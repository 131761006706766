import Container from "../../../components/Container";
import { StyledSection } from "../../../components";
import * as React from "react";
import styled from "styled-components";
import { device } from "../../../utilities/responsive";
import { dict, localize } from "../../../i18n";
import testimonial1 from "../../../pics/testimonial-1.png";
import testimonial2 from "../../../pics/testimonial-2.png";
import testimonial3 from "../../../pics/testimonial-3.png";

const Card = styled.div`
  height: 400px;
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;

  img {
    margin-bottom: 20px;
  }

  span {
    display: flex;
    justify-content: flex-end;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative !important;
  max-width: 760px;
  width: 100%;
  row-gap: 75px;
  flex-direction: column;
  padding: 30px;

  @media ${device.lg} {
    flex-direction: row;
    bottom: -200px;
    column-gap: 30px;
    row-gap: 0;
    position: absolute !important;
    padding: 0;
  }
`;

const StyledSectionSection = styled(StyledSection.Section)`
  background-color: #ffebe6;
  padding-top: 75px;
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${device.lg} {
    padding-bottom: 200px;
    flex-direction: row;
  }
`;

export const GuaranteedSection = () => (
  <StyledSectionSection
    style={{
      backgroundColor: "#FFEBE6",
      paddingTop: 75,
      paddingBottom: 200,
      position: "relative",
    }}
  >
    <Container style={{ flexDirection: "column", color: "#454545" }}>
      <h2
        style={{
          fontFamily: "Cantata One, serif",
          fontSize: 30,
          textAlign: "center",
          fontWeight: "normal",
        }}
      >
        {localize(dict.guaranteed.satisfactionGuaranteed)}
      </h2>
      <p style={{ fontSize: 18, textAlign: "center" }}>
        {localize(dict.guaranteed.satisfactionGuaranteedSub)}
      </p>
    </Container>
    <StyledDiv>
      <Card>
        <img
          src={testimonial1}
          alt=""
          style={{
            borderRadius: "100%",
            marginTop: -10,
            marginLeft: -10,
            maxWidth: 100,
          }}
        />
        <p>{localize(dict.guaranteed.testimonialOne)}</p>
        <span>- L.P. 9/7/2021</span>
      </Card>
      <Card>
        <img
          src={testimonial2}
          alt=""
          style={{
            borderRadius: "100%",
            marginTop: -10,
            marginLeft: -10,
            maxWidth: 100,
          }}
        />
        <p>{localize(dict.guaranteed.testimonialTwo)}</p>
        <span>- M.L 8/24/21</span>
      </Card>
      <Card>
        <img
          src={testimonial3}
          alt=""
          style={{
            borderRadius: "100%",
            marginTop: -10,
            marginLeft: -10,
            maxWidth: 100,
          }}
        />
        <p>{localize(dict.guaranteed.testimonialThree)}</p>
        <span>- A.C 10/2/21</span>
      </Card>
    </StyledDiv>
  </StyledSectionSection>
);
