import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartClosed } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOpen } from "@fortawesome/free-regular-svg-icons";
import styled from "styled-components";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { toast } from "react-hot-toast";
import fetchFavorites from "../utilities/fetchFavorites";
import { localize, dict } from "../i18n";

const IconLink = styled.div`
  cursor: pointer;
`;

const FavoriteIcon = ({ therapist, style, favorited, setFavorites }) => {
  const token = localStorage.getItem("token");
  const user = jwt_decode(token);
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  const handleOnClick = async () => {
    if (favorited) {
      try {
        const response = await axios({
          method: "delete",
          url: `${process.env.REACT_APP_API_URL}/api/favorites/user/${user.id}/therapist/${therapist._id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        await fetchFavorites(setFavorites);
      } catch (err) {
        if (err.response) {
          console.error("error", err.response.data.message);
          return toast.error(`Error: ${err.response.data.message}`);
        }
      }
    } else {
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/favorites/user/${user.id}`,
          data: {
            therapistUser: therapist._id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        await fetchFavorites(setFavorites);
      } catch (err) {
        if (err.response) {
          console.error("error", err.response.data.message);
          return toast.error(`Error: ${err.response.data.message}`);
        }
      }
    }
  };

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);

      return () => {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, [ref.current]);

  return (
    <IconLink ref={ref} style={style} onClick={handleOnClick}>
      {favorited || value ? (
        <FontAwesomeIcon icon={faHeartClosed} color={"#fe6551"} size={"3x"} />
      ) : (
        <FontAwesomeIcon icon={faHeartOpen} color={"#fe6551"} size={"3x"} />
      )}
    </IconLink>
  );
};

export default FavoriteIcon;
