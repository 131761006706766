import styled from "styled-components";
import { device } from "../../utilities/responsive";
import PagesComponent from "./PagesComponent";

const MobilePagesComponent = styled(PagesComponent)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  a {
    display: flex;
    width: 100%;
    padding: 15px 20px;
    justify-content: center;
    color: #fe6551;
    font-weight: normal;

    &:hover {
      cursor: pointer;
      background-color: #ececec;
    }

    > span {
      margin-left: 0;
    }
  }

  @media ${device.lg} {
    display: none;
  }
`;

export default MobilePagesComponent;
