import { specialties } from "../../pages/Dashboard/Sidebar";

const createSpecialtiesList = (receivedSpecialties) => {
  const converted = receivedSpecialties?.map((specialty) => {
    return specialties[specialty]?.value;
  });

  if (converted?.length) {
    return <p>{converted.join(", ")}</p>;
  } else {
    return <p>No specialties selected.</p>;
  }
};

const createCertificationsList = (fetchedTherapist) => {
  if (fetchedTherapist?.certifications) {
    return <p>{fetchedTherapist.certifications}</p>;
  } else {
    return <p>No certifications listed.</p>;
  }
};

export { createSpecialtiesList, createCertificationsList };
