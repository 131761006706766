const dashboardScope = "dashboard";

export default {
  myTherapists: `${dashboardScope}.myTherapists`,
  allTherapists: `${dashboardScope}.allTherapists`,
  allSpecialties: `${dashboardScope}.allSpecialties`,
  search: `${dashboardScope}.search`,
  filters: `${dashboardScope}.filters`,
  seeMore: `${dashboardScope}.seeMore`,
  credentials: `${dashboardScope}.credentials`,
  therapistProfile: `${dashboardScope}.therapistProfile`,
  profileImage: `${dashboardScope}.profileImage`,
  uploadImage: `${dashboardScope}.uploadImage`,
  calendlyUsername: `${dashboardScope}.calendlyUsername`,
  facebookUrl: `${dashboardScope}.facebookUrl`,
  linkedInUrl: `${dashboardScope}.linkedInUrl`,
  instagramUrl: `${dashboardScope}.instagramUrl`,
  twitterUrl: `${dashboardScope}.twitterUrl`,
  media: `${dashboardScope}.media`,
  clientProfile: `${dashboardScope}.clientProfile`,
  listYourCertifications: `${dashboardScope}.listYourCertifications`,
};
