export default {
  required: "Required",
  invalidEmailAddress: "Invalid email address",
  emailAddressNoMatch: "Email address does not match",
  pleaseEnterInformationRegardingInquiry:
    "Please enter information regarding your inquiry",
  mustMatchInitialPassword: "Must match initial password",
  mustProvideYearsOfExperience: "Must provide years of experience",
  mustHaveAtLeastThreeYearsOfExperience:
    "Must have at least 3 years of experience",
  pleaseEnterInformationRegardingAreaOfSpecialty:
    "Please enter information regarding your area of specialty",
  pleaseEnterYourCertifications:
    'Please enter your certifications or write "none"',
  mustAcceptTermsOfUseAndPrivacyPolicy:
    "Must accept terms of use and privacy policy",
  removedFavorite: "Removed favorite.",
  paymentSuccessful: "Payment Successful.",
  failedToSubmitPayment: "Failed to Submit Payment.",
  successfullyUpdated: "Successfully Updated.",
  errorWhileUpdatingUser: "Error while updating user.",
  successfullyUploadedImage: "Successfully uploaded image.",
  failedToUploadImage: "Failed to upload image.",
  errorWhileLoggingIn: "Error while logging in.",
  accountIsCurrentlyUnderReview: "Account is currently under review.",
  successfullyScheduledAppointment: "Successfully scheduled appointment.",
  failedToScheduleAppointment: "Failed to schedule appointment.",
  youDoNotHaveAnyCreditsAvailable: "You do not have any credits available.",
  successfullyCreatedAccount: "Successfully created account.",
  errorWhileCreatingUserAccount: "Error while creating user account.",
  passwordReset: "Password Reset",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  pleaseFollowTheLinkToResetYourPassword:
    "Please follow the link below to reset your password",
  aPasswordResetLinkHasBeenSent:
    "A password reset link has been sent to the requested email address.",
  successfullyResetPassword: "Successfully reset password. Please log in.",
  failedToResetPassword: "Failed to reset password.",
  resetPassword: "Reset Password",
};
