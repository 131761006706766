import DesktopPagesComponent from "./DesktopPagesComponent";
import PagesLinks from "./PagesLinks";
import MobilePagesComponent from "./MobilePagesComponent";
import DesktopActionsComponent from "./DesktopActionsComponent";
import DesktopActions from "./DesktopActions";
import MobileActionsComponent from "./MobileActionsComponent";
import MobileActions from "./MobileActions";

const AuthenticatedNavComponents = () => {
  return [
    <DesktopPagesComponent>
      <PagesLinks />
    </DesktopPagesComponent>,
    <MobilePagesComponent>
      <PagesLinks />
    </MobilePagesComponent>,
    <DesktopActionsComponent>
      <DesktopActions />
    </DesktopActionsComponent>,
    <MobileActionsComponent>
      <MobileActions />
    </MobileActionsComponent>,
  ];
};

export default AuthenticatedNavComponents;
