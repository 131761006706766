import React, { useState } from "react";
import { Navbar } from "./Navbar";
import { StyledSection } from "../../components";
import Container from "../../components/Container";
import styled from "styled-components";
import Ul from "./Ul";
import {
  faCalendarCheck,
  faGraduationCap,
  faHeart,
  faMoneyBillWave,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dict, localize } from "../../i18n";
import ProvidersForm from "./Forms/ProvidersForm";
import { device } from "../../utilities/responsive";
import PrivacyPolicyModal from "../../components/PrivacyPolicyModal";
import CareSeekerImg from "../../pics/care-seeker-img.png";

const StyledContainer = styled(Container)`
  padding: 50px;
  column-gap: 75px;

  h1 {
    font-family: "Martel Sans", sans-serif;
    margin-top: 0;
  }

  div#care-seekers-right {
    img {
      display: none;
    }
  }

  @media ${device.lg} {
    padding: 0;

    div#care-seekers-right {
      img {
        display: flex;
        max-height: 1100px;
        opacity: 0.8;
      }
    }
  }
`;

const Providers = () => {
  const [modal, setModal] = useState(false);

  return (
    <React.Fragment>
      <Navbar />
      <StyledSection.Section style={{ paddingTop: 100, paddingBottom: 100 }}>
        <StyledContainer style={{ rowGap: 75 }}>
          <div style={{ flex: 2 }}>
            <h1>{localize(dict.providers.providersHeadline)}</h1>
            <p style={{ marginBottom: 50 }}>
              {localize(dict.providers.providersSubCopy)}
            </p>
            <p>
              <h4 style={{ marginBottom: 30 }}>
                {localize(dict.providers.whyJoinSevelyn)}
              </h4>
              <Ul>
                <li>
                  <span>
                    <FontAwesomeIcon
                      icon={faTrophy}
                      color={"#fe6551"}
                      size={"lg"}
                      style={{ marginRight: 10 }}
                    />
                    {localize(dict.providers.freeToJoin)}
                  </span>
                  <p>{localize(dict.providers.freeToJoinSubCopy)}</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon
                      icon={faHeart}
                      color={"#fe6551"}
                      size={"lg"}
                      style={{ marginRight: 10 }}
                    />
                    {localize(dict.providers.focusOnWhatYouLove)}
                  </span>
                  <p>{localize(dict.providers.focusOnWhatYouLoveSubCopy)}</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon
                      icon={faCalendarCheck}
                      color={"#fe6551"}
                      size={"lg"}
                      style={{ marginRight: 10 }}
                    />
                    {localize(dict.providers.workFlexibility)}
                  </span>
                  <p>{localize(dict.providers.workFlexibilitySubCopy)}</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon
                      icon={faMoneyBillWave}
                      color={"#fe6551"}
                      size={"lg"}
                      style={{ marginRight: 10 }}
                    />
                    {localize(dict.providers.supplementalIncome)}
                  </span>
                  <p>{localize(dict.providers.supplementalIncomeSubCopy)}</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon
                      icon={faGraduationCap}
                      color={"#fe6551"}
                      size={"lg"}
                      style={{ marginRight: 10 }}
                    />
                    {localize(dict.providers.increaseYourKnowledge)}
                  </span>
                  <p>{localize(dict.providers.increaseYourKnowledgeSubCopy)}</p>
                </li>
              </Ul>
            </p>
          </div>
          <div id={"care-seekers-right"} style={{ flex: 1 }}>
            <img src={CareSeekerImg} alt="" />
          </div>
        </StyledContainer>
      </StyledSection.Section>
      <ProvidersForm setModal={setModal} />
      <PrivacyPolicyModal modal={modal} setModal={setModal} />
    </React.Fragment>
  );
};

export default Providers;
