import "./Card.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import { createCertificationsList, createSpecialtiesList } from "./utils";
import { StyledButton } from "../index";
import { HashLink } from "react-router-hash-link";
import FavoriteIcon from "../FavoriteIcon";
import { device } from "../../utilities/responsive";
import { dict, localize } from "../../i18n";
import { toast } from "react-hot-toast";
import MalePlaceholder from "../../pics/male-placeholder.jpeg";
import FemalePlaceholder from "../../pics/female-placeholder.jpeg";

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  padding: 30px 40px;
  column-gap: 40px;

  @media ${device.lg} {
    flex-direction: row;
    row-gap: 0;
  }
`;

const CardBio = ({ therapist, cells, favorited, setFavorites }) => {
  const [fetchedTherapist, setFetchedTherapist] = useState(null);

  useEffect(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/therapists/${therapist._id}`,
        {
          headers: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            "cache-control": "no-cache",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setFetchedTherapist(res.data);
    } catch (err) {
      if (err.response) {
        console.error("error", err.response.data.message);
        return toast.error(`Error: ${err.response.data.message}`);
      }
    }
  }, []);

  return fetchedTherapist
    ? [
        <InnerContainer>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              rowGap: 10,
            }}
          >
            <div className={"circle"}>
              <FavoriteIcon
                therapist={therapist}
                favorited={favorited}
                setFavorites={setFavorites}
              />
            </div>
            <img
              src={
                therapist.therapist.profileImage
                  ? `${process.env.REACT_APP_API_URL}/upload/images/${therapist.therapist.profileImage}`
                  : therapist.therapist.therapistGender === "GENDER_MALE"
                  ? MalePlaceholder
                  : FemalePlaceholder
              }
              className={"cardbio-image"}
              style={{
                maxHeight: 250,
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
            <HashLink to={`/dashboard/therapists/${therapist._id}#calendly`}>
              <StyledButton.Button>
                <StyledButton.ButtonText>
                  {localize(dict.app.bookNow)}
                </StyledButton.ButtonText>
              </StyledButton.Button>
            </HashLink>
          </div>
          <div style={{ flex: 1 }}>
            <div className={"cardbio-content"}>
              <h2
                className={"name"}
              >{`${cells[0].value} ${cells[1].value}`}</h2>
              <div>
                <h4>{localize(dict.app.specialties)}</h4>
                {createSpecialtiesList(cells[3].value)}
              </div>
              <div>
                <h4>{localize(dict.app.certifications)}</h4>
                {createCertificationsList(fetchedTherapist)}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: 1,
                backgroundColor: "#000",
                opacity: 0.1,
              }}
            />
          </div>
        </InnerContainer>,
        <CardFooter therapist={therapist} />,
      ]
    : null;
};
const CardFooter = ({ therapist }) => {
  return (
    <div
      style={{
        display: "flex",
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 10,
        paddingTop: 10,
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #e3e3e3",
      }}
    >
      <Link
        to={{
          pathname: `/dashboard/therapists/${therapist._id}`,
          state: { therapist },
        }}
      >
        <span className={"more"} style={{ alignSelf: "flex-end" }}>
          {localize(dict.dashboard.seeMore)} >
        </span>
      </Link>
    </div>
  );
};

const StyledCard = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 rgba(173, 173, 173, 0.5);
  background-color: #fff;
  max-width: 980px;
  width: 100%;
`;

export const Card = ({ user, row, favorited, setFavorites }) => (
  <StyledCard {...row.getRowProps()}>
    <CardBio
      therapist={user}
      cells={row.cells}
      favorited={favorited}
      setFavorites={setFavorites}
    />
  </StyledCard>
);
