import styled from "styled-components";

const StyledSection = styled.section`
  display: flex;
  justify-content: center;
  min-height: 450px;

  ${({ style }) => ({ ...style })};
`;

const Section = (props) => {
  return <StyledSection {...props}>{props.children}</StyledSection>;
};

export default { Section };
