import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { StyledButton, StyledInput, StyledLabel } from "../../../components";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-hot-toast";
import ReactCrop from "react-image-crop";
import { localize, dict } from "../../../i18n";

const Label = styled(StyledLabel.Label)`
  h4 {
    margin-top: 0;
  }

  textarea {
    max-width: 490px;
    width: 100%;
    min-height: 150px;
  }
`;

const Group = styled(StyledLabel.Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;

  span {
    margin-bottom: 5px;
    font-size: 12px;
    color: #2b2b2b;
    letter-spacing: 0.7px;
    font-family: "Martel Sans", sans-serif;
  }

  ${({ style }) => ({ ...style })};
`;

const UploadMedia = () => {
  const token = localStorage.getItem("token");
  const userId = jwt_decode(token).id;
  const hiddenFileInput = React.useRef(null);
  const [profileImage, setProfileImage] = useState(null);
  const [img, setImg] = useState(null);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [result, setResult] = useState(null);

  const fetchProfileImage = async () => {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/api/profile-image/${userId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response) {
      setProfileImage(
        `${process.env.REACT_APP_API_URL}/upload/images/${response.data}`
      );
    } else {
      setProfileImage(null);
    }
  };

  useEffect(async () => {
    await fetchProfileImage();
    // TODO: Fetch video from specific endpoint
    // TODO: Set state of both
  }, []);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImage = (e) => {
    const file = e.currentTarget.files[0];

    if (file) {
      return setProfileImage(file);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", profileImage);

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/profile-image`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response) {
        throw new Error("Failed to fetch profile image.");
      }

      setProfileImage(null);
      await fetchProfileImage();
      return toast.success(localize(dict.errors.successfullyUploadedImage));
    } catch (err) {
      console.error("error", err);
      return toast.error(localize(dict.errors.failedToUploadImage));
    }
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 40,
        marginTop: 75,
        marginBottom: 75,
      }}
      encType="multipart/form-data"
      onSubmit={handleFormSubmit}
    >
      {profileImage && (
        <ReactCrop
          crop={crop}
          onChange={setCrop}
          src={
            !(profileImage instanceof File)
              ? profileImage
              : URL.createObjectURL(profileImage)
          }
        />
      )}
      <Label>
        <h4>{localize(dict.dashboard.profileImage)}</h4>
        {profileImage && (
          <img
            src={
              !(profileImage instanceof File)
                ? profileImage
                : URL.createObjectURL(profileImage)
            }
            style={{
              maxWidth: 346,
              maxHeight: 240,
              width: "100%",
              height: "100%",
            }}
          />
        )}

        <StyledInput.Input
          ref={hiddenFileInput}
          name="image"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleImage}
          style={{ display: "none" }}
        />
      </Label>
      {profileImage ? (
        <StyledButton.Button
          style={{ maxWidth: 150, marginTop: -30, padding: "5px 13px" }}
          onClick={handleClick}
        >
          <StyledButton.ButtonText>
            {localize(dict.dashboard.uploadImage)}
          </StyledButton.ButtonText>
        </StyledButton.Button>
      ) : null}
      {!profileImage || profileImage instanceof File ? (
        <StyledButton.Button
          style={{ maxWidth: 150, marginTop: 50 }}
          onClick={handleFormSubmit}
          disabled={!profileImage || !(profileImage instanceof File)}
        >
          <StyledButton.ButtonText>
            {localize(dict.app.save)}
          </StyledButton.ButtonText>
        </StyledButton.Button>
      ) : null}
    </form>
  );
};

export default UploadMedia;
