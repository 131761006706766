export default {
  aboutUsHeadline: "About Us",
  aboutUsSubCopy:
    "Welcome to Sevelyn, the digital emotional wellness platform designed to service to the Latino community by connecting them to professionals whose specialties are tailored to fit their needs.",
  ourMissionHeadline: "Our Mission",
  ourMissionSubCopy:
    "We built Sevelyn to bridge the gap between Latinos and emotional wellness services. Due to language barriers, cultural differences, stigmas and affordability, Latinos are less likely to seek help from emotional wellness providers. At Sevelyn, we understand that finding the right provider is hard, and we want to change this by connecting care-seekers to emotional wellness professionals who can knowledgeably navigate the Latino culture, values, and needs.",
  ourPromiseHeadline: "Our Promise",
  affordability: "Affordability",
  safetyAndConfidentiality: "Safety and Confidentiality",
  convenience: "Convenience",
  qualityFirst: "Quality First",
  cultureFirst: "Culture First",
};
