export default {
  wellnessYouDeserve: "El Bienestar Que Te Mereces",
  wellnessYouDeserveBody:
    "Sevelyn es una plataforma digital que brinda servicios de bienestar emocional. Nuestro objetivo es brindarte una experiencia de bienestar mental que se adapte a ti - ya sea que hables con uno de nuestros especialistas, desarrolles nuevas habilidades a través de servicios de auto-ayuda, o aprendas e interactúes con nuestra comunidad y servicios de peer to peer que atiende tus necesidades únicas. Atender a tu bienestar mental y tus preferencias es nuestra única prioridad.",
  affordableBody:
    "Precios asequibles con descuentos por la compra de paquetes de 4 y 10 sesiones",
  qualityCareBody:
    "Nuestros especialistas de bienestar mental cuentan con certificaciones en Estados Unidos y Latino América y cuentan con un mínimo de tres años de experiencias profesional en el área clínica.",
  cultureFirstBody:
    "Nuestros proveedores entienden completamente tu idioma y cultura, ¡Son latinos como tú!",
  convenienceBody:
    "¡Ahórrate tiempo y maximiza tu comodidad! Conéctate con nosotros de cualquier lugar con conexión a internet.",
  safetyAndConfidentialityBody:
    "Nuestra plataforma y herramientas están completamente encriptadas y administradas teniendo en cuenta la seguridad y el anonimato.",
  podcast: "Podcast",
  ourPodcastDiscusses:
    "Nuestro podcast trata sobre temas relacionados con el bienestar emocional de importancia para la comunidad latina.",
  sevelynMobileApp: "Aplicación móvil Sevelyn",
  whatMakesUsUnique: "Lo Que Nos Diferencia",
};
