const careSeekers = "careSeekers";

export default {
  wellnessYouDeserve: `${careSeekers}.wellnessYouDeserve`,
  wellnessYouDeserveBody: `${careSeekers}.wellnessYouDeserveBody`,
  safetyAndConfidentialityBody: `${careSeekers}.safetyAndConfidentialityBody`,
  affordableBody: `${careSeekers}.affordableBody`,
  qualityCareBody: `${careSeekers}.qualityCareBody`,
  cultureFirstBody: `${careSeekers}.cultureFirstBody`,
  convenienceBody: `${careSeekers}.convenienceBody`,
  podcast: `${careSeekers}.podcast`,
  ourPodcastDiscusses: `${careSeekers}.ourPodcastDiscusses`,
  sevelynMobileApp: `${careSeekers}.sevelynMobileApp`,
  whatMakesUsUnique: `${careSeekers}.whatMakesUsUnique`,
};
