export default {
  providersHeadline: "Providers",
  providersSubCopy:
    "We’re on the hunt for emotional wellness providers from various fields - psychologists, therapists, counselors, coaches, social workers and many others. If you are versed in the Latino culture and speak fluent Spanish with a minimum of 3 years of professional experience we would love to talk to you. Please fill out the form below to apply and a member of our clinical team will be in touch within 24 hours.",
  whyJoinSevelyn: "Why Join Sevelyn",
  freeToJoin: "FREE to Join",
  freeToJoinSubCopy: "Joining our platform is completely free of charge!",
  focusOnWhatYouLove: "Focus on what you love",
  focusOnWhatYouLoveSubCopy:
    "You take of our clients, we take care of you and your operations!",
  workFlexibility: "Work Flexibility",
  workFlexibilitySubCopy:
    "Work from anywhere with stable internet connection, on your own schedule.",
  supplementalIncome: "Supplemental Income",
  supplementalIncomeSubCopy:
    "Sevelyn can be a great source to supplement your work. Work as little or as much as you want, the choice is yours.",
  increaseYourKnowledge: "Increase Your Knowledge",
  increaseYourKnowledgeSubCopy:
    "Sevelyn makes sure you stay aware of latest professional trainings and methodologies by providing trainings, webinars and industry information.",
  applicationForm: "Application Form",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  confirmEmail: "Confirm Email",
  phoneNumber: "Phone Number",
  whatsAppNumber: "WhatsApp Number",
  nationality: "Nationality",
  gender: "Gender",
  howAreYouWillingToSeePatients: "How Are You Willing To See Patients?",
  individuals: "Individuals",
  couples: "Couples",
  groups: "Groups",
  areaOfSpecialty: "Area of Specialty",
  yearsOfExperience: "Years of Experience",
  certifications: "Certifications",
  consentDialog:
    "I am over the age of 18 and agree to the terms of use and privacy policy.",
  successfullySentProviderIntakeInformation:
    "Successfully sent provider intake information.",
};
