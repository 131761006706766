import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Redirect } from "react-router-dom";
import { Routes } from "../../Routes";
import { StyledContainer } from "../../components";
import styled from "styled-components";
import { device } from "../../utilities/responsive";
import UserManagementList from "./UserManagementList";
import UserCard from "./UserCard";
import { Card } from "./Card";
import CancellationRequestsList from "./CancellationRequestsList";
import CancellationRequestCard from "./CancellationRequestCard";

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  column-gap: 20px;
  flex-wrap: wrap;
  padding: 30px 10px 10px;
  width: 100%;
  min-height: 100vh;

  @media ${device.lg} {
    flex-direction: row;
  }
`;

export const StyledNavLink = styled.span`
  color: #2b2b2b;

  &:hover {
    cursor: pointer;
    color: rgb(254, 101, 81);
  }

  ${({ match }) => match && { color: "rgb(254, 101, 81)" }})
  ${({ style }) => style && { ...style }})
`;

const Admin = () => {
  const { roles } = jwt_decode(localStorage.getItem("token"));
  const [match, setMatch] = useState("USER_MANAGEMENT");
  const [showUser, setShowUser] = useState(null);
  const [showCancellation, setShowCancellation] = useState(null);

  return roles.includes("ROLE_ADMIN") ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#f3f3f3",
      }}
    >
      <StyledContainer.Container>
        <Grid>
          <Card>
            <div style={{ display: "flex", columnGap: 20, marginBottom: 10 }}>
              <StyledNavLink
                match={match === "USER_MANAGEMENT"}
                onClick={() => setMatch("USER_MANAGEMENT")}
              >
                User Management
              </StyledNavLink>
              <StyledNavLink
                match={match === "CANCELLATION_REQUESTS"}
                onClick={() => setMatch("CANCELLATION_REQUESTS")}
              >
                Cancellation Requests
              </StyledNavLink>
            </div>
            <div className={"list"}>
              {match === "USER_MANAGEMENT" && (
                <UserManagementList setShowUser={setShowUser} />
              )}
              {match === "CANCELLATION_REQUESTS" && (
                <CancellationRequestsList
                  setShowCancellation={setShowCancellation}
                />
              )}
            </div>
          </Card>
          {match === "USER_MANAGEMENT" && <UserCard showUser={showUser} />}
          {match === "CANCELLATION_REQUESTS" && (
            <CancellationRequestCard showCancellation={showCancellation} />
          )}
        </Grid>
      </StyledContainer.Container>
    </div>
  ) : (
    <Redirect to={Routes.DASHBOARD} />
  );
};

export default Admin;
