const registrationScope = "registration";

export default {
  whatRoleAreYouSeeking: `${registrationScope}.whatRoleAreYouSeeking`,
  client: `${registrationScope}.client`,
  registerNow: `${registrationScope}.registerNow`,
  registerForTeletherapy: `${registrationScope}.registerForTeletherapy`,
  thankYou: `${registrationScope}.thankYou`,
  whatTypeOfTherapy: `${registrationScope}.whatTypeOfTherapy`,
  therapyForMyself: `${registrationScope}.therapyForMyself`,
  couplesTherapy: `${registrationScope}.couplesTherapy`,
  howOldAreYou: `${registrationScope}.howOldAreYou`,
  twelveAndUnder: `${registrationScope}.twelveAndUnder`,
  howDidYouHearAboutSevelyn: `${registrationScope}.howDidYouHearAboutSevelyn`,
  googleSearch: `${registrationScope}.googleSearch`,
  wordOfMouth: `${registrationScope}.wordOfMouth`,
  other: `${registrationScope}.other`,
  next: `${registrationScope}.next`,
  confirmPassword: `${registrationScope}.confirmPassword`,
  password: `${registrationScope}.password`,
  firstName: `${registrationScope}.firstName`,
  lastName: `${registrationScope}.lastName`,
  email: `${registrationScope}.email`,
  cellPhoneNumber: `${registrationScope}.cellPhoneNumber`,
  back: `${registrationScope}.back`,
  tellUsTheType: `${registrationScope}.tellUsTheType`,
  gender: `${registrationScope}.gender`,
  male: `${registrationScope}.male`,
  female: `${registrationScope}.female`,
  noPreference: `${registrationScope}.noPreference`,
  therapistWhoSpecializesInLGBTQ: `${registrationScope}.therapistWhoSpecializesInLGBTQ`,
  specializesInLGBTQ: `${registrationScope}.specializesInLGBTQ`,
  spiritualOrientation: `${registrationScope}.spiritualOrientation`,
  believer: `${registrationScope}.believer`,
  nonBeliever: `${registrationScope}.nonBeliever`,
  countryOfOrigin: `${registrationScope}.countryOfOrigin`,
  done: `${registrationScope}.done`,
  dontWorry: `${registrationScope}.dontWorry`,
  provider: `${registrationScope}.provider`,
  tellUsAboutYourself: `${registrationScope}.tellUsAboutYourself`,
  connectWith: `${registrationScope}.connectWith`,
  willBeAbleToFind: `${registrationScope}.willBeAbleToFind`,
  whatCertificationsDoYouHave: `${registrationScope}.whatCertificationsDoYouHave`,
};
