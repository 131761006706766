import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { StyledButton } from "./index";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "./PrivacyPolicyModal.css";

const PrivacyPolicyModal = ({ modal, setModal }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleClose = () => {
    setPageNumber(1);
    setModal(false);
  };

  return (
    <Modal show={modal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Privacy Policy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Document
          file={"./privacy-policy.pdf"}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </Modal.Body>
      <Modal.Footer>
        {pageNumber != 1 && (
          <StyledButton.Button onClick={() => setPageNumber(pageNumber - 1)}>
            <StyledButton.ButtonText>Previous</StyledButton.ButtonText>
          </StyledButton.Button>
        )}
        {pageNumber != numPages && (
          <StyledButton.Button onClick={() => setPageNumber(pageNumber + 1)}>
            <StyledButton.ButtonText>Next</StyledButton.ButtonText>
          </StyledButton.Button>
        )}
        {pageNumber == numPages && (
          <StyledButton.Button onClick={handleClose}>
            <StyledButton.ButtonText>Done</StyledButton.ButtonText>
          </StyledButton.Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PrivacyPolicyModal;
