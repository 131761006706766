import styled from "styled-components";
import { device } from "../utilities/responsive";

const InputGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 60px;

  @media ${device.lg} {
    flex-direction: row;
  }
`;

export default InputGroupContainer;
