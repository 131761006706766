import Modal from "react-bootstrap/Modal";
import Button from "../../../components/Button";
import React, { useState } from "react";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-hot-toast";
import { dict, localize } from "../../../i18n";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const plan = {
  ["PLAN_ONE"]: { name: localize(dict.pricing.individualSession), amount: 50 },
  ["PLAN_COUPLES"]: { name: localize(dict.pricing.couplesSession), amount: 60 },
  ["PLAN_FOUR"]: {
    name: localize(dict.pricing.packetOfFourSessions),
    amount: 180,
  },
  ["PLAN_TEN"]: {
    name: localize(dict.pricing.packetOfTenSessions),
    amount: 400,
  },
};

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
`;

const SubscriptionModalCheckoutForm = ({
  setSubmitButtonReady,
  submitButtonReady,
  clearData,
  callback,
  paymentIntent,
  setPhase,
  phase,
  bearerToken,
  setPurchased,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error) {
        throw new Error(error);
      }

      const cardPayment = await stripe.confirmCardPayment(
        paymentIntent.clientSecret,
        { payment_method: paymentMethod.id }
      );

      if (!cardPayment) {
        throw new Error("Failed to confirm payment");
      }

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/stripe/confirm-payment-intent`,
        data: { paymentIntentId: paymentIntent.id },
        headers: { Authorization: bearerToken },
      });

      if (!response || response.status !== 201) {
        throw new Error("Failed to created subscription.");
      }

      await setPurchased(true);
      await callback();
      await clearData();
      toast.success(localize(dict.errors.paymentSuccessful));
    } catch (err) {
      toast.error(localize(dict.errors.failedToSubmitPayment), err);
      await clearData();
    }

    setSubmitting(false);
  };

  return (
    <form>
      <CardElement onReady={() => setSubmitButtonReady(true)} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          columnGap: 30,
          marginTop: 30,
        }}
      >
        <Button
          onClick={() => {
            setSubmitButtonReady(false);
            setPhase(phase - 1);
          }}
        >
          {localize(dict.app.back)}
        </Button>
        {submitButtonReady && (
          <Button
            onClick={handleSubmit}
            style={submitting ? { pointerEvents: "none" } : {}}
          >
            {localize(dict.app.submit)}
          </Button>
        )}
      </div>
    </form>
  );
};

const SubscriptionModal = ({ modal, setModal, callback, setPurchased }) => {
  const [phase, setPhase] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [submitButtonReady, setSubmitButtonReady] = useState(false);
  const bearerToken = `Bearer ${localStorage.getItem("token")}`;

  const handleSelectedPlan = async (plan) => {
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/stripe/create-payment-intent`,
        data: { plan: plan },
        headers: { Authorization: bearerToken },
      });
      setSelectedPlan(plan);
      setPaymentIntent(response?.data);
      setPhase(2);
    } catch (err) {
      if (err.response) {
        console.error("error", err.response.data.message);
        return toast.error(`Error: ${err.response.data.message}`);
      }
    }
  };

  const clearData = async () => {
    setModal(false);
    setPhase(1);
    setSelectedPlan(null);
    setPaymentIntent(null);
    setSubmitButtonReady(false);
  };

  return (
    <Modal show={modal} onHide={() => setModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title style={{ display: "flex", flexDirection: "column" }}>
          <span>{localize(dict.appointments.upgradePlan)}</span>
          {selectedPlan && phase !== 1 ? (
            <React.Fragment>
              <span style={{ fontSize: 14 }}>{plan[selectedPlan].name}</span>
              <span style={{ fontSize: 14 }}>
                ${plan[selectedPlan].amount.toFixed(2)}
              </span>
            </React.Fragment>
          ) : null}
        </Modal.Title>
      </Modal.Header>
      {phase === 1 && (
        <Modal.Body
          style={{ display: "flex", flexDirection: "column", rowGap: 30 }}
        >
          <ButtonGroup>
            {localize(dict.pricing.individualSession)}
            <Button
              style={{ maxWidth: 150 }}
              onClick={() => handleSelectedPlan("PLAN_ONE")}
            >
              {localize(dict.app.select)}
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            {localize(dict.pricing.couplesSession)}
            <Button
              style={{ maxWidth: 150 }}
              onClick={() => handleSelectedPlan("PLAN_COUPLES")}
            >
              {localize(dict.app.select)}
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            {localize(dict.pricing.packetOfFourSessions)}
            <Button
              style={{ maxWidth: 150 }}
              onClick={() => handleSelectedPlan("PLAN_FOUR")}
            >
              {localize(dict.app.select)}
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            {localize(dict.pricing.packetOfTenSessions)}
            <Button
              style={{ maxWidth: 150 }}
              onClick={() => handleSelectedPlan("PLAN_TEN")}
            >
              {localize(dict.app.select)}
            </Button>
          </ButtonGroup>
        </Modal.Body>
      )}
      {phase === 2 && (
        <Modal.Body>
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: paymentIntent.clientSecret }}
          >
            <SubscriptionModalCheckoutForm
              phase={phase}
              setPhase={setPhase}
              paymentIntent={paymentIntent}
              submitButtonReady={submitButtonReady}
              setSubmitButtonReady={setSubmitButtonReady}
              callback={callback}
              clearData={clearData}
              bearerToken={bearerToken}
              setPurchased={setPurchased}
            />
          </Elements>
        </Modal.Body>
      )}
    </Modal>
  );
};
export default SubscriptionModal;
