export default {
  pricing: "Precios",
  freeFirstSession: "Consulta Inicial Gratis",
  freeFirstSessionPrice: "$0",
  fourtyFiveMinutes: "45 Minutos",
  fourtyFiveMinutesEach: "45 Minutos",
  individualSession: "Cita Individual",
  fifty: "$50",
  valueOfFifty: "(Valor de $50)",
  packetOfFourSessions: "Paquete de 4 sesiones",
  oneHundredEighty: "$180",
  savingsOfTwenty: "(Ahorro de $20)",
  packetOfTenSessions: "Paquete de 10 sesiones",
  fourHundred: "$400",
  savingsOfOneHundred: "(Ahorro de $100)",
  sixty: "$60",
  couplesSession: "Cita de Pareja",
};
