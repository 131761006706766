import React from "react";
import { Navbar } from "./Navbar";
import { StyledButton, StyledSection } from "../../components";
import Container from "../../components/Container";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Routes } from "../../Routes";
import Ul from "./Ul";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCouch,
  faHeart,
  faLock,
  faUsers,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { device } from "../../utilities/responsive";
import { dict, localize } from "../../i18n";
import ProvidersImg from "../../pics/providers.jpeg";

const StyledContainer = styled(Container)`
  padding: 50px;

  h1 {
    font-family: "Martel Sans", sans-serif;
    margin-top: 0;
  }

  div#care-seekers-right {
    img {
      display: none;
    }
  }

  @media ${device.lg} {
    padding: 0;

    div#care-seekers-right {
      img {
        display: flex;
        max-height: 1100px;
        opacity: 0.8;
      }
    }
  }
`;

const CareSeekers = () => (
  <React.Fragment>
    <Navbar />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 100,
        paddingBottom: 100,
      }}
    >
      <StyledSection.Section
        style={{
          paddingTop: 100,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <StyledContainer
          style={{ columnGap: 75, rowGap: 75, overflow: "hidden" }}
        >
          <div style={{ flex: 2 }}>
            <h1>{localize(dict.careSeekers.wellnessYouDeserve)}</h1>
            <p style={{ marginBottom: 50 }}>
              {localize(dict.careSeekers.wellnessYouDeserveBody)}
            </p>
            <h4 style={{ marginBottom: 30 }}>
              {localize(dict.careSeekers.whatMakesUsUnique)}
            </h4>
            <Ul liMarginBottom={"0px"}>
              <li>
                <span>
                  <FontAwesomeIcon
                    icon={faWallet}
                    color={"#fe6551"}
                    size={"lg"}
                    style={{ marginRight: 10 }}
                  />
                  {localize(dict.app.affordableHeadline)}
                </span>{" "}
                <p>{localize(dict.app.affordableBody)}</p>
              </li>
              <li>
                <span>
                  <FontAwesomeIcon
                    icon={faHeart}
                    color={"#fe6551"}
                    size={"lg"}
                    style={{ marginRight: 10 }}
                  />
                  {localize(dict.app.qualityCareHeadline)}
                </span>{" "}
                <p>{localize(dict.app.qualityCareBody)}</p>
              </li>
              <li>
                <span>
                  <FontAwesomeIcon
                    icon={faUsers}
                    color={"#fe6551"}
                    size={"lg"}
                    style={{ marginRight: 10 }}
                  />
                  {localize(dict.app.cultureFirstHeadline)}
                </span>{" "}
                <p>{localize(dict.app.cultureFirstBody)}</p>
              </li>
              <li>
                <span>
                  <FontAwesomeIcon
                    icon={faCouch}
                    color={"#fe6551"}
                    size={"lg"}
                    style={{ marginRight: 10 }}
                  />
                  {localize(dict.app.convenienceHeadline)}
                </span>{" "}
                <p>{localize(dict.app.convenienceBody)}</p>
              </li>
              <li>
                <span>
                  <FontAwesomeIcon
                    icon={faLock}
                    color={"#fe6551"}
                    size={"lg"}
                    style={{ marginRight: 10 }}
                  />
                  {localize(dict.app.safetyAndConfidentialityHeadline)}
                </span>{" "}
                <p>{localize(dict.app.safetyAndConfidentialityBody)}</p>
              </li>
            </Ul>
          </div>
          <div style={{ flex: 1 }}>
            <img
              src={ProvidersImg}
              alt=""
              style={{ maxHeight: 1100, opacity: 0.8 }}
            />
          </div>
        </StyledContainer>
        <NavLink to={Routes.SIGN_IN}>
          <StyledButton.Button
            md={{
              width: "100%",
              maxWidth: 300,
              margin: "50px auto 0 auto",
            }}
          >
            <StyledButton.ButtonText>
              {localize(dict.app.bookFreeSessionNow)}
            </StyledButton.ButtonText>
          </StyledButton.Button>
        </NavLink>
      </StyledSection.Section>
    </div>
  </React.Fragment>
);

export default CareSeekers;
