const appScope = "app";

export default {
  example: `${appScope}.example`,
  bookFreeSessionNow: `${appScope}.bookFreeSessionNow`,
  careSeekers: `${appScope}.careSeekers`,
  providers: `${appScope}.providers`,
  aboutUs: `${appScope}.aboutUs`,
  blog: `${appScope}.blog`,
  contact: `${appScope}.contact`,
  dashboard: `${appScope}.dashboard`,
  emotionalWellBeingForTheLatinoCommunity: `${appScope}.emotionalWellBeingForTheLatinoCommunity`,
  benefitsOfOurPlatform: `${appScope}.benefitsOfOurPlatform`,
  howItWorks: `${appScope}.howItWorks`,
  tellUsAboutYourself: `${appScope}.tellUsAboutYourself`,
  getMatched: `${appScope}.getMatched`,
  startTherapy: `${appScope}.startTherapy`,
  benefitsOfOurPlatformHeadline: `${appScope}.benefitsOfOurPlatformHeadline`,
  affordableHeadline: `${appScope}.affordableHeadline`,
  affordableBody: `${appScope}.affordableBody`,
  qualityCareHeadline: `${appScope}.qualityCareHeadline`,
  qualityCareBody: `${appScope}.qualityCareBody`,
  cultureFirstHeadline: `${appScope}.cultureFirstHeadline`,
  cultureFirstBody: `${appScope}.cultureFirstBody`,
  convenienceHeadline: `${appScope}.convenienceHeadline`,
  convenienceBody: `${appScope}.convenienceBody`,
  safetyAndConfidentialityHeadline: `${appScope}.safetyAndConfidentialityHeadline`,
  safetyAndConfidentialityBody: `${appScope}.safetyAndConfidentialityBody`,
  lifeThreateningSituation: `${appScope}.lifeThreateningSituation`,
  switchToEnglish: `${appScope}.switchToEnglish`,
  switchToSpanish: `${appScope}.switchToSpanish`,
  privacyPolicy: `${appScope}.privacyPolicy`,
  logIn: `${appScope}.logIn`,
  getStarted: `${appScope}.getStarted`,
  gender: `${appScope}.gender`,
  specialties: `${appScope}.specialties`,
  certifications: `${appScope}.certifications`,
  yearsOfExperience: `${appScope}.yearsOfExperience`,
  passwordReset: `${appScope}.passwordReset`,
  newPassword: `${appScope}.newPassword`,
  confirmNewPassword: `${appScope}.confirmNewPassword`,
  registerNow: `${appScope}.registerNow`,
  registerForTeletherapy: `${appScope}.registerForTeletherapy`,
  thankYouForStartingYourWellnessJourney: `${appScope}.thankYouForStartingYourWellnessJourney`,
  whatRoleAreYouSeeking: `${appScope}.whatRoleAreYouSeeking`,
  client: `${appScope}.client`,
  admin: `${appScope}.admin`,
  comingSoon: `${appScope}.comingSoon`,
  forgotPassword: `${appScope}.forgotPassword`,
  password: `${appScope}.password`,
  email: `${appScope}.email`,
  hello: `${appScope}.hello`,
  therapists: `${appScope}.therapists`,
  appointments: `${appScope}.appointments`,
  male: `${appScope}.male`,
  female: `${appScope}.female`,
  bookNow: `${appScope}.bookNow`,
  years: `${appScope}.years`,
  year: `${appScope}.year`,
  back: `${appScope}.back`,
  submit: `${appScope}.submit`,
  save: `${appScope}.save`,
  select: `${appScope}.select`,
  areaOfSpecialty: `${appScope}.areaOfSpecialty`,
  yes: `${appScope}.yes`,
  no: `${appScope}.no`,
  dontHaveAnAccount: `${appScope}.dontHaveAnAccount`,
  whatDoYouSpecializeIn: `${appScope}.whatDoYouSpecializeIn`,
  preferNotToSay: `${appScope}.preferNotToSay`,
};
