import { Redirect } from "react-router-dom";
import { Routes } from "../Routes";
import jwt_decode from "jwt-decode";

export const PrivateRoute = ({ children }) =>
  localStorage.getItem("token") &&
  jwt_decode(localStorage.getItem("token")).approved ? (
    children
  ) : (
    <Redirect to={Routes.SIGN_IN} />
  );
