import React from "react";
import { Navbar } from "./Navbar";
import { StyledButton, StyledSection } from "../../components";
import Container from "../../components/Container";
import styled from "styled-components";
import Ul from "./Ul";
import {
  faCouch,
  faHeart,
  faLock,
  faUsers,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { device } from "../../utilities/responsive";
import { dict, localize } from "../../i18n";
import { Routes } from "../../Routes";
import { NavLink } from "react-router-dom";

const StyledContainer = styled(Container)`
  padding: 50px;

  h1 {
    font-family: "Martel Sans", sans-serif;
    margin-top: 0;
  }

  @media ${device.lg} {
    padding: 0;
  }
`;

const AboutUs = () => (
  <React.Fragment>
    <Navbar />
    <div>
      <StyledSection.Section style={{ paddingTop: 100, paddingBottom: 100 }}>
        <StyledContainer style={{ columnGap: 75, flexDirection: "column" }}>
          <div style={{ flex: 2 }}>
            <h1>{localize(dict.aboutUs.aboutUsHeadline)}</h1>
            <p style={{ marginBottom: 30 }}>
              {localize(dict.aboutUs.aboutUsSubCopy)}
            </p>
            <p style={{ marginBottom: 30 }}>
              <h4>{localize(dict.aboutUs.ourMissionHeadline)}</h4>
              {localize(dict.aboutUs.ourMissionSubCopy)}
            </p>
            <p>
              <h4 style={{ marginBottom: 30 }}>
                {localize(dict.aboutUs.ourPromiseHeadline)}
              </h4>
              <Ul>
                <li>
                  <span>
                    <FontAwesomeIcon
                      icon={faWallet}
                      color={"#fe6551"}
                      size={"lg"}
                      style={{ marginRight: 10 }}
                    />
                    {localize(dict.aboutUs.affordability)}
                  </span>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon
                      icon={faLock}
                      color={"#fe6551"}
                      size={"lg"}
                      style={{ marginRight: 10 }}
                    />
                    {localize(dict.aboutUs.safetyAndConfidentiality)}
                  </span>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon
                      icon={faCouch}
                      color={"#fe6551"}
                      size={"lg"}
                      style={{ marginRight: 10 }}
                    />
                    {localize(dict.aboutUs.convenience)}
                  </span>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon
                      icon={faHeart}
                      color={"#fe6551"}
                      size={"lg"}
                      style={{ marginRight: 10 }}
                    />
                    {localize(dict.aboutUs.qualityFirst)}
                  </span>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon
                      icon={faUsers}
                      color={"#fe6551"}
                      size={"lg"}
                      style={{ marginRight: 10 }}
                    />
                    {localize(dict.aboutUs.cultureFirst)}
                  </span>
                </li>
              </Ul>
            </p>
          </div>
          {/*<div*/}
          {/*  style={{*/}
          {/*    flex: 1,*/}
          {/*    display: "flex",*/}
          {/*    flexDirection: "column",*/}
          {/*    alignItems: "center",*/}
          {/*    justifyContent: "flex-end",*/}
          {/*    rowGap: 30,*/}
          {/*  }}*/}
          {/*></div>*/}

          <NavLink to={Routes.SIGN_IN}>
            <StyledButton.Button
              md={{
                width: "100%",
                maxWidth: 300,
                margin: "50px auto 0 auto",
              }}
            >
              <StyledButton.ButtonText>
                {localize(dict.app.bookFreeSessionNow)}
              </StyledButton.ButtonText>
            </StyledButton.Button>
          </NavLink>
        </StyledContainer>
      </StyledSection.Section>
    </div>
  </React.Fragment>
);

export default AboutUs;
