import styled from "styled-components";
import { device } from "../../utilities/responsive";

const DesktopNavDrawer = styled.div`
  display: none;
  flex: 1;

  @media ${device.lg} {
    display: flex;
  }
`;

export default DesktopNavDrawer;
