import { localize, dict } from "../i18n";

const GenderSelect = ({ name, value, onChange, onBlur }) => {
  return (
    <select
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={{
        maxWidth: 380,
        width: "100%",
        padding: "12px 13px",
        borderRadius: 8,
      }}
    >
      <option value="Male">{localize(dict.app.male)}</option>
      <option value="Female">{localize(dict.app.female)}</option>
      <option value="No">{localize(dict.app.preferNotToSay)}</option>
    </select>
  );
};

export default GenderSelect;
