const providersScope = "providers";

export default {
  providersHeadline: `${providersScope}.providersHeadline`,
  providersSubCopy: `${providersScope}.providersSubCopy`,
  whyJoinSevelyn: `${providersScope}.whyJoinSevelyn`,
  freeToJoin: `${providersScope}.freeToJoin`,
  freeToJoinSubCopy: `${providersScope}.freeToJoinSubCopy`,
  focusOnWhatYouLove: `${providersScope}.focusOnWhatYouLove`,
  focusOnWhatYouLoveSubCopy: `${providersScope}.focusOnWhatYouLoveSubCopy`,
  workFlexibility: `${providersScope}.workFlexibility`,
  workFlexibilitySubCopy: `${providersScope}.workFlexibilitySubCopy`,
  supplementalIncome: `${providersScope}.supplementalIncome`,
  supplementalIncomeSubCopy: `${providersScope}.supplementalIncomeSubCopy`,
  increaseYourKnowledge: `${providersScope}.increaseYourKnowledge`,
  increaseYourKnowledgeSubCopy: `${providersScope}.increaseYourKnowledgeSubCopy`,
  applicationForm: `${providersScope}.applicationForm`,
  firstName: `${providersScope}.firstName`,
  lastName: `${providersScope}.lastName`,
  email: `${providersScope}.email`,
  confirmEmail: `${providersScope}.confirmEmail`,
  phoneNumber: `${providersScope}.phoneNumber`,
  whatsAppNumber: `${providersScope}.whatsAppNumber`,
  nationality: `${providersScope}.nationality`,
  gender: `${providersScope}.gender`,
  howAreYouWillingToSeePatients: `${providersScope}.howAreYouWillingToSeePatients`,
  individuals: `${providersScope}.individuals`,
  couples: `${providersScope}.couples`,
  groups: `${providersScope}.groups`,
  areaOfSpeciality: `${providersScope}.areaOfSpecialty`,
  yearsOfExperience: `${providersScope}.yearsOfExperience`,
  certifications: `${providersScope}.certifications`,
  consentDialog: `${providersScope}.consentDialog`,
  mustProvideYearsOfExperience: `${providersScope}.mustProvideYearsOfExperience`,
  mustHaveAtLeastThreeYearsOfExperience: `${providersScope}.mustHaveAtLeastThreeYearsOfExperience`,
  pleaseEnterInformationRegardingAreaOfSpecialty: `${providersScope}.pleaseEnterInformationRegardingAreaOfSpecialty`,
  mustAcceptTermsOfUseAndPrivacyPolicy: `${providersScope}.mustAcceptTermsOfUseAndPrivacyPolicy`,
  successfullySentProviderIntakeInformation: `${providersScope}.successfullySentProviderIntakeInformation`,
};
