import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { NavLink as BSNavLink } from "react-bootstrap";
import { StyledButton } from "../../components";
import { Navbar as BaseNavbar } from "../../components/Navbar";
import Container from "../../components/Container";
import { Routes } from "../../Routes";
import { dict, i18n, localize } from "../../i18n";
import styled from "styled-components";
import { device } from "../../utilities/responsive";

const StyledBSNavLink = styled(BSNavLink)`
  padding: 15px 20px;

  @media ${device.lg} {
    padding: 0;
  }
`;

export const Navbar = () => {
  const userAuthenticated = localStorage.getItem("token");
  const lng = i18n.language;
  const lngIsSpanish = /es/.test(lng);
  const history = useHistory();

  return (
    <BaseNavbar>
      <Container style={{ justifyContent: "flex-end" }}>
        <div
          style={{
            fontFamily: "Martel Sans",
            display: "flex",
            alignItems: "center",
            flex: 1,
            justifyContent: "space-between",
            fontSize: 13,
          }}
        >
          <NavLink to={"/care-seekers"}>
            <span>{localize(dict.app.careSeekers)}</span>
          </NavLink>
          <NavLink to={"/providers"}>
            <span>{localize(dict.app.providers)}</span>
          </NavLink>
          <NavLink to={"/about-us"}>
            <span>{localize(dict.app.aboutUs)}</span>
          </NavLink>
          {lngIsSpanish && (
            <NavLink to={"/blog"}>
              <span>{localize(dict.app.blog)}</span>
            </NavLink>
          )}
          <NavLink to={"/contact"}>
            <span>{localize(dict.app.contact)}</span>
          </NavLink>
          <StyledBSNavLink
            onClick={() => {
              localStorage.setItem("lng", lngIsSpanish ? "en" : "es");
              history.go(0);
            }}
          >
            {lngIsSpanish ? (
              <span style={{ color: "#fe6551" }}>
                {localize(dict.app.switchToEnglish)}
              </span>
            ) : (
              <span style={{ color: "#fe6551" }}>
                {localize(dict.app.switchToSpanish)}
              </span>
            )}
          </StyledBSNavLink>
          {userAuthenticated ? (
            <NavLink to={Routes.DASHBOARD}>
              <StyledButton.Button
                style={{ flex: "1 0 0%", maxWidth: 150, width: "100%" }}
              >
                <StyledButton.ButtonText>
                  {localize(dict.app.dashboard)}
                </StyledButton.ButtonText>
              </StyledButton.Button>
            </NavLink>
          ) : (
            <React.Fragment>
              <NavLink to={Routes.SIGN_IN}>
                <StyledButton.Button
                  style={{
                    flex: "1",
                    width: lngIsSpanish ? 125 : 105,
                    padding: "12px 6px",
                  }}
                >
                  <StyledButton.ButtonText style={{ fontSize: 11 }}>
                    {localize(dict.app.logIn)}
                  </StyledButton.ButtonText>
                </StyledButton.Button>
              </NavLink>
              <NavLink to={Routes.REGISTRATION}>
                <StyledButton.Button
                  style={{
                    flex: "1",
                    width: lngIsSpanish ? 125 : 105,
                    padding: "12px 6px",
                  }}
                >
                  <StyledButton.ButtonText style={{ fontSize: 11 }}>
                    {localize(dict.app.getStarted)}
                  </StyledButton.ButtonText>
                </StyledButton.Button>
              </NavLink>
            </React.Fragment>
          )}
        </div>
      </Container>
    </BaseNavbar>
  );
};
