import * as React from "react";
import { Navbar } from "../Home/Navbar";
import styled from "styled-components";
import Container from "../../components/Container";
import { device } from "../../utilities/responsive";
import { StyledSection } from "../../components";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";

const StyledContainer = styled(Container)`
  padding: 50px;

  h1 {
    font-family: "Martel Sans", sans-serif;
    margin-top: 0;
  }

  @media ${device.lg} {
    padding: 0;
  }
`;

const Post = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(async () => {
    const response = await axios({
      method: "get",
      url: `https://aqueous-oasis-97995.herokuapp.com/api/articles?filters[slug][$eq]=${slug}&populate=image`,
    });

    setPost(response.data?.data[0].attributes);
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <div>
        <StyledSection.Section style={{ paddingTop: 100, paddingBottom: 100 }}>
          <StyledContainer style={{ columnGap: 75, flexDirection: "column" }}>
            {post ? (
              <React.Fragment>
                <div>
                  <h1 style={{ textAlign: "center", marginBottom: 50 }}>
                    {post.title}
                  </h1>
                  {post.timeToRead && <span>Tiempo: ${post.timeToRead}</span>}
                </div>
                <img
                  src={post.image.data.attributes.url}
                  style={{ marginBottom: 50 }}
                />
                <ReactMarkdown
                  allowDangerousHtml={true}
                  children={post.content}
                />
              </React.Fragment>
            ) : null}
          </StyledContainer>
        </StyledSection.Section>
      </div>
    </React.Fragment>
  );
};

export default Post;
