import { NavLink } from "react-router-dom";
import { Routes } from "../../Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const DesktopActions = () => {
  return [
    <div
      style={{
        maxWidth: 70,
        width: "100%",
        display: "flex",
        marginRight: 10,
      }}
    >
      <NavLink to={Routes.SETTINGS}>
        <FontAwesomeIcon icon={faCog} color={"rgb(254, 101, 81)"} size={"lg"} />
      </NavLink>
      <NavLink to={() => Routes.SIGN_OUT}>
        <FontAwesomeIcon
          icon={faSignOutAlt}
          color={"rgb(254, 101, 81)"}
          size={"lg"}
          style={{ marginLeft: 20 }}
        />
      </NavLink>
    </div>,
  ];
};

export default DesktopActions;
