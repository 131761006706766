export default {
  whatRoleAreYouSeeking: "Crear cuenta como",
  client: "Usuario",
  registerNow: "Regístrate Ahora",
  registerForTeletherapy:
    "Gracias por comenzar tu viaje de bienestar emocional con Sevelyn.",
  thankYou:
    "Gracias por comenzar tu viaje de bienestar emocional con Sevelyn. El proceso de registro tardará menos de dos minutos en completarse.",
  whatTypeOfTherapy: "¿Qué tipo de terapia buscas?",
  therapyForMyself: "Terapia para mi",
  couplesTherapy: "Terapia de pareja",
  howOldAreYou: "¿Cual es tu edad?",
  twelveAndUnder: "12 o menor",
  howDidYouHearAboutSevelyn: "¿Cómo encontraste a Sevelyn?",
  googleSearch: "Búsqueda de Google",
  wordOfMouth: "Boca a Boca",
  other: "Otro",
  next: "Siguiente Página",
  confirmPassword: "Confirmar nueva contraseña",
  password: "Contraseña",
  firstName: "Nombre",
  lastName: "Apellido",
  email: "Email",
  cellPhoneNumber: "Número de Celular",
  dontWorry:
    "No te preocupes, solo te contactaremos sobre asuntos relacionados a tu cita.",
  back: "Página Anterior",
  tellUsTheType:
    "Indica tus preferencias para conectarte al especialista adecuado.",
  gender: "Género",
  male: "Hombre",
  female: "Mujer",
  noPreference: "No tengo preferencia",
  therapistWhoSpecializesInLGBTQ:
    "¿Buscas un especialista con experiencia en temas LGBTQ?",
  specializesInLGBTQ: "¿Experiencia en temas LGBTQ?",
  spiritualOrientation: "Orientación espiritual",
  believer: "Creyente",
  nonBeliever: "No creyente",
  countryOfOrigin: "País de Residencia",
  done: "Enviar",
  provider: "Especialista",
  tellUsAboutYourself: "Cuéntanos sobre ti",
  willBeAbleToFind:
    "Gracias por completar la siguiente información. Esto permitirá que nuestros usuarios te encuentren fácilmente dentro de nuestra plataforma.",
  whatCertificationsDoYouHave:
    "¿Tienes alguna certificación que te gustaría compartir?",
};
