import InputGroupContainer from "../../components/InputGroupContainer";
import { dict, localize } from "../../i18n";
import { StyledInput, StyledLabel } from "../../components";
import React from "react";
import styled from "styled-components";

const ErrorMessage = styled.div`
  color: red;
`;

const Group = styled(StyledLabel.Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;

  span {
    margin-bottom: 5px;
    font-size: 12px;
    color: #2b2b2b;
    letter-spacing: 0.7px;
    font-family: "Martel Sans", sans-serif;
  }

  ${({ style }) => ({ ...style })};
`;

const InputGroup = ({ style, children }) => (
  <Group style={{ ...style, maxWidth: 320, width: "100%" }}>{children}</Group>
);

const UserForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  editing,
}) => {
  return (
    <React.Fragment>
      <InputGroupContainer>
        <InputGroup>
          <span>{localize(dict.registration.firstName)} *</span>
          {editing ? (
            <React.Fragment>
              <StyledInput.Input
                type={"text"}
                name={"firstName"}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.firstName && touched.firstName}
              />
              <ErrorMessage>
                {errors.firstName && touched.firstName && errors.firstName}
              </ErrorMessage>
            </React.Fragment>
          ) : (
            <span>{values.firstName}</span>
          )}
        </InputGroup>
        <InputGroup>
          <span>{localize(dict.registration.lastName)} *</span>
          {editing ? (
            <React.Fragment>
              <StyledInput.Input
                type={"text"}
                name={"lastName"}
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.lastName && touched.lastName}
              />
              <ErrorMessage>
                {errors.lastName && touched.lastName && errors.lastName}
              </ErrorMessage>
            </React.Fragment>
          ) : (
            <span>{values.lastName}</span>
          )}
        </InputGroup>
      </InputGroupContainer>
      <InputGroupContainer>
        <InputGroup>
          <span>{localize(dict.registration.email)} *</span>
          {editing ? (
            <React.Fragment>
              <StyledInput.Input
                type={"email"}
                name={"email"}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.email && touched.email}
              />
              <ErrorMessage>
                {errors.email && touched.email && errors.email}
              </ErrorMessage>
            </React.Fragment>
          ) : (
            <span>{values.email}</span>
          )}
        </InputGroup>
        <InputGroup>
          <span>{localize(dict.registration.cellPhoneNumber)} *</span>
          {editing ? (
            <React.Fragment>
              <StyledInput.Input
                type={"tel"}
                name={"phoneNumber"}
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.phoneNumber && touched.phoneNumber}
              />
              <ErrorMessage>
                {errors.phoneNumber &&
                  touched.phoneNumber &&
                  errors.phoneNumber}
              </ErrorMessage>
            </React.Fragment>
          ) : (
            <span>{values.phoneNumber}</span>
          )}
        </InputGroup>
      </InputGroupContainer>
      <InputGroupContainer>
        <InputGroup>
          {editing ? <span>New Password</span> : null}
          {editing ? (
            <React.Fragment>
              <StyledInput.Input
                type={"password"}
                name={"newPassword"}
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.newPassword && touched.newPassword}
              />
              <ErrorMessage>
                {errors.newPassword &&
                  touched.newPassword &&
                  errors.newPassword}
              </ErrorMessage>
            </React.Fragment>
          ) : null}
        </InputGroup>
        <InputGroup>
          {editing ? <span>Confirm New Password</span> : null}
          {editing ? (
            <React.Fragment>
              <StyledInput.Input
                type={"password"}
                name={"confirmNewPassword"}
                value={values.confirmNewPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.confirmNewPassword && touched.confirmNewPassword}
              />
              <ErrorMessage>
                {errors.confirmNewPassword &&
                  touched.confirmNewPassword &&
                  errors.confirmNewPassword}
              </ErrorMessage>
            </React.Fragment>
          ) : null}
        </InputGroup>
      </InputGroupContainer>
      <InputGroupContainer>
        <InputGroup>
          <span>Roles</span>
          <div>
            {values.roles.map((role, idx) => {
              if (role === "ROLE_CLIENT")
                return <span key={"client"}>Client</span>;
              else if (role === "ROLE_THERAPIST")
                return <span key={"therapist"}>Therapist</span>;
              else if (role === "ROLE_ADMIN")
                return <span key={"admin"}>{idx > 0 && ", "}Admin</span>;
              else return <span key={"nothing"} />;
            })}
          </div>
        </InputGroup>
      </InputGroupContainer>
    </React.Fragment>
  );
};

export default UserForm;
