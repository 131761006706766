import React, { useState } from "react";
import { StyledContainer } from "../../components";
import styled from "styled-components";
import { Profile } from "./tabs/Profile";
import { TherapistPreferences } from "./tabs/TherapistPreferences";
import { ClientPreferences } from "./tabs/ClientPreferences";
import jwt_decode from "jwt-decode";
import UploadMedia from "./tabs/UploadMedia";
import { device } from "../../utilities/responsive";
import { localize, dict } from "../../i18n/index";

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(173, 173, 173, 0.5);
  background-color: #fff;
  align-self: flex-start;
  padding: 50px;
`;

const Tab = styled.div`
  padding: 20px 30px;
  border-radius: ${({ tab, activeTab }) =>
    tab === activeTab ? "4px 4px 0px 0px" : ""};
  border: ${({ tab, activeTab }) =>
    tab === activeTab ? "0px 0px 4px 0 rgb(173 173 173 / 50%)" : ""};
  background-color: ${({ tab, activeTab }) =>
    tab === activeTab ? "#fff" : ""};

  &:hover {
    cursor: pointer;
  }

  ${({ tab, activeTab }) => {
    return tab === activeTab ? { backgroundColor: "#fff" } : {};
  }}
`;

const StyledContainerContainer = styled(StyledContainer.Container)`
  justify-content: flex-start;
  padding: 30px;

  @media ${device.lg} {
    padding: 30px 10px 10px;
    justify-content: center;
  }
`;

export const Settings = (props) => {
  const [activeTab, setActiveTab] = useState("PROFILE");
  const { roles } = jwt_decode(localStorage.getItem("token"));
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#f3f3f3",
        minHeight: "100vh",
      }}
    >
      <StyledContainerContainer>
        <div style={{ maxWidth: 1000, width: "100%" }}>
          <div style={{ display: "flex" }}>
            <Tab
              activeTab={activeTab}
              tab={"PROFILE"}
              onClick={() => setActiveTab("PROFILE")}
            >
              {localize(dict.dashboard.credentials)}
            </Tab>
            {/*<Tab*/}
            {/*  activeTab={activeTab}*/}
            {/*  tab={"PASSWORD"}*/}
            {/*  onClick={() => setActiveTab("PASSWORD")}*/}
            {/*>*/}
            {/*  Password*/}
            {/*</Tab>*/}
            {/*<Tab*/}
            {/*  activeTab={activeTab}*/}
            {/*  tab={"BILLING"}*/}
            {/*  onClick={() => setActiveTab("BILLING")}*/}
            {/*>*/}
            {/*  Billing*/}
            {/*</Tab>*/}
            {roles.includes("ROLE_CLIENT") && (
              <Tab
                activeTab={activeTab}
                tab={"CLIENT_PREFERENCES"}
                onClick={() => setActiveTab("CLIENT_PREFERENCES")}
              >
                {localize(dict.dashboard.clientProfile)}
              </Tab>
            )}
            {roles.includes("ROLE_THERAPIST") && (
              <Tab
                activeTab={activeTab}
                tab={"THERAPIST_PREFERENCES"}
                onClick={() => setActiveTab("THERAPIST_PREFERENCES")}
              >
                {localize(dict.dashboard.therapistProfile)}
              </Tab>
            )}
            {roles.includes("ROLE_THERAPIST") && (
              <Tab
                activeTab={activeTab}
                tab={"MEDIA"}
                onClick={() => setActiveTab("MEDIA")}
              >
                {localize(dict.dashboard.media)}
              </Tab>
            )}
          </div>
          <Card
            style={{
              borderRadius: "0px 0px 4px 4px",
              boxShadow: "0px 3px 4px 0 rgb(173 173 173 / 50%)",
            }}
          >
            {activeTab === "PROFILE" ? <Profile /> : null}
            {/*{activeTab === "PASSWORD" ? <Password /> : null}*/}
            {/*{activeTab === "BILLING" ? <Billing /> : null}*/}
            {activeTab === "CLIENT_PREFERENCES" &&
            roles.includes("ROLE_CLIENT") ? (
              <ClientPreferences />
            ) : null}
            {activeTab === "THERAPIST_PREFERENCES" &&
            roles.includes("ROLE_THERAPIST") ? (
              <TherapistPreferences />
            ) : null}
            {activeTab === "MEDIA" && roles.includes("ROLE_THERAPIST") ? (
              <UploadMedia />
            ) : null}
          </Card>
        </div>
      </StyledContainerContainer>
    </div>
  );
};
