import styled from "styled-components";
import { activeStateStyles } from "./Pill.utility";

export const StyledPill = styled.div`
  min-width: 281px;
  height: 35px;
  margin-bottom: 50px;
  border-radius: 17.5px;
  border: solid 1px #fe6551;
  display: flex;
  justify-content: space-between;
  font-family: "Martel Sans", sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #fe6551;
`;

const StyledPillOption = styled.div`
  display: flex;
  flex-grow: 1;
  height: 34px;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  ${({ active }) => activeStateStyles(active)}
`;

export const StyledPillLeftOption = styled(StyledPillOption)`
  border-radius: 17.5px 0 0 17.5px;
`;

export const StyledPillRightOption = styled(StyledPillOption)`
  border-radius: 0 17.5px 17.5px 0;
`;
