import styled from "styled-components";

const Ul = styled.ul`
  font-family: "Martel Sans Regular", sans-serif;
  font-size: 20px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 0;

  span {
    font-weight: bold;
    font-size: 1.2rem;
  }

  p {
    font-size: 1.05rem;
  }

  li {
    margin-bottom: ${({ liMarginBottom }) =>
      liMarginBottom ? liMarginBottom : "15px"};
  }

  ${({ style }) => ({ ...style })};
`;

export default Ul;
