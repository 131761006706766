export default {
  contactUsHeadline: "Contact Us",
  contactUsSubCopy:
    "Do you have questions about us, our services, or our network of providers? We're here to help!",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  confirmEmail: "Confirm Email",
  howCanWeHelpYou: "How Can We Help?",
};
