import { StyledButton, StyledSection } from "../../../components";
import * as React from "react";
import styled from "styled-components";
import Container from "../../../components/Container";
import { Routes } from "../../../Routes";
import { NavLink } from "react-router-dom";
import { device } from "../../../utilities/responsive";
import { dict, localize } from "../../../i18n";

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  column-gap: 50px;
  flex-direction: column;
  align-items: center;
  row-gap: 75px;
  flex-flow: wrap;

  @media ${device.lg} {
    flex-direction: row;
    align-items: stretch;
    row-gap: 50px;
  }
`;

const StyledContainerContainer = styled(Container)`
  flex-direction: column;
  justify-content: space-around;
  padding: 75px 50px;

  @media ${device.lg} {
    padding: 75px 0;
  }
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 168px;
  min-width: 241px;
  border-radius: 10px;
  background-color: #ff7875;
  color: #ffffff;
  padding: 20px;
  flex-grow: 1;
  max-width: 300px;

  span {
    text-align: center;
  }
`;

export const PricingSection = () => (
  <StyledSection.Section>
    <StyledContainerContainer>
      <h2
        style={{
          fontFamily: "Cantata One",
          fontSize: 30,
          textAlign: "center",
          marginBottom: 50,
          fontWeight: "normal",
        }}
      >
        {localize(dict.pricing.pricing)}
      </h2>
      <StyledDiv>
        <StyledCard>
          <span style={{ fontSize: 20 }}>
            {localize(dict.pricing.freeFirstSession)}
          </span>
          <span style={{ fontSize: 36, fontWeight: "bold" }}>
            {localize(dict.pricing.freeFirstSessionPrice)}
          </span>
          <span>{localize(dict.pricing.fourtyFiveMinutes)}</span>
          <span style={{ fontSize: 16 }}>
            {localize(dict.pricing.valueOfFifty)}
          </span>
        </StyledCard>
        <StyledCard>
          <span style={{ fontSize: 20 }}>
            {localize(dict.pricing.individualSession)}
          </span>
          <span style={{ fontSize: 36, fontWeight: "bold" }}>
            {localize(dict.pricing.fifty)}
          </span>
          <span style={{ fontSize: 16 }}>
            {localize(dict.pricing.fourtyFiveMinutesEach)}
          </span>
        </StyledCard>
        <StyledCard>
          <span style={{ fontSize: 20 }}>
            {localize(dict.pricing.couplesSession)}
          </span>
          <span style={{ fontSize: 36, fontWeight: "bold" }}>
            {localize(dict.pricing.sixty)}
          </span>
          <span style={{ fontSize: 16 }}>
            {localize(dict.pricing.fourtyFiveMinutesEach)}
          </span>
        </StyledCard>
        <StyledCard>
          <span style={{ fontSize: 20 }}>
            {localize(dict.pricing.packetOfFourSessions)}
          </span>
          <span style={{ fontSize: 36, fontWeight: "bold" }}>
            {localize(dict.pricing.oneHundredEighty)}
          </span>
          <span style={{ fontSize: 16 }}>
            {localize(dict.pricing.fourtyFiveMinutesEach)}
          </span>
          <span style={{ fontSize: 16 }}>
            {localize(dict.pricing.savingsOfTwenty)}
          </span>
        </StyledCard>
        <StyledCard>
          <span style={{ fontSize: 20 }}>
            {localize(dict.pricing.packetOfTenSessions)}
          </span>
          <span style={{ fontSize: 36, fontWeight: "bold" }}>
            {localize(dict.pricing.fourHundred)}
          </span>
          <span style={{ fontSize: 16 }}>
            {localize(dict.pricing.fourtyFiveMinutesEach)}
          </span>
          <span style={{ fontSize: 16 }}>
            {localize(dict.pricing.savingsOfOneHundred)}
          </span>
        </StyledCard>
      </StyledDiv>
      <NavLink to={Routes.SIGN_IN}>
        <StyledButton.Button
          style={{ marginTop: 50 }}
          md={{ width: "100%", maxWidth: 300, margin: "0 auto" }}
        >
          <StyledButton.ButtonText>
            {localize(dict.app.bookFreeSessionNow)}
          </StyledButton.ButtonText>
        </StyledButton.Button>
      </NavLink>
    </StyledContainerContainer>
  </StyledSection.Section>
);
