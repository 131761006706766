import React from "react";

export const createVideoConference = ({ roomName, close }) => {
  const jitsiContainerId = "jitsi-container-id";
  let jitsi = {};

  const loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null;

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const script = document.createElement("script");
    script.src = "https://meet.jit.si/external_api.js";
    script.async = true;
    script.onload = resolveLoadJitsiScriptPromise;
    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };

  (async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }

    const _jitsi = new window.JitsiMeetExternalAPI("meet.jit.si", {
      roomName,
      parentNode: document.getElementById(jitsiContainerId),
    });

    _jitsi.addListener("readyToClose", () => {
      close();
      window.location.reload();
    });

    jitsi = _jitsi;
  })();

  const renderComponent = () => (
    <div id={jitsiContainerId} style={{ height: 720, width: "100%" }} />
  );

  return { dispose: () => jitsi?.dispose, renderComponent };
};
