import { dict, localize } from "../../i18n";
import React, { useState } from "react";
import UserForm from "./UserForm";
import { StyledButton } from "../../components";
import { requestPasswordReset } from "../forgot-password";
import { Formik } from "formik";
import { CardFooter } from "./UserCard";
import { toast } from "react-hot-toast";
import axios from "axios";

const UserCredentialsForm = ({ showUser }) => {
  const [editing, setEditing] = useState(false);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        firstName: showUser.firstName,
        lastName: showUser.lastName,
        email: showUser.email,
        phoneNumber: showUser.phoneNumber,
        newPassword: "",
        confirmNewPassword: "",
        roles: showUser.roles,
      }}
      validate={(values) => {
        const errors = {};
        if (!values.firstName) {
          errors.firstName = localize(dict.errors.required);
        }

        if (!values.lastName) {
          errors.lastName = localize(dict.errors.required);
        }

        if (!values.email) {
          errors.email = localize(dict.errors.required);
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = localize(dict.errors.invalidEmailAddress);
        }

        if (values.confirmNewPassword !== values.newPassword) {
          errors.confirmNewPassword = localize(
            dict.errors.mustMatchInitialPassword
          );
        }

        return errors;
      }}
      onSubmit={async (values, actions) => {
        try {
          const credentialsResponse = await axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/api/admin/update-credentials`,
            data: {
              _id: showUser._id,
              ...values,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          return toast.success("Successfully updated credentials.");
        } catch (err) {
          if (err.response) {
            console.error("err: ", err.response.data.message);
            return toast.error(`Error: ${err.response.data.message}`);
          }
        }
        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <React.Fragment>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 10,
              marginTop: 75,
              marginBottom: 75,
            }}
          >
            <UserForm
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              editing={editing}
            />
          </form>
          <CardFooter>
            <StyledButton.Button
              onClick={() => requestPasswordReset(values.email)}
            >
              <StyledButton.ButtonText>
                Send Password Reset
              </StyledButton.ButtonText>
            </StyledButton.Button>
            <StyledButton.Button onClick={() => setEditing(!editing)}>
              <StyledButton.ButtonText>Edit</StyledButton.ButtonText>
            </StyledButton.Button>
            <StyledButton.Button onClick={handleSubmit}>
              <StyledButton.ButtonText>Update</StyledButton.ButtonText>
            </StyledButton.Button>
          </CardFooter>
        </React.Fragment>
      )}
    </Formik>
  );
};

export default UserCredentialsForm;
