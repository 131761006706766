import styled from "styled-components";
import { device } from "../../utilities/responsive";

const StyledNavbar = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(173, 173, 173, 0.5);
  background-color: #fff;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1000;

  a {
    color: #2b2b2b;
  }

  .topnav-main-navigation {
    display: flex;
    flex: 0;
    justify-content: space-between;
    align-items: center;
  }

  .menu-drawer-toggle {
    display: flex;

    @media ${device.lg} {
      display: none;
    }
  }

  .open {
    display: flex !important;
  }

  @media ${device.lg} {
    position: relative;
  }
`;

export default StyledNavbar;
