import { Card } from "../../components/Card/Card";
import * as React from "react";
import styled from "styled-components";
import { device } from "../../utilities/responsive";
import { useTable, useFilters, useGlobalFilter } from "react-table";
import { Sidebar, specialties } from "./Sidebar";
import { matchSorter } from "match-sorter";
import SpecialtiesFilter from "./SpecialtiesFilter";
import GendersFilter from "./GendersFilter";
import MyTherapistsFilter from "./MyTherapistsFilter";

const StyledCards = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 50px;
  padding: 30px;
  align-items: center;
  min-height: 500px;
  order: 2;

  @media ${device.xl} {
    order: 1;
    overflow: scroll;
    padding: 30px 10px 500px 10px;
  }
`;

const TherapistsTable = ({ users, favorites, setFavorites }) => {
  const data = React.useMemo(() => users, [users]);
  const columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
        filter: "fuzzyText",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        filter: "fuzzyText",
      },
      {
        Header: "Gender",
        accessor: "therapist.therapistGender",
        Filter: (props) => <GendersFilter {...props} />,
        filter: "gendersMultiSelect",
      },
      {
        Header: "Specialties",
        accessor: "therapist.specialties",
        Filter: (props) => <SpecialtiesFilter {...props} />,
        filter: "specialtiesMultiSelect",
      },
      {
        Header: "MyTherapists",
        Filter: (props) => <MyTherapistsFilter {...props} />,
        filter: "myTherapistsFilter",
      },
    ],
    []
  );

  const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  };

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      gendersMultiSelect: (rows, id, filterValues) => {
        if (filterValues.length === 0) return rows;
        return rows.filter((row) =>
          filterValues.some((val) => {
            return row.values["therapist.therapistGender"] === val;
          })
        );
      },
      specialtiesMultiSelect: (rows, id, filterValues) => {
        if (filterValues.length === 0) return rows;
        return rows.filter((row) =>
          filterValues.some((v) => {
            const convertedV = Object.keys(specialties).find(
              (specialty) => specialties[specialty].value === v
            );

            return row.values["therapist.specialties"].includes(convertedV);
          })
        );
      },
      myTherapistsFilter: (rows, id, { myTherapists, favorites }) => {
        if (myTherapists) {
          return rows.filter((row) => {
            return favorites[row.original._id];
          });
        }
        return rows;
      },
    }),
    []
  );

  const tableInstance = useTable(
    { columns, data, filterTypes },
    useGlobalFilter,
    useFilters
  );

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    preGlobalFilteredRows,
    headerGroups,
  } = tableInstance;

  return (
    <React.Fragment>
      <StyledCards {...getTableProps()} {...getTableBodyProps()}>
        {rows.map((row, idx) => {
          prepareRow(row);
          return (
            <Card
              key={idx}
              user={row.original}
              row={row}
              favorited={favorites[row.original._id]}
              setFavorites={setFavorites}
            />
          );
        })}
      </StyledCards>
      <Sidebar
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        GendersComponent={headerGroups[0].headers[2]}
        SpecialtiesComponent={headerGroups[0].headers[3]}
        MyTherapistsComponent={headerGroups[0].headers[4]}
        favorites={favorites}
      />
    </React.Fragment>
  );
};

export default TherapistsTable;
