const pricingScope = "pricing";

export default {
  pricing: `${pricingScope}.pricing`,
  freeFirstSession: `${pricingScope}.freeFirstSession`,
  freeFirstSessionPrice: `${pricingScope}.freeFirstSessionPrice`,
  fourtyFiveMinutes: `${pricingScope}.fourtyFiveMinutes`,
  fourtyFiveMinutesEach: `${pricingScope}.fourtyFiveMinutesEach`,
  individualSession: `${pricingScope}.individualSession`,
  fifty: `${pricingScope}.fifty`,
  valueOfFifty: `${pricingScope}.valueOfFifty`,
  packetOfFourSessions: `${pricingScope}.packetOfFourSessions`,
  oneHundredEighty: `${pricingScope}.oneHundredEighty`,
  savingsOfTwenty: `${pricingScope}.savingsOfTwenty`,
  packetOfTenSessions: `${pricingScope}.packetOfTenSessions`,
  fourHundred: `${pricingScope}.fourHundred`,
  savingsOfOneHundred: `${pricingScope}.savingsOfOneHundred`,
  sixty: `${pricingScope}.sixty`,
  couplesSession: `${pricingScope}.couplesSession`,
};
