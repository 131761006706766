import React, { useEffect, useRef } from "react";
import { Card } from "./Card";
import { useState } from "react";
import styled from "styled-components";
import UserCredentialsForm from "./UserCredentialsForm";
import TherapistPreferencesForm from "./TherapistPreferencesForm";
import { StyledNavLink } from "./Admin";
import ClientPreferencesForm from "./ClientPreferencesForm";

export const CardFooter = styled.div`
  margin-top: 50px;
  display: flex;
  column-gap: 20px;
`;

export const CardButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  .groupTitle {
    font-weight: bold;
  }
`;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const UserCard = ({ showUser }) => {
  const [match, setMatch] = useState("USER_CREDENTIALS");
  const prevShowUser = usePrevious(showUser);

  useEffect(() => {
    if (prevShowUser && prevShowUser._id !== showUser._id) {
      setMatch("USER_CREDENTIALS");
    }
  }, [showUser]);

  return showUser ? (
    <Card style={{ overflow: "scroll", rowGap: 20 }}>
      <div style={{ display: "flex", columnGap: 20 }}>
        <StyledNavLink
          match={match === "USER_CREDENTIALS"}
          onClick={() => setMatch("USER_CREDENTIALS")}
        >
          User Credentials
        </StyledNavLink>
        {showUser.roles.includes("ROLE_CLIENT") ? (
          <StyledNavLink
            match={match === "CLIENT_PREFERENCES"}
            onClick={() => setMatch("CLIENT_PREFERENCES")}
          >
            Client Preferences
          </StyledNavLink>
        ) : null}
        {showUser.roles.includes("ROLE_THERAPIST") ? (
          <StyledNavLink
            match={match === "THERAPIST_PREFERENCES"}
            onClick={() => setMatch("THERAPIST_PREFERENCES")}
          >
            Therapist Preferences
          </StyledNavLink>
        ) : null}
      </div>
      {match === "USER_CREDENTIALS" ? (
        <UserCredentialsForm showUser={showUser} />
      ) : null}
      {match === "CLIENT_PREFERENCES" ? (
        <ClientPreferencesForm showUser={showUser} />
      ) : null}
      {match === "THERAPIST_PREFERENCES" ? (
        <TherapistPreferencesForm showUser={showUser} />
      ) : null}
    </Card>
  ) : null;
};

export default UserCard;
