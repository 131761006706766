import styled from "styled-components";

const PagesComponent = styled.div`
  font-family: "Martel Sans", sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #2b2b2b;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

export default PagesComponent;
