import styled from "styled-components";

const StyledButton = styled.div`
  padding: 12px 13px;
  border-radius: 8px;
  background-color: #fe6551;
  justify-content: center;
  align-items: center;
  flex: 1;

  &:hover {
    cursor: pointer;
    transition: 0.5ms filter;
    filter: brightness(0.9);
  }

  span {
    display: flex;
    justify-content: center;
    font-family: ${({ theme: { fonts } }) => fonts.martel};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.05px;
    text-align: center;
    color: #fff;

    ${({ textStyle }) => ({ ...textStyle })};
  }

  ${({ style }) => ({ ...style })};
`;

const Button = (props) => (
  <StyledButton {...props}>
    <span>{props.children}</span>
  </StyledButton>
);

export default Button;
