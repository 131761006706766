import { useEffect, useState } from "react";
import axios from "axios";
import * as React from "react";
import { matchSorter } from "match-sorter";
import { useFilters, useGlobalFilter, useTable } from "react-table";
import styled from "styled-components";

const CancellationRequestButton = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    background-color: rgba(254, 101, 81, 0.34);
  }
`;

const CancellationRequestsList = (props) => {
  const [cancellationRequests, setCancellationRequests] = useState([]);

  useEffect(async () => {
    const users = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/admin/cancellation-requests`,
      {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "cache-control": "no-cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    setCancellationRequests(users.data);
  }, []);

  const data = React.useMemo(
    () => cancellationRequests,
    [cancellationRequests]
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "Client",
        accessor: "client",
        filter: "fuzzyText",
      },
      {
        Header: "Therapist",
        accessor: "therapist",
        filter: "fuzzyText",
      },
    ],
    []
  );

  const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  };

  const filterTypes = React.useMemo(
    () => ({ fuzzyText: fuzzyTextFilterFn }),
    []
  );

  const tableInstance = useTable(
    { columns, data, filterTypes },
    useGlobalFilter,
    useFilters
  );

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    preGlobalFilteredRows,
    headerGroups,
  } = tableInstance;

  return (
    <div
      style={{
        maxHeight: 500,
        width: "100%",
        overflowY: "scroll",
        paddingBottom: 50,
      }}
      {...getTableProps()}
      {...getTableBodyProps()}
    >
      {rows.map((cancellationRequest) => {
        prepareRow(cancellationRequest);
        return (
          <CancellationRequestButton
            key={cancellationRequest.original.original}
            onClick={() =>
              props.setShowCancellation(cancellationRequest.original)
            }
          >
            <div style={{ display: "flex", columnGap: 20 }}>
              <div style={{ flex: 1 }}>
                <div style={{ fontWeight: "bold" }}>Client</div>
                {cancellationRequest.original.client.firstName}{" "}
                {cancellationRequest.original.client.lastName}
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ fontWeight: "bold" }}>Therapist</div>
                {cancellationRequest.original.therapist.firstName}{" "}
                {cancellationRequest.original.therapist.lastName}
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ fontWeight: "bold" }}>Date</div>
                {new Date(
                  cancellationRequest.original.requestCancellationDate
                ).toString()}
              </div>
            </div>
          </CancellationRequestButton>
        );
      })}
    </div>
  );
};

export default CancellationRequestsList;
