import { StyledButton, StyledInput, StyledLabel } from "../../../components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import jwt_decode from "jwt-decode";
import axios from "axios";
import CountrySelect from "../../../components/CountrySelect";
import { Formik, Field } from "formik";
import { specialties } from "../../Dashboard/Sidebar";
import { toast } from "react-hot-toast";
import { localize, dict } from "../../../i18n/index";

const ButtonGroup = styled.div`
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  max-width: 650px;

  > div {
    max-width: 150px;
    width: 100%;
    border: 1px solid #9d9d9d;
    background-color: white;

    span {
      color: #2b2b2b;
      font-family: "Martel Sans", sans-serif;
      font-weight: normal;
    }
  }
`;

const Label = styled(StyledLabel.Label)`
  h4 {
    margin-top: 0;
  }

  textarea {
    max-width: 490px;
    width: 100%;
    min-height: 150px;
  }
`;

const Group = styled(StyledLabel.Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;

  span {
    margin-bottom: 5px;
    font-size: 12px;
    color: #2b2b2b;
    letter-spacing: 0.7px;
    font-family: "Martel Sans", sans-serif;
  }

  ${({ style }) => ({ ...style })};
`;

const StyledTextArea = styled.input`
  min-height: 150px;
  max-width: 490px;
  width: 100%;
`;

const InputGroup = ({ style, children }) => (
  <Group style={{ ...style, maxWidth: 320, width: "100%" }}>{children}</Group>
);

const Button = ({ children, onClick, selected }) => (
  <StyledButton.Button
    style={{ maxWidth: 150 }}
    onClick={onClick}
    selected={selected}
    clickable={true}
  >
    <StyledButton.ButtonText onClick={onClick}>
      {children}
    </StyledButton.ButtonText>
  </StyledButton.Button>
);

export const TherapistPreferences = ({ shownUserId }) => {
  const [therapist, setTherapist] = useState(null);
  const [gender, setGender] = useState(null);
  const [orientation, setOrientation] = useState(null);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [LGBTQ, setLGBTQ] = useState(null);
  const userId = shownUserId
    ? shownUserId
    : jwt_decode(localStorage.getItem("token")).id;
  const [profileImage, setProfileImage] = useState(null);

  const fetchTherapist = async () => {
    return await axios.get(
      `${process.env.REACT_APP_API_URL}/api/therapists/${userId}`,
      {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "cache-control": "no-cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  };

  useEffect(async () => {
    const res = await fetchTherapist();

    if (!res.data) {
      return setTherapist({});
    }

    setTherapist(res?.data);

    const {
      therapistGender,
      therapistLGBTQ,
      therapistOrientation,
      therapistCountryOfOrigin,
      specialties,
    } = res?.data;

    setGender(therapistGender);
    setOrientation(therapistOrientation);
    setSelectedSpecialties(specialties);
    setLGBTQ(therapistLGBTQ);
  }, []);

  const handleProfileImage = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      return setProfileImage(URL.createObjectURL(file));
    }
  };

  return therapist ? (
    <Formik
      initialValues={{
        therapistCountryOfOrigin: therapist?.therapistCountryOfOrigin
          ? therapist?.therapistCountryOfOrigin
          : "United States of America",
        experience: therapist?.experience ? therapist.experience : 1,
        calendly: therapist?.calendly ? therapist.calendly : "",
        facebook: therapist?.facebook ? therapist.facebook : "",
        linkedin: therapist?.linkedin ? therapist.linkedin : "",
        certifications: therapist?.certifications
          ? therapist.certifications
          : "",
        about: therapist?.about ? therapist.about : "",
      }}
      onSubmit={async (formValues, actions) => {
        const props = {
          ...formValues,
          therapistGender: gender,
          therapistOrientation: orientation,
          specialties: selectedSpecialties,
          therapistLGBTQ: LGBTQ,
        };

        if (shownUserId) {
          const updatedTherapist = await axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/api/admin/update-therapist`,
            data: { _id: shownUserId, ...props },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          toast.success("Updated therapist preferences.");
          return setTherapist(updatedTherapist);
        }

        try {
          await axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/api/therapists/${userId}`,
            data: { ...props },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          const newTherapist = await fetchTherapist();
          toast.success(localize(dict.errors.successfullyUpdated));
          setTherapist(newTherapist);
        } catch (err) {
          console.error("error", err);
          toast.error(localize(dict.errors.errorWhileUpdatingUser));
        }
        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <React.Fragment>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 40,
              marginTop: 75,
              marginBottom: 75,
            }}
          >
            <InputGroup>
              <span>{localize(dict.dashboard.calendlyUsername)}</span>
              <StyledInput.Input
                type={"text"}
                name={"calendly"}
                value={values.calendly}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.firstName && touched.firstName && errors.firstName}
            </InputGroup>
            <InputGroup>
              <span>{localize(dict.dashboard.facebookUrl)}</span>
              <StyledInput.Input
                type={"text"}
                name={"facebook"}
                value={values.facebook}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.firstName && touched.firstName && errors.firstName}
            </InputGroup>
            <InputGroup>
              <span>{localize(dict.dashboard.linkedInUrl)}</span>
              <StyledInput.Input
                type={"text"}
                name={"linkedin"}
                value={values.linkedin}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.firstName && touched.firstName && errors.firstName}
            </InputGroup>
            <Label>
              <h4>{localize(dict.app.gender)}</h4>
              <ButtonGroup>
                <Button
                  onClick={() => setGender("GENDER_MALE")}
                  selected={gender === "GENDER_MALE"}
                >
                  {localize(dict.app.male)}
                </Button>
                <Button
                  onClick={() => setGender("GENDER_FEMALE")}
                  selected={gender === "GENDER_FEMALE"}
                >
                  {localize(dict.app.female)}
                </Button>
                <Button
                  onClick={() => setGender("GENDER_NO_PREFERENCE")}
                  selected={gender === "GENDER_NO_PREFERENCE"}
                >
                  {localize(dict.registration.noPreference)}
                </Button>
              </ButtonGroup>
            </Label>
            <Label>
              <h4>{localize(dict.registration.specializesInLGBTQ)}</h4>
              <ButtonGroup>
                <Button
                  onClick={() => setLGBTQ(true)}
                  selected={LGBTQ === true}
                >
                  {localize(dict.app.yes)}
                </Button>
                <Button
                  onClick={() => setLGBTQ(false)}
                  selected={LGBTQ === false}
                >
                  {localize(dict.app.no)}
                </Button>
              </ButtonGroup>
            </Label>
            <Label>
              <h4>{localize(dict.registration.spiritualOrientation)}</h4>
              <ButtonGroup>
                <Button
                  onClick={() => setOrientation(true)}
                  selected={orientation === true}
                >
                  {localize(dict.registration.believer)}
                </Button>
                <Button
                  onClick={() => setOrientation(false)}
                  selected={orientation === false}
                >
                  {localize(dict.registration.nonBeliever)}
                </Button>
              </ButtonGroup>
            </Label>
            <Label>
              <h4>{localize(dict.registration.countryOfOrigin)}</h4>
              <CountrySelect
                name={"therapistCountryOfOrigin"}
                value={values.therapistCountryOfOrigin}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Label>
            <Label>
              <h4>{localize(dict.app.areaOfSpecialty)}</h4>
              <ButtonGroup>
                {Object.keys(specialties).map((specialty) => {
                  return (
                    <Button
                      key={specialties[specialty].name}
                      onClick={() => {
                        const copySpecialties = [...selectedSpecialties];
                        if (
                          copySpecialties.includes(specialties[specialty].name)
                        ) {
                          const newArr = copySpecialties.filter(
                            (e) => e !== specialties[specialty].name
                          );
                          setSelectedSpecialties(newArr);
                        } else {
                          copySpecialties.push(specialties[specialty].name);
                          setSelectedSpecialties(copySpecialties);
                        }
                      }}
                      selected={selectedSpecialties.includes(
                        specialties[specialty].name
                      )}
                    >
                      {specialties[specialty].value}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Label>
            <Label>
              <h4>{localize(dict.app.certifications)}</h4>
              <Field
                placeholder={localize(dict.dashboard.listYourCertifications)}
                name="certifications"
                value={values.certifications}
                component="textarea"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Label>
            <Label>
              <h4>{localize(dict.therapist.aboutMe)}</h4>
              <Field
                placeholder="Tell us about yourself"
                name="about"
                value={values.about}
                component="textarea"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Label>
            <StyledButton.Button
              style={{ maxWidth: 150, marginTop: 50 }}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              <StyledButton.ButtonText>
                {localize(dict.app.save)}
              </StyledButton.ButtonText>
            </StyledButton.Button>
          </form>
        </React.Fragment>
      )}
    </Formik>
  ) : null;
};
