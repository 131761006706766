export default {
  addictionCoping: "Adicción",
  adhd: "TDAH",
  anxiety: "Ansiedad",
  stress: "Estrés",
  bipolarDisorder: "Trastorno bipolar",
  couplesTherapy: "Terapia de pareja",
  depression: "Depresión",
  angerManagement: "Manejo del Enojo",
  familyConflicts: "Conflictos familiares",
  grief: "Duelo",
  relationships: "Relaciones familiares",
  parentingCoaching: "Consejería para padres",
  sleepingDisorders: "Trastornos del Sueño",
  intimacyIssues: "Problemas de intimidad",
  selfEsteem: "Autoestima",
};
