export default {
  myTherapists: "Mis especialistas",
  allTherapists: "Lista completa de especialistas",
  allSpecialties: "Especialidades",
  search: "Buscar",
  filters: "Filtros",
  seeMore: "Ver más",
  credentials: "Datos Personales",
  therapistProfile: "Tu Perfil",
  profileImage: "Imagen de perfil",
  uploadImage: "Subir imagen",
  calendlyUsername: "Nombre de usuario de Calendly",
  facebookUrl: "Perfil de Facebook ",
  linkedInUrl: "Perfil de Linked In",
  instagramUrl: "Perfil de Instagram",
  twitterUrl: "Perfil de Twitter",
  media: "Subir imagen",
  clientProfile: "Perfil de usuario",
  listYourCertifications: "Lista tus certificaciones",
};
