import "./App.css";
import { SignIn } from "./pages/SignIn/SignIn";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { Routes } from "./Routes";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Navbar } from "./components/Navbar";
import { PrivateRoute } from "./components/PrivateRoute";
import { Therapist } from "./pages/Therapist/Therapist";
import { Appointments } from "./pages/Appointments/Appointments";
import { ThemeProvider } from "styled-components";
import { Home } from "./pages";
import theme from "./theme";
import { Registration } from "./pages/Registration/Registration";
import CareSeekers from "./pages/Home/CareSeekers";
import Providers from "./pages/Home/Providers";
import { SignOut } from "./pages/SignOut";
import { Settings } from "./pages/Settings/Settings";
import { AuthProvider } from "./context/auth-context";
import AboutUs from "./pages/Home/AboutUs";
import React from "react";
import Admin from "./pages/Admin/Admin";
import "react-image-crop/dist/ReactCrop.css";
import Contact from "./pages/Home/Contact";
import AuthenticatedNavComponents from "./components/Navbar/AuthenticatedNavComponents";
import Blog from "./pages/Blog";
import Post from "./pages/Blog/Post";

require("dotenv").config();

const App = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router>
            <Switch>
              <Route exact path={Routes.HOME}>
                <Home.Landing />
              </Route>
              <Route path={Routes.SIGN_IN}>
                <SignIn />
              </Route>
              <Route path={Routes.SIGN_OUT}>
                <SignOut />
              </Route>
              <Route path={Routes.REGISTRATION}>
                <Registration />
              </Route>
              <Route path={Routes.CARE_SEEKERS}>
                <CareSeekers />
              </Route>
              <Route path={Routes.PROVIDERS}>
                <Providers />
              </Route>
              <Route path={Routes.ABOUT_US}>
                <AboutUs />
              </Route>
              <Route exact path={Routes.BLOG}>
                <Blog />
              </Route>
              <Route path={Routes.BLOG_POST}>
                <Post />
              </Route>
              <Route path={Routes.CONTACT}>
                <Contact />
              </Route>
              <PrivateRoute>
                <Navbar containerStyle={{ maxWidth: "100%" }}>
                  <AuthenticatedNavComponents />
                </Navbar>
                <Switch>
                  <Route exact path={Routes.DASHBOARD}>
                    <Dashboard />
                  </Route>
                  <Route path={Routes.THERAPISTS}>
                    {(props) => <Therapist {...props} />}
                  </Route>
                  <Route path={Routes.APPOINTMENTS}>
                    <Appointments />
                  </Route>
                  <Route path={Routes.SETTINGS}>
                    <Settings />
                  </Route>
                  <Route path={Routes.ADMIN}>
                    <Admin />
                  </Route>
                </Switch>
              </PrivateRoute>
            </Switch>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
