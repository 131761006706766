const contactUsScope = "contactUs";

export default {
  contactUsHeadline: `${contactUsScope}.contactUsHeadline`,
  contactUsSubCopy: `${contactUsScope}.contactUsSubCopy`,
  firstName: `${contactUsScope}.firstName`,
  lastName: `${contactUsScope}.lastName`,
  email: `${contactUsScope}.email`,
  confirmEmail: `${contactUsScope}.confirmEmail`,
  howCanWeHelpYou: `${contactUsScope}.howCanWeHelpYou`,
};
