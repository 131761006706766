export default {
  upcomingAppointments: "Próximas citas",
  noAppointments: "No hay citas programadas.",
  pastAppointments: "Citas pasadas",
  successfullyPurchasedSubscriptionPlan: "Plan de suscripción comprado.",
  sessionsRemaining: "sesiones restantes",
  pleasePurchaseAPlan: "Por favor elige un plan.",
  upgradePlan: "Actualiza tu plan",
  plan: "Tu Plan de Servicio",
  noPlan: "No Hay Plan Seleccionado.",
  packetOfSessions: "Paquete de {{number}} sesiones",
  joinMeeting: "Comienza tu sesión",
  save: "Guardar",
  requestCancellation: "Cancelar / reprogramar tu cita",
  confirmCancellation: "Confirmar cancelación",
  awaitingCancellation: "Esperando cancelación",
  cancelled: "Cita cancelada",
  disclaimer:
    "Favor de proporcionar un aviso de cancelación al menos 24 horas antes de tu cita para mantener tu crédito.",
};
