import React from "react";
import { Formik, Field } from "formik";
import { Navbar } from "./Navbar";
import {
  StyledButton,
  StyledInput,
  StyledLabel,
  StyledSection,
} from "../../components";
import ErrorMessage from "../../components/ErrorMessage";
import styled from "styled-components";
import Container from "../../components/Container";
import { device } from "../../utilities/responsive";
import { dict, localize } from "../../i18n";
import axios from "axios";
import { toast } from "react-hot-toast";

const StyledContainer = styled(Container)`
  h1 {
    font-family: "Martel Sans", sans-serif;
    margin-top: 0;
  }
`;

const Group = styled(StyledLabel.Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;

  span {
    margin-bottom: 5px;
    font-size: 12px;
    color: #2b2b2b;
    letter-spacing: 0.7px;
    font-family: "Martel Sans", sans-serif;
  }

  ${({ style }) => ({ ...style })};
`;

const InputGroup = ({ style, children }) => (
  <Group style={{ maxWidth: 320, width: "100%", ...style }}>{children}</Group>
);

const StyledContainerContainer = styled(StyledContainer)`
  flex-direction: column;
  padding: 50px;

  @media ${device.lg} {
    padding: 0;
  }
`;

const Contact = () => {
  return (
    <React.Fragment>
      <Navbar />
      <div>
        <StyledSection.Section style={{ paddingTop: 100, paddingBottom: 100 }}>
          <StyledContainerContainer>
            <h1>{localize(dict.contactUs.contactUsHeadline)}</h1>
            <p style={{ marginBottom: 30 }}>
              {localize(dict.contactUs.contactUsSubCopy)}
            </p>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                confirmEmail: "",
                info: "",
              }}
              validate={(formValues) => {
                const errors = {};

                if (!formValues.firstName || formValues.firstName.length < 1) {
                  errors.firstName = localize(dict.errors.required);
                }

                if (!formValues.lastName || formValues.lastName.length < 1) {
                  errors.lastName = localize(dict.errors.required);
                }

                if (!formValues.email) {
                  errors.email = localize(dict.errors.required);
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    formValues.email
                  )
                ) {
                  errors.email = localize(dict.errors.invalidEmailAddress);
                }

                if (!formValues.confirmEmail) {
                  errors.confirmEmail = localize(dict.errors.required);
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    formValues.confirmEmail
                  )
                ) {
                  errors.confirmEmail = localize(
                    dict.errors.invalidEmailAddress
                  );
                } else if (formValues.email !== formValues.confirmEmail) {
                  errors.confirmEmail = localize(
                    dict.errors.emailAddressNoMatch
                  );
                }

                if (!formValues.info || formValues.info.length < 1) {
                  errors.info = localize(
                    dict.errors.pleaseEnterInformationRegardingInquiry
                  );
                }

                return errors;
              }}
              onSubmit={async (formValues, helpers) => {
                const {confirmEmail, ...restOfValues} = formValues;
                try {
                  const res = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_API_URL}/api/contact`,
                    data: restOfValues
                  });

                  toast.success(localize(
                    dict.providers.successfullySentProviderIntakeInformation
                  ));
                  helpers.resetForm();
                } catch(err) {
                  if (err.response) {
                    console.error("error", err.response.data.message);
                    return toast.error(`Error: ${err.response.data.message}`)
                  }
                }
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <React.Fragment>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: 30,
                    }}
                  >
                    <div style={{ display: "flex", columnGap: 30 }}>
                      <InputGroup>
                        <span>{localize(dict.contactUs.firstName)} *</span>
                        <StyledInput.Input
                          type={"text"}
                          name={"firstName"}
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors.firstName && touched.firstName}
                        />
                        <ErrorMessage>
                          {errors.firstName &&
                            touched.firstName &&
                            errors.firstName}
                        </ErrorMessage>
                      </InputGroup>
                      <InputGroup>
                        <span>{localize(dict.contactUs.lastName)} *</span>
                        <StyledInput.Input
                          type={"text"}
                          name={"lastName"}
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors.lastName && touched.lastName}
                        />
                        <ErrorMessage>
                          {errors.lastName &&
                            touched.lastName &&
                            errors.lastName}
                        </ErrorMessage>
                      </InputGroup>
                    </div>
                    <div style={{ display: "flex", columnGap: 30 }}>
                      <InputGroup>
                        <span>{localize(dict.contactUs.email)} *</span>
                        <StyledInput.Input
                          type={"text"}
                          name={"email"}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors.email && touched.email}
                        />
                        <ErrorMessage>
                          {errors.email && touched.email && errors.email}
                        </ErrorMessage>
                      </InputGroup>
                      <InputGroup>
                        <span>{localize(dict.contactUs.confirmEmail)} *</span>
                        <StyledInput.Input
                          type={"text"}
                          name={"confirmEmail"}
                          value={values.confirmEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors.confirmEmail && touched.confirmEmail}
                        />
                        <ErrorMessage>
                          {errors.confirmEmail &&
                            touched.confirmEmail &&
                            errors.confirmEmail}
                        </ErrorMessage>
                      </InputGroup>
                    </div>
                    <InputGroup
                      style={{ maxWidth: 670, width: "100%", minHeight: 300 }}
                    >
                      <span>{localize(dict.contactUs.howCanWeHelpYou)} *</span>
                      <Field
                        name="info"
                        as={CustomTextarea}
                        errors={errors.info && touched.info}
                      />
                      <ErrorMessage>
                        {errors.info && touched.info && errors.info}
                      </ErrorMessage>
                    </InputGroup>
                    <StyledButton.Button md={{ maxWidth: 300, width: "100%" }} onClick={handleSubmit} disabled={isSubmitting}>
                      <StyledButton.ButtonText>
                        {localize(dict.app.submit)}
                      </StyledButton.ButtonText>
                    </StyledButton.Button>
                  </div>
                </React.Fragment>
              )}
            </Formik>
          </StyledContainerContainer>
        </StyledSection.Section>
      </div>
    </React.Fragment>
  );
};

const StyledTextarea = styled.textarea`
  min-height: 200px;
  border-radius: 8px;

  ${({ errors }) => (errors ? { borderColor: "red" } : {})}
`;

const CustomTextarea = (props) => {
  return <StyledTextarea {...props} style={{ minHeight: 200 }} />;
};

export default Contact;
