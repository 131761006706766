export default {
  example: "ejemplo",
  bookFreeSessionNow: "Reserva Tu Sesión Gratuita",
  careSeekers: "Usuario",
  providers: "Especialistas",
  aboutUs: "Quiénes Somos",
  blog: "Blog",
  contact: "Contáctenos",
  dashboard: "Iniciar Sesión",
  emotionalWellBeingForTheLatinoCommunity:
    "Tu Bienestar Emocional Es Nuestra Prioridad",
  benefitsOfOurPlatform:
    "Escucha, descubre, conversa y practica. Cambia tu vida hoy, trabajando mano a mano con un especialista que te ayudará a mejorar tu bienestar mental y convirtiéndote en la mejor versión de ti mismo.",
  howItWorks: "¿Cómo funciona Sevelyn?",
  tellUsAboutYourself:
    "Completa un breve cuestionario el cual nos permitirá conocer y aprender un poco de ti, tus metas y preferencia",
  getMatched: "Selecciona la hora y día disponible",
  startTherapy:
    "Conéctate a tu cita por medio de video llamada de forma segura y privada",
  benefitsOfOurPlatformHeadline: "Beneficios De Sevelyn",
  affordableHeadline: "Asequibilidad",
  affordableBody:
    "Precios asequibles con descuentos por la compra de paquetes de 4 y 10 sesiones",
  qualityCareHeadline: "Atención de Calidad",
  qualityCareBody:
    "Nuestros especialistas de bienestar mental cuentan con certificaciones en Estados Unidos y Latino América y cuentan con un mínimo de tres años de experiencias profesional en el área clínica.",
  cultureFirstHeadline: "Cultura Primero",
  cultureFirstBody:
    "Todos nuestros especialistas entienden completamente tu cultura e idioma ya que ellos son Latinos igual que tú.",
  convenienceHeadline: "Comodidad",
  convenienceBody:
    "¡Ahórrate tiempo y maximiza tu comodidad! Conéctate con nosotros de cualquier lugar con conexión a internet.",
  safetyAndConfidentialityHeadline: "Seguridad y Confidencialidad",
  safetyAndConfidentialityBody:
    "Nuestra plataforma cuenta con comunicación encriptada de punto a punto, tu seguridad y privacidad están garantizadas.",
  lifeThreateningSituation:
    "Si estás experimentando una crisis o emergencia, por favor comunícate a los servicios de emergencia más cercanos a tu localidad.",
  switchToEnglish: "English",
  switchToSpanish: "Cambiar al español",
  privacyPolicy: "Política de privacidad",
  logIn: "Iniciar sesión",
  getStarted: "Crear una cuenta",
  comingSoon: "Próximamente",
  gender: "Género",
  specialties: `Especialidades`,
  certifications: `Certificaciones`,
  yearsOfExperience: `Años de experiencia`,
  passwordReset: `Restablecimiento de contraseña`,
  newPassword: `Nueva contraseña`,
  confirmNewPassword: `Confirmar nueva contraseña`,
  registerNow: `Register Now`,
  registerForTeletherapy: `Regístrate para una sesión para comenzar tu viaje a bienestar mental.`,
  thankYouForStartingYourWellnessJourney: `Gracias por comenzar tu viaje de bienestar con Sevelyn. El proceso de registro tardará menos de dos minutos para completar.`,
  whatRoleAreYouSeeking: `¿Qué rol buscas?`,
  client: `Cliente`,
  admin: `Admin`,
  forgotPassword: "¿Se te olvidó tu contraseña?",
  password: "Contraseña",
  email: "Email",
  hello: "¡Bienvenidos a Sevelyn!",
  therapists: "Especialistas",
  appointments: "Citas",
  male: "Hombre",
  female: "Mujer",
  bookNow: "Reserva Tu Cita",
  aboutMe: "Sobre mí",
  years: "años",
  year: "año",
  back: "Regresar",
  submit: "Enviar",
  save: "Guardar",
  select: "Eligir",
  areaOfSpecialty: "Área de Especialidad",
  yes: "Sí",
  no: "No",
  preferNotToSay: "Prefiero no decir",
  dontHaveAnAccount: "¿No tienes una cuenta?",
};
