export default {
  providersHeadline: "Especialistas",
  providersSubCopy: `¡Búscamos profesionales de bienestar emocional como tú en diversos campos: Psicólogos, terapeutas, consejeros, coaches, trabajadores sociales, y mucho más. Si conoces la cultura latina, hablas español con fluidez y tienes un mínimo de 3 años de experiencia profesional verificable, nos encantaría hablar contigo. ${""}
    Al unirte al equipo, continuarás haciendo el trabajo que amas con más flexibilidad y podrás mejorar tus ingresos.  Nuestra plataforma te proporcionará herramientas innovadoras para conectar y tratar a nuestros usuarios actuales en línea, además de brindarte acceso a un mercado completamente nuevo.`,
  whyJoinSevelyn: "¡Sé parte de nuestro equipo!",
  freeToJoin: "Únete sin ningun costo",
  freeToJoinSubCopy: "Unirse a Sevelyn es completamente ¡GRATIS!",
  focusOnWhatYouLove: "Concentrate en lo que te apasiona",
  focusOnWhatYouLoveSubCopy:
    "¡Tú te ocupas de nuestros clientes, nosotros nos ocupamos de tus operaciones!",
  workFlexibility: "Flexibilidad laboral",
  workFlexibilitySubCopy:
    "Trabaja cuando quieras y desde donde quieras. ¡Lo único lo que necesitas es una conexión a Internet estable y listo!",
  supplementalIncome: "Ingresos suplementarios",
  supplementalIncomeSubCopy:
    "Sevelyn es una gran fuente para complementar tu ingreso actual. Trabaja tanto como quieras, la elección es tuya.",
  increaseYourKnowledge: "Entrenamiento y Workshops (Talleres)",
  increaseYourKnowledgeSubCopy:
    "Sevelyn se encarga de mantenerte al tanto de las ultimas capacitaciones, metodologías profesionales y seminarios de al industria.",
  applicationForm: "Aplicación",
  firstName: "Nombre",
  lastName: "Apellido",
  email: "Email",
  confirmEmail: "Confirma tu email",
  phoneNumber: "Phone Number",
  whatsAppNumber: "Número de Whatsapp",
  nationality: "País de Residencia",
  gender: "Género",
  yearsOfExperience: "Years of Experience",
  areaOfSpecialty: "Area of Specialty",
  certifications: "Certifications",
  consentDialog:
    "Soy mayor de 18 años y acepto los términos de uso y la política de privacidad.",
  instagramUrl: "Prefil",
  successfullySentProviderIntakeInformation:
    "Felicidades, tu solicitud ha sido enviada.",
  providerLGBTQ: "¿Experiencia en temas LGBTQ?",
};
