import styled from "styled-components";
import { device } from "../../utilities/responsive";

const DesktopActionsComponent = styled.div`
  display: none;

  @media ${device.lg} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
`;

export default DesktopActionsComponent;
