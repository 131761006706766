import { StyledSection } from "../../../components";
import Container from "../../../components/Container";
import * as React from "react";
import styled from "styled-components";
import { NavLink as BSNavLink, NavLink } from "react-bootstrap";
import { useHistory } from "react-router";
import { Routes } from "../../../Routes";
import { device } from "../../../utilities/responsive";
import { dict, i18n, localize } from "../../../i18n";

const StyledSectionSection = styled(StyledSection.Section)`
  background-color: #ff6f6f;
  min-height: 100px;
  height: 100%;
  padding: 150px 0 25px 0;

  @media ${device.lg} {
    padding: 400px 0 25px 0;
  }
`;

const StyledContainerContainer = styled(Container)`
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding: 50px;

  @media ${device.lg} {
    padding: 0;
  }
`;

export const FooterSection = ({ setModal }) => {
  const lng = i18n.language;
  const lngIsSpanish = /es/.test(lng);
  const history = useHistory();

  return (
    <StyledSectionSection>
      <StyledContainerContainer>
        <h4
          style={{
            fontFamily: "Martel Sans, sans-serif",
            fontSize: 20,
            fontWeight: "normal",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            color: "white",
          }}
        >
          Copyright &copy; Sevelyn. All rights reserved.
        </h4>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 100,
          }}
        >
          <NavLink onClick={() => setModal(true)}>
            <span style={{ color: "#0f55cb" }}>
              {localize(dict.app.privacyPolicy)}
            </span>
          </NavLink>
          <NavLink onClick={() => history.push(Routes.CONTACT)}>
            <span style={{ color: "#0f55cb" }}>
              {localize(dict.app.contact)}
            </span>
          </NavLink>
        </div>
        <span style={{ color: "white", textAlign: "center", fontSize: 14 }}>
          {localize(dict.app.lifeThreateningSituation)}
        </span>
      </StyledContainerContainer>
    </StyledSectionSection>
  );
};
