import { css } from "styled-components";
import { device } from "../utilities/responsive";

export const mediaQueries = (
  props = { base: {}, md: {}, lg: {}, xl: {} }
) => css`
  @media ${device.base} {
    ${props.base}
  }

  @media ${device.md} {
    ${props.md}
  }

  @media ${device.lg} {
    ${props.lg}
  }

  @media ${device.xl} {
    ${props.xl}
  }
`;
