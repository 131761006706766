import React from "react";
import styled from "styled-components";
import { device } from "../../utilities/responsive";

const MobileActionsComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  flex: 1;
  font-weight: normal;
  font-size: 14px;

  a {
    padding: 15px 20px;
    color: #fe6551;

    &:hover {
      cursor: pointer;
      background-color: #ececec;
    }
  }

  @media ${device.lg} {
    display: none;
  }
`;

export default MobileActionsComponent;
