import styled from "styled-components";
import { StyledLabel } from "../../../components";
import React from "react";

const Group = styled(StyledLabel.Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;

  span {
    margin-bottom: 5px;
    font-size: 12px;
    color: #2b2b2b;
    letter-spacing: 0.7px;
    font-family: "Martel Sans", sans-serif;
  }

  ${({ style }) => ({ ...style })};
`;

const InputGroup = ({ style, children }) => (
  <Group style={{ maxWidth: 320, width: "100%", ...style }}>{children}</Group>
);

export default InputGroup;
