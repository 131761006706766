import React, { useState } from "react";
import "./Sidebar.css";
import { StyledInput, StyledSidebar } from "../../components";
import { Formik } from "formik";
import { useAsyncDebounce } from "react-table";
import styled from "styled-components";
import { device } from "../../utilities/responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { dict, localize } from "../../i18n";

export const specialties = {
  ADDICTION_COPING: {
    name: "ADDICTION_COPING",
    value: localize(dict.specialties.addictionCoping),
  },
  ADHD_FOCUS: {
    name: "ADHD_FOCUS",
    value: localize(dict.specialties.adhd),
  },
  ANGER_MANAGEMENT: {
    name: "ANGER_MANAGEMENT",
    value: localize(dict.specialties.angerManagement),
  },
  ANXIETY: {
    name: "ANXIETY",
    value: localize(dict.specialties.anxiety),
  },
  STRESS: {
    name: "STRESS",
    value: localize(dict.specialties.stress),
  },
  BIPOLAR_DISORDER: {
    name: "BIPOLAR_DISORDER",
    value: localize(dict.specialties.bipolarDisorder),
  },
  COUPLES_THERAPY: {
    name: "COUPLES_THERAPY",
    value: localize(dict.specialties.couplesTherapy),
  },
  DEPRESSION: {
    name: "DEPRESSION",
    value: localize(dict.specialties.depression),
  },
  FAMILY_CONFLICTS: {
    name: "FAMILY_CONFLICTS",
    value: localize(dict.specialties.familyConflicts),
  },
  GRIEF_LOSS_COPING: {
    name: "GRIEF_LOSS_COPING",
    value: localize(dict.specialties.grief),
  },
  INTIMACY_ISSUES: {
    name: "INTIMACY_ISSUES",
    value: localize(dict.specialties.intimacyIssues),
  },
  MOTIVATION_SELF_ESTEEM: {
    name: "MOTIVATION_SELF_ESTEEM",
    value: localize(dict.specialties.selfEsteem),
  },
  PARENTING_ISSUES: {
    name: "PARENTING_ISSUES",
    value: localize(dict.specialties.parentingCoaching),
  },
  RELATIONSHIPS: {
    name: "RELATIONSHIPS",
    value: localize(dict.specialties.relationships),
  },
  SLEEPING_DISORDERS: {
    name: "SLEEPING_DISORDERS",
    value: localize(dict.specialties.sleepingDisorders),
  },
};

const StyledSidebarSidebar = styled(StyledSidebar.Sidebar)`
  .sidebar-search {
    margin-bottom: 30px !important;

    @media ${device.xl} {
      margin-bottom: 50px !important;
    }
  }
`;

const Drawer = styled.div`
  .collapse-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #fe6551;
    border-radius: 8px;
    column-gap: 10px;
    margin-bottom: ${({ drawer }) => (drawer ? "30px" : "0")};

    @media ${device.xl} {
      display: none;
      margin-bottom: 0;
    }
  }

  .collapse-content {
    display: ${({ drawer }) => (drawer ? "flex" : "none")};
    flex-direction: column;

    @media ${device.xl} {
      display: flex;
    }
  }
`;

export const Sidebar = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  MyTherapistsComponent,
  GendersComponent,
  SpecialtiesComponent,
  favorites,
}) => {
  const [drawer, setDrawer] = useState(false);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <StyledSidebarSidebar
      style={{
        alignItems: "stretch",
        overflow: window.screen.availWidth >= 1200 ? "scroll" : "visible",
      }}
    >
      <Formik
        initialValues={{
          therapistCountryOfOrigin: "United States of America",
          search: "",
          specialties: [],
          genders: [],
        }}
      >
        {({ values, handleChange, handleBlur }) => (
          <React.Fragment>
            <StyledInput.Input
              className={"sidebar-search"}
              placeholder={localize(dict.dashboard.search)}
              name={"search"}
              value={values.search}
              onChange={(e) => {
                handleChange(e);
                onChange(e.target.value);
              }}
              onBlur={handleBlur}
              style={{ marginBottom: 50 }}
            />
            <Drawer drawer={drawer}>
              <div
                className={"collapse-bar"}
                onClick={() => setDrawer(!drawer)}
              >
                <span style={{ color: "#fff" }}>
                  {localize(dict.dashboard.filters)}
                </span>
                <FontAwesomeIcon
                  icon={drawer ? faChevronUp : faChevronDown}
                  color={"#FFF"}
                  size={"sm"}
                />
              </div>
              <div className={"collapse-content"}>
                {MyTherapistsComponent.render("Filter", { values, favorites })}
                {GendersComponent.render("Filter", { values })}
                {SpecialtiesComponent.render("Filter", { values })}
              </div>
            </Drawer>
          </React.Fragment>
        )}
      </Formik>
    </StyledSidebarSidebar>
  );
};
