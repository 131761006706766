import { StyledButton, StyledLabel } from "../../../components";
import styled from "styled-components";
import { ProgressBar } from "../ProgressBar";
import React, { useState } from "react";
import { specialties } from "../../Dashboard/Sidebar";
import { Formik } from "formik";
import CountrySelect from "../../../components/CountrySelect";
import { localize, dict } from "../../../i18n/index";

const ButtonGroup = styled.div`
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  max-width: 650px;

  > div {
    max-width: 160px;
    width: 100%;
    border: 1px solid #9d9d9d;
    background-color: white;

    span {
      color: #2b2b2b;
      font-family: "Martel Sans", sans-serif;
      font-weight: normal;
    }
  }
`;

const Label = styled(StyledLabel.Label)`
  h4 {
    margin-top: 0;
  }
`;

const Button = ({ children, onClick, selected }) => (
  <StyledButton.Button
    style={{ maxWidth: 150 }}
    onClick={onClick}
    selected={selected}
    clickable={true}
  >
    <StyledButton.ButtonText onClick={onClick}>
      {children}
    </StyledButton.ButtonText>
  </StyledButton.Button>
);

const H4 = styled.h4`
  color: #626262;
  font-family: "Cantata One", serif;
  font-size: 25px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 5px;
`;

export const SecondProviderRegistration = (props) => {
  const { setSecondData, goPrevious, goNext } = props;
  const [gender, setGender] = useState(null);
  const [orientation, setOrientation] = useState(null);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [LGBTQ, setLGBTQ] = useState(null);

  return (
    <React.Fragment>
      <ProgressBar percent={66} phase={2} phases={3} />
      <Formik
        initialValues={{ therapistCountryOfOrigin: "United States of America" }}
        onSubmit={async (formProps, actions) => {
          await setSecondData({
            ...formProps,
            gender,
            orientation,
            selectedSpecialties,
            LGBTQ,
          });
          await goNext();
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <React.Fragment>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 50,
                marginTop: 75,
                marginBottom: 75,
              }}
            >
              <Label>
                <h4>{localize(dict.registration.gender)}</h4>
                <ButtonGroup>
                  <Button
                    onClick={() => setGender("GENDER_MALE")}
                    selected={gender === "GENDER_MALE"}
                  >
                    {localize(dict.app.male)}
                  </Button>
                  <Button
                    onClick={() => setGender("GENDER_FEMALE")}
                    selected={gender === "GENDER_FEMALE"}
                  >
                    {localize(dict.app.female)}
                  </Button>
                  <Button
                    onClick={() => setGender("GENDER_NO_PREFERENCE")}
                    selected={gender === "GENDER_NO_PREFERENCE"}
                  >
                    {localize(dict.app.preferNotToSay)}
                  </Button>
                </ButtonGroup>
              </Label>
              <Label>
                <h4>{localize(dict.registration.spiritualOrientation)}</h4>
                <ButtonGroup>
                  <Button
                    onClick={() => setOrientation(true)}
                    selected={orientation === true}
                  >
                    {localize(dict.registration.believer)}
                  </Button>
                  <Button
                    onClick={() => setOrientation(false)}
                    selected={orientation === false}
                  >
                    {localize(dict.registration.nonBeliever)}
                  </Button>
                </ButtonGroup>
              </Label>
              <Label>
                <h4>{localize(dict.registration.specializesInLGBTQ)}</h4>
                <ButtonGroup>
                  <Button
                    onClick={() => setLGBTQ(true)}
                    selected={LGBTQ === true}
                  >
                    {localize(dict.app.yes)}
                  </Button>
                  <Button
                    onClick={() => setLGBTQ(false)}
                    selected={LGBTQ === false}
                  >
                    {localize(dict.app.no)}
                  </Button>
                </ButtonGroup>
              </Label>
              <Label>
                <h4>{localize(dict.registration.countryOfOrigin)}</h4>
                <CountrySelect
                  name={"therapistCountryOfOrigin"}
                  value={values.therapistCountryOfOrigin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Label>
              <Label>
                <h4>{localize(dict.app.areaOfSpecialty)}</h4>
                <ButtonGroup>
                  {Object.keys(specialties).map((specialty) => {
                    return (
                      <Button
                        key={specialties[specialty].name}
                        onClick={() => {
                          const copySpecialties = [...selectedSpecialties];
                          if (
                            copySpecialties.includes(
                              specialties[specialty].name
                            )
                          ) {
                            const newArr = copySpecialties.filter(
                              (e) => e !== specialties[specialty].name
                            );
                            setSelectedSpecialties(newArr);
                          } else {
                            copySpecialties.push(specialties[specialty].name);
                            setSelectedSpecialties(copySpecialties);
                          }
                        }}
                        selected={selectedSpecialties.includes(
                          specialties[specialty].name
                        )}
                      >
                        {specialties[specialty].value}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Label>
              <Label>
                <h4>
                  {localize(dict.registration.whatCertificationsDoYouHave)}
                </h4>
                <textarea
                  style={{
                    maxWidth: 600,
                    width: "100%",
                    padding: "12px 13px",
                    boxSizing: "border-box",
                    borderRadius: 8,
                  }}
                />
              </Label>
            </form>
            <div
              style={{
                display: "flex",
                maxWidth: 380,
                justifyContent: "space-between",
              }}
            >
              <StyledButton.Button
                style={{ maxWidth: 150, width: "100%" }}
                onClick={goPrevious}
              >
                <StyledButton.ButtonText>
                  {localize(dict.registration.back)}
                </StyledButton.ButtonText>
              </StyledButton.Button>
              <StyledButton.Button
                style={{ maxWidth: 150, width: "100%" }}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                <StyledButton.ButtonText>
                  {localize(dict.registration.next)}
                </StyledButton.ButtonText>
              </StyledButton.Button>
            </div>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
};
