const appointmentsScope = "appointments";

export default {
  upcomingAppointments: `${appointmentsScope}.upcomingAppointments`,
  noAppointments: `${appointmentsScope}.noAppointments`,
  pastAppointments: `${appointmentsScope}.pastAppointments`,
  successfullyPurchasedSubscriptionPlan: `${appointmentsScope}.successfullyPurchasedSubscriptionPlan`,
  sessionsRemaining: `${appointmentsScope}.sessionsRemaining`,
  pleasePurchaseAPlan: `${appointmentsScope}.pleasePurchaseAPlan`,
  upgradePlan: `${appointmentsScope}.upgradePlan`,
  plan: `${appointmentsScope}.plan`,
  noPlan: `${appointmentsScope}.noPlan`,
  packetOfSessions: `${appointmentsScope}.packetOfSessions`,
  joinMeeting: `${appointmentsScope}.joinMeeting`,
  save: `${appointmentsScope}.save`,
  requestCancellation: `${appointmentsScope}.requestCancellation`,
  confirmCancellation: `${appointmentsScope}.confirmCancellation`,
  awaitingCancellation: `${appointmentsScope}.awaitingCancellation`,
  cancelled: `${appointmentsScope}.cancelled`,
  disclaimer: `${appointmentsScope}.disclaimer`,
};
