import React from "react";
import { StyledButton } from "../../components";
import styled from "styled-components";
import { CalendlyEventListener, InlineWidget } from "react-calendly";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { specialties } from "../Dashboard/Sidebar";
import { StyledContainer } from "../../components";
import { toast } from "react-hot-toast";
import PaywallModal from "./PaywallModal";
import { useHistory } from "react-router";
import { Routes } from "../../Routes";
import FavoriteIcon from "../../components/FavoriteIcon";
import { device } from "../../utilities/responsive";
import { dict, localize } from "../../i18n";
import fetchFavorites from "../../utilities/fetchFavorites";
import videoComingSoon from "../../pics/video-coming-soon.png";
import MalePlaceholder from "../../pics/male-placeholder.jpeg";
import FemalePlaceholder from "../../pics/female-placeholder.jpeg";

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 rgba(173, 173, 173, 0.5);
  background-color: #fff;
  align-self: flex-start;
  padding: 50px;
  margin-bottom: 50px;
  width: 100%;

  h2 {
  }

  h4 {
    font-size: 1.3rem;
    margin-bottom: 5px;
  }

  ${({ style }) => ({ ...style })};
`;

const StyledCard = styled(Card)`
  .Card-header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;

    .card-image-area {
      flex-direction: column;

      img {
        margin-right: 0;
        height: 150px;
        width: 150px;
        margin-bottom: 30px;
      }
    }

    @media ${device.xl} {
      flex-direction: row;

      .card-image-area {
        flex-direction: row;

        img {
          margin-right: 30px;
        }
      }

      .card-action-area {
        justify-content: flex-end;
      }
    }
  }
`;

const StyledContainerContainer = styled(StyledContainer.Container)`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const InnerBioContainer = styled.div`
  display: flex;
  column-gap: 80px;
  width: 100%;
  flex-direction: column;

  div#video-coming-soon {
    margin-bottom: 30px;
  }

  @media ${device.xl} {
    flex-direction: row;

    div#video-coming-soon {
      margin-bottom: 0;
    }
  }
`;

export const Therapist = (props) => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [profile, setProfile] = useState(null);
  const [therapist, setTherapist] = useState(null);
  const therapistUserId = props.location.pathname.slice(
    props.location.pathname.indexOf("therapists/") + "therapists/".length
  );
  const token = localStorage.getItem("token");
  const user = jwt_decode(localStorage.getItem("token"));
  const [favorites, setFavorites] = useState({});
  useEffect(async () => {
    const userRes = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/users/${therapistUserId}`,
      {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "cache-control": "no-cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setProfile(userRes.data);
    setTherapist(userRes.data.therapist);
    await fetchFavorites(setFavorites);
  }, []);

  const handleEventScheduled = async (e) => {
    if (e.type === "message" && e.data.event === "calendly.event_scheduled") {
      const {
        event: { uri: eventId },
        invitee: { uri: inviteeId },
      } = e.data.payload;

      try {
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/appointments/therapist/${therapist.user}/user/${user.id}`,
          data: {
            therapist: therapist.user,
            client: user.id,
            clientEmail: user.email,
            eventId: eventId,
            inviteeId: inviteeId,
            completed: false,
            cancelled: false,
          },
          headers: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            "cache-control": "no-cache",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        history.push(Routes.APPOINTMENTS, { booked: true });
        return toast.success(
          localize(dict.errors.successfullyScheduledAppointment)
        );
      } catch (err) {
        return toast.error(localize(dict.errors.failedToScheduleAppointment));
      }
    }
  };

  const createSpecialtiesList = () => {
    const converted = therapist.specialties.map((specialty) => {
      return specialties[specialty]?.value;
    });

    return <p>{converted.join(", ")}</p>;
  };

  const checkUserCreditBalance = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/api/subscriptions/client/${user.id}`,
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "cache-control": "no-cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (
        !response ||
        !response.data ||
        response.data.currentCredit < 1 ||
        !response.data.active
      ) {
        toast.error(localize(dict.errors.youDoNotHaveAnyCreditsAvailable));
        return false;
      }

      return true;
    } catch (err) {
      console.error("error", err);
      toast.error(localize(dict.errors.youDoNotHaveAnyCreditsAvailable));
    }
  };

  return therapist && profile ? (
    <div
      id={"therapist-page"}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "rgb(243, 243, 243)",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <StyledContainerContainer>
        <StyledCard>
          <div
            className={"Card-header"}
            style={{
              minHeight: 125,
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 50,
              flex: 1,
            }}
          >
            <div
              className="card-image-area"
              style={{ display: "flex", alignItems: "center", flex: 1 }}
            >
              <img
                src={
                  therapist.profileImage
                    ? `${process.env.REACT_APP_API_URL}/upload/images/${therapist.profileImage}`
                    : therapist.therapistGender === "GENDER_MALE"
                    ? MalePlaceholder
                    : FemalePlaceholder
                }
                style={{
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
              <h2 style={{ fontFamily: "Cantata One", fontWeight: "normal" }}>
                {`${profile.firstName} ${profile.lastName}`}
              </h2>
            </div>
            <div
              className="card-action-area"
              style={{ display: "flex", alignItems: "center" }}
            >
              {/*<StyledButton.Button style={{ margin: 0 }}>*/}
              {/*  <StyledButton.ButtonText>*/}
              {/*    {localize(dict.therapist.referToFriend)}*/}
              {/*  </StyledButton.ButtonText>*/}
              {/*</StyledButton.Button>*/}
              <FavoriteIcon
                therapist={profile}
                favorited={favorites[therapist.user]}
                setFavorites={setFavorites}
              />
            </div>
          </div>
          <div className={"card-bio"} style={{ display: "flex", flex: 1 }}>
            <InnerBioContainer>
              <div id={"video-coming-soon"} style={{ flex: 3, maxWidth: 740 }}>
                <img
                  src={videoComingSoon}
                  style={{
                    maxHeight: 513,
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 15,
                }}
              >
                {therapist.specialties && therapist.specialties.length > 0 && (
                  <div className={"bio-div"}>
                    <h4>{localize(dict.app.specialties)}</h4>
                    {createSpecialtiesList()}
                  </div>
                )}
                {therapist.certifications && therapist.certifications.length && (
                  <div className={"bio-div"}>
                    <h4>{localize(dict.app.certifications)}</h4>
                    <p>{therapist.certifications}</p>
                  </div>
                )}
                {therapist.experience && (
                  <div className={"bio-div"}>
                    <h4>{localize(dict.app.yearsOfExperience)}</h4>
                    <p>
                      {therapist.experience > 1
                        ? `${therapist.experience} ${localize(dict.app.years)}`
                        : `${therapist.experience} ${localize(dict.app.year)}`}
                    </p>
                  </div>
                )}
                {therapist.about && (
                  <div className={"bio-div"}>
                    <h4>{localize(dict.therapist.aboutMe)}</h4>
                    <p>{therapist.about}</p>
                  </div>
                )}
              </div>
            </InnerBioContainer>
          </div>
        </StyledCard>
        {therapist.calendly && (
          <React.Fragment>
            <div id={"calendly"} />
            <CalendlyEventListener
              onDateAndTimeSelected={async () => {
                const userHasCredits = await checkUserCreditBalance();

                if (!userHasCredits) {
                  document.querySelector("#therapist-page").style.display =
                    "none";
                  setModal(true);
                }
              }}
              onEventScheduled={handleEventScheduled}
            >
              <InlineWidget
                url={`https://calendly.com/${therapist.calendly}`}
                prefill={{
                  name: `${user.firstName} ${user.lastName}`,
                  email: user.email,
                }}
                styles={{ height: 780 }}
              />
            </CalendlyEventListener>
          </React.Fragment>
        )}
      </StyledContainerContainer>
      <PaywallModal modal={modal} setModal={setModal} />
    </div>
  ) : null;
};
