import { StyledButton, StyledSection } from "../../../components";
import * as React from "react";
import Container from "../../../components/Container";
import { Routes } from "../../../Routes";
import { NavLink } from "react-router-dom";
import {
  faCouch,
  faHeart,
  faLock,
  faUsers,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Ul from "../Ul";
import styled from "styled-components";
import { device } from "../../../utilities/responsive";
import { dict, localize } from "../../../i18n";
import BenefitsImage from "../../../pics/benefits-of-our-platform.png";

const StyledSectionSection = styled(StyledSection.Section)`
  background-color: #ff7875;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media ${device.lg} {
    padding: 0;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  div#benefits-left {
    padding: 75px;
  }

  div#benefits-right {
    img {
      opacity: 0.75;
      filter: blur(0.5px);
      position: absolute;
      max-width: 1000px;
      bottom: 0;
      left: 60px;
      z-index: -1;
    }
  }

  @media ${device.lg} {
    flex-direction: row;

    div#benefits-right {
      img {
        opacity: 0.75;
        filter: blur(0.5px);
        position: absolute;
        max-width: 1200px;
        bottom: 0;
        left: -336px;
      }
    }
  }
`;

export const BenefitsSection = () => (
  <StyledSectionSection>
    <Container style={{ flexDirection: "column", rowGap: 50, zIndex: 1 }}>
      <StyledDiv>
        <div
          id={"benefits-left"}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            color: "#FFFFFF",
          }}
        >
          <h2
            style={{
              fontFamily: "Cantata One, serif",
              fontSize: 30,
              margin: 0,
              fontWeight: "normal",
              marginBottom: 50,
            }}
          >
            {localize(dict.app.benefitsOfOurPlatformHeadline)}
          </h2>
          <Ul>
            <li>
              <span>
                <FontAwesomeIcon
                  icon={faWallet}
                  color={"rgb(255, 255, 255)"}
                  size={"lg"}
                  style={{ marginRight: 10 }}
                />
                {localize(dict.app.affordableHeadline)}
              </span>{" "}
              <p>{localize(dict.app.affordableBody)}</p>
            </li>
            <li>
              <span>
                <FontAwesomeIcon
                  icon={faHeart}
                  color={"rgb(255, 255, 255)"}
                  size={"lg"}
                  style={{ marginRight: 10 }}
                />
                {localize(dict.app.qualityCareHeadline)}
              </span>{" "}
              <p>{localize(dict.careSeekers.qualityCareBody)}</p>
            </li>
            <li>
              <span>
                <FontAwesomeIcon
                  icon={faUsers}
                  color={"rgb(255, 255, 255)"}
                  size={"lg"}
                  style={{ marginRight: 10 }}
                />
                {localize(dict.app.cultureFirstHeadline)}
              </span>{" "}
              <p>{localize(dict.careSeekers.cultureFirstBody)}</p>
            </li>
            <li>
              <span>
                <FontAwesomeIcon
                  icon={faCouch}
                  color={"rgb(255, 255, 255)"}
                  size={"lg"}
                  style={{ marginRight: 10 }}
                />
                {localize(dict.app.convenienceHeadline)}
              </span>{" "}
              <p>{localize(dict.careSeekers.convenienceBody)}</p>
            </li>
            <li>
              <span>
                <FontAwesomeIcon
                  icon={faLock}
                  color={"rgb(255, 255, 255)"}
                  size={"lg"}
                  style={{ marginRight: 10 }}
                />
                {localize(dict.app.safetyAndConfidentialityHeadline)}
              </span>{" "}
              <p>{localize(dict.app.safetyAndConfidentialityBody)}</p>
            </li>
          </Ul>
          <NavLink to={Routes.SIGN_IN}>
            <StyledButton.Button md={{ width: "100%", maxWidth: 300 }}>
              <StyledButton.ButtonText>
                {localize(dict.app.bookFreeSessionNow)}
              </StyledButton.ButtonText>
            </StyledButton.Button>
          </NavLink>
        </div>
        <div id={"benefits-right"} style={{ flex: 1, position: "relative" }}>
          <img src={BenefitsImage} alt="" />
        </div>
      </StyledDiv>
    </Container>
  </StyledSectionSection>
);
