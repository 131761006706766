import Modal from "react-bootstrap/Modal";
import Button from "../../components/Button";
import React from "react";
import { useHistory } from "react-router";
import { Routes } from "../../Routes";

const PaywallModal = ({ modal, setModal, callback }) => {
  const history = useHistory();

  return (
    <Modal show={modal} onHide={() => setModal(false)}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h4 style={{ textAlign: "center", marginBottom: 50 }}>
          Purchase Credits?
        </h4>
        <div
          style={{ display: "flex", justifyContent: "center", columnGap: 30 }}
        >
          <Button
            style={{ maxWidth: 150 }}
            onClick={() =>
              history.push(Routes.APPOINTMENTS, { displayModal: true })
            }
          >
            Yes
          </Button>
          <Button
            style={{ maxWidth: 150 }}
            onClick={() => window.location.reload()}
          >
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaywallModal;
