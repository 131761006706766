import React, { useState } from "react";
import { StyledButton, StyledLabel } from "../../../components";
import styled from "styled-components";
import { ProgressBar } from "../ProgressBar";
import { localize, dict } from "../../../i18n/index";

const Label = styled(StyledLabel.Label)`
  h4 {
    margin-top: 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  max-width: 380px;

  > div {
    max-width: 150px;
    width: 100%;
    border: 1px solid #9d9d9d;
    background-color: white;

    span {
      color: #2b2b2b;
      font-family: "Martel Sans", sans-serif;
      font-weight: normal;
    }
  }
`;

const Button = ({ children, onClick, selected }) => (
  <StyledButton.Button
    style={{ maxWidth: 165 }}
    onClick={onClick}
    selected={selected}
  >
    <StyledButton.ButtonText onClick={onClick}>
      {children}
    </StyledButton.ButtonText>
  </StyledButton.Button>
);

export const FirstRegistration = (props) => {
  const [therapyType, setTherapyType] = useState(null);
  const [age, setAge] = useState(null);
  const [referral, setReferral] = useState(null);

  const getNextStatus = () => {
    if (therapyType && age && referral) return true;
    return false;
  };

  const { setFirstData, goNext } = props;
  return (
    <React.Fragment>
      <ProgressBar percent={33} phase={1} phases={3} />
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 50,
          marginTop: 75,
          marginBottom: 75,
        }}
      >
        <Label>
          <h4>{localize(dict.registration.whatTypeOfTherapy)}</h4>
          <ButtonGroup>
            <Button
              onClick={() => setTherapyType("THERAPY_FOR_SELF")}
              selected={therapyType === "THERAPY_FOR_SELF"}
            >
              {localize(dict.registration.therapyForMyself)}
            </Button>
            <Button
              onClick={() => setTherapyType("THERAPY_FOR_COUPLES")}
              selected={therapyType === "THERAPY_FOR_COUPLES"}
            >
              {localize(dict.registration.couplesTherapy)}
            </Button>
          </ButtonGroup>
        </Label>
        <Label>
          <h4>{localize(dict.registration.howOldAreYou)}</h4>
          <ButtonGroup>
            <Button
              onClick={() => setAge("AGE_12_OR_UNDER")}
              selected={age === "AGE_12_OR_UNDER"}
            >
              {localize(dict.registration.twelveAndUnder)}
            </Button>
            <Button
              onClick={() => setAge("AGE_12_17")}
              selected={age === "AGE_12_17"}
            >
              12 - 17
            </Button>
            <Button
              onClick={() => setAge("AGE_18_34")}
              selected={age === "AGE_18_34"}
            >
              18 - 34
            </Button>
            <Button
              onClick={() => setAge("AGE_35_55")}
              selected={age === "AGE_35_55"}
            >
              35 - 55
            </Button>
            <Button
              onClick={() => setAge("AGE_55_65")}
              selected={age === "AGE_55_65"}
            >
              55 - 65
            </Button>
            <Button
              onClick={() => setAge("AGE_65_PLUS")}
              selected={age === "AGE_65_PLUS"}
            >
              65+
            </Button>
          </ButtonGroup>
        </Label>
        <Label>
          <h4>{localize(dict.registration.howDidYouHearAboutSevelyn)}</h4>
          <ButtonGroup>
            <Button
              onClick={() => setReferral("REFERRAL_FACEBOOK")}
              selected={referral === "REFERRAL_FACEBOOK"}
            >
              Facebook
            </Button>
            <Button
              onClick={() => setReferral("REFERRAL_INSTAGRAM")}
              selected={referral === "REFERRAL_INSTAGRAM"}
            >
              Instagram
            </Button>
            <Button
              onClick={() => setReferral("REFERRAL_GOOGLE")}
              selected={referral === "REFERRAL_GOOGLE"}
            >
              {localize(dict.registration.googleSearch)}
            </Button>
            <Button
              onClick={() => setReferral("REFERRAL_WORD_OF_MOUTH")}
              selected={referral === "REFERRAL_WORD_OF_MOUTH"}
            >
              {localize(dict.registration.wordOfMouth)}
            </Button>
            <Button
              onClick={() => setReferral("REFERRAL_OTHER")}
              selected={referral === "REFERRAL_OTHER"}
            >
              {localize(dict.registration.other)}
            </Button>
          </ButtonGroup>
        </Label>
      </form>
      <StyledButton.Button
        style={{ maxWidth: 150, width: "100%" }}
        onClick={() => {
          setFirstData({ therapyType, age, referral });
          goNext();
        }}
        disabled={!getNextStatus()}
      >
        <StyledButton.ButtonText>
          {localize(dict.registration.next)}
        </StyledButton.ButtonText>
      </StyledButton.Button>
    </React.Fragment>
  );
};
