import { StyledButton, StyledInput, StyledLabel } from "../../../components";
import styled from "styled-components";
import { ProgressBar } from "../ProgressBar";
import React from "react";
import { Formik } from "formik";
import ErrorMessage from "../../../components/ErrorMessage";
import { localize, dict } from "../../../i18n/index";
import { device } from "../../../utilities/responsive";
import InputGroupContainer from "../../../components/InputGroupContainer";

const Group = styled(StyledLabel.Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;

  span {
    margin-bottom: 5px;
    font-size: 12px;
    color: #2b2b2b;
    letter-spacing: 0.7px;
    font-family: "Martel Sans", sans-serif;
  }

  ${({ style }) => ({ ...style })};
`;

const InputGroup = ({ style, children }) => (
  <Group style={{ ...style, maxWidth: 320, width: "100%" }}>{children}</Group>
);

export const SecondRegistration = (props) => {
  const { setSecondData, goPrevious, goNext } = props;

  return (
    <React.Fragment>
      <ProgressBar percent={66} phase={2} phases={3} />
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
          email: "",
          phoneNumber: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstName) {
            errors.firstName = localize(dict.errors.required);
          }

          if (!values.lastName) {
            errors.lastName = localize(dict.errors.required);
          }

          if (!values.password) {
            errors.password = localize(dict.errors.required);
          }

          if (!values.confirmPassword) {
            errors.confirmPassword = localize(dict.errors.required);
          } else if (values.confirmPassword !== values.password) {
            errors.confirmPassword = localize(
              dict.errors.mustMatchInitialPassword
            );
          }

          if (!values.email) {
            errors.email = localize(dict.errors.required);
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = localize(dict.errors.invalidEmailAddress);
          }

          if (!values.phoneNumber) {
            errors.phoneNumber = localize(dict.errors.required);
          }

          return errors;
        }}
        onSubmit={(props, actions) => {
          setSecondData(props);
          goNext();
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <React.Fragment>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 10,
                marginTop: 75,
                marginBottom: 75,
              }}
            >
              <InputGroupContainer>
                <InputGroup>
                  <span>{localize(dict.registration.firstName)} *</span>
                  <StyledInput.Input
                    type={"text"}
                    name={"firstName"}
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.firstName && touched.firstName}
                  />
                  <ErrorMessage>
                    {errors.firstName && touched.firstName && errors.firstName}
                  </ErrorMessage>
                </InputGroup>
                <InputGroup>
                  <span>{localize(dict.registration.lastName)} *</span>
                  <StyledInput.Input
                    type={"text"}
                    name={"lastName"}
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.lastName && touched.lastName}
                  />
                  <ErrorMessage>
                    {errors.lastName && touched.lastName && errors.lastName}
                  </ErrorMessage>
                </InputGroup>
              </InputGroupContainer>
              <InputGroupContainer>
                <InputGroup>
                  <span>{localize(dict.registration.password)} *</span>
                  <StyledInput.Input
                    type={"password"}
                    name={"password"}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.password && touched.password}
                  />
                  <ErrorMessage>
                    {errors.password && touched.password && errors.password}
                  </ErrorMessage>
                </InputGroup>
                <InputGroup>
                  <span>{localize(dict.registration.confirmPassword)} *</span>
                  <StyledInput.Input
                    type={"password"}
                    name={"confirmPassword"}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.confirmPassword && touched.confirmPassword}
                  />
                  <ErrorMessage>
                    {errors.confirmPassword &&
                      touched.confirmPassword &&
                      errors.confirmPassword}
                  </ErrorMessage>
                </InputGroup>
              </InputGroupContainer>
              <InputGroupContainer>
                <InputGroup>
                  <span>{localize(dict.registration.email)} *</span>
                  <StyledInput.Input
                    type={"email"}
                    name={"email"}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.email && touched.email}
                  />
                  <ErrorMessage>
                    {errors.email && touched.email && errors.email}
                  </ErrorMessage>
                </InputGroup>
                <InputGroup>
                  <span>{localize(dict.registration.cellPhoneNumber)} *</span>
                  <StyledInput.Input
                    type={"tel"}
                    name={"phoneNumber"}
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.phoneNumber && touched.phoneNumber}
                  />
                  <ErrorMessage>
                    {errors.phoneNumber &&
                    touched.phoneNumber &&
                    errors.phoneNumber ? (
                      errors.phoneNumber &&
                      touched.phoneNumber &&
                      errors.phoneNumber
                    ) : (
                      <span>{localize(dict.registration.dontWorry)}</span>
                    )}
                  </ErrorMessage>
                </InputGroup>
              </InputGroupContainer>
            </form>
            <div
              style={{
                display: "flex",
                maxWidth: 380,
                justifyContent: "space-between",
              }}
            >
              <StyledButton.Button
                style={{ maxWidth: 150, width: "100%" }}
                onClick={goPrevious}
              >
                <StyledButton.ButtonText>
                  {localize(dict.registration.back)}
                </StyledButton.ButtonText>
              </StyledButton.Button>
              <StyledButton.Button
                style={{ maxWidth: 150, width: "100%" }}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                <StyledButton.ButtonText>
                  {localize(dict.registration.next)}
                </StyledButton.ButtonText>
              </StyledButton.Button>
            </div>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
};
