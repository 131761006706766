import * as React from "react";
import { Navbar } from "../Home/Navbar";
import styled from "styled-components";
import Container from "../../components/Container";
import { device } from "../../utilities/responsive";
import { StyledSection } from "../../components";
import Card from "./components/Card";
import { useEffect, useState } from "react";
import axios from "axios";

const StyledContainer = styled(Container)`
  padding: 50px;
  align-items: center;

  h1 {
    font-family: "Martel Sans", sans-serif;
    margin-top: 0;
  }

  @media ${device.lg} {
    padding: 0;
  }
`;

const Blog = () => {
  const [posts, setPosts] = useState(null);

  useEffect(async () => {
    const posts = await axios({
      method: "get",
      url: `https://aqueous-oasis-97995.herokuapp.com/api/articles?populate=image`,
    });

    setPosts(posts?.data?.data || []);
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <div>
        <StyledSection.Section style={{ paddingTop: 75, paddingBottom: 100 }}>
          <StyledContainer style={{ flexDirection: "column" }}>
            <h1 style={{ marginBottom: "4rem" }}>Blog</h1>
            {!posts ? (
              <div style={{ marginBottom: "auto" }} />
            ) : posts.length ? (
              <div
                style={{
                  display: "flex",
                  columnGap: 20,
                  rowGap: 50,
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {posts.map(({ id, attributes }) => (
                  <Card key={id} attributes={attributes} />
                ))}
              </div>
            ) : (
              <div>No posts to display.</div>
            )}
          </StyledContainer>
        </StyledSection.Section>
      </div>
    </React.Fragment>
  );
};

export default Blog;
