export default {
  whatRoleAreYouSeeking: "What role are you seeking?",
  client: "Client",
  registerNow: "Register Now",
  registerForTeletherapy:
    "Register for teletherapy to start your journey to total wellness.",
  thankYou:
    "Thank you for starting your wellness journey with Sevelyn. The registration process will take less than two minutes to complete.",
  whatTypeOfTherapy: "What type of therapy are you seeking?",
  therapyForMyself: "Therapy for myself",
  couplesTherapy: "Couples Therapy",
  howOldAreYou: "How old are you?",
  twelveAndUnder: "12 and under",
  howDidYouHearAboutSevelyn: "How did you hear about Sevelyn?",
  googleSearch: "Google search",
  wordOfMouth: "Word of mouth",
  other: "Other",
  next: "Next",
  confirmPassword: "Confirm Password",
  password: "Password",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  cellPhoneNumber: "Cell Phone Number",
  back: "Back",
  tellUsTheType: "Tell us the type of therapist you're interested in seeing.",
  gender: "Gender",
  male: "Male",
  female: "Female",
  noPreference: "No preference",
  therapistWhoSpecializesInLGBTQ: "Therapist who specializes in LGBTQ?",
  specializesInLGBTQ: "Specializes in LGBTQ?",
  spiritualOrientation: "Spiritual Orientation",
  believer: "Believer",
  nonBeliever: "Non-Believer",
  countryOfOrigin: "Country of Origin",
  done: "Done",
  provider: "Provider",
  tellUsAboutYourself: "Tell us about yourself",
  willBeAbleToFind:
    "Users will be able to find you based on the information below.",
  whatCertificationsDoYouHave: "Do you have any certifications?",
  dontWorry: "Don't worry, we will only contact you about your meeting.",
};
