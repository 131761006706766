import styled from "styled-components";
import { device } from "../../utilities/responsive";
import PagesComponent from "./PagesComponent";

const DesktopPagesComponent = styled(PagesComponent)`
  display: none;

  @media ${device.lg} {
    display: flex;
    column-gap: 15px;
  }
`;

export default DesktopPagesComponent;
