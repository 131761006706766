import styled from "styled-components";

const Component = styled.label`
  display: flex;
  flex-direction: column;

  h4 {
    color: #626262;
    font-family: "Martel Sans", sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  ${({ style }) => ({ ...style })};
`;

const Label = ({ children, ...props }) => {
  return <Component {...props}>{children}</Component>;
};

export default { Label };
