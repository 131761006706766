import React from "react";
import { StyledButton, StyledInput, StyledSection } from "../../../components";
import Container from "../../../components/Container";
import { Field, Formik } from "formik";
import InputGroup from "./InputGroup";
import ErrorMessage from "../../../components/ErrorMessage";
import styled from "styled-components";
import { dict, i18n, localize } from "../../../i18n";
import { device } from "../../../utilities/responsive";
import CountrySelect from "../../../components/CountrySelect";
import GenderSelect from "../../../components/GenderSelect";
import Checkbox from "../../../components/Checkbox";
import axios from "axios";
import { toast } from "react-hot-toast";
import InputGroupContainer from "../../../components/InputGroupContainer";
import { NavLink } from "react-bootstrap";

const StyledContainer = styled(Container)`
  flex-direction: column;
  padding: 50px;

  h1 {
    font-family: "Martel Sans", sans-serif;
    margin-top: 0;
  }

  @media ${device.lg} {
    padding: 0;
  }
`;

const Link = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const ProvidersForm = (props) => {
  const lng = i18n.language;
  const lngIsSpanish = /es/.test(lng);

  return (
    <StyledSection.Section style={{ paddingTop: 100, paddingBottom: 100 }}>
      <StyledContainer>
        <h1>{localize(dict.providers.applicationForm)}</h1>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            confirmEmail: "",
            phoneNumber: "",
            whatsAppNumber: "",
            nationality: "United States of America",
            gender: "Male",
            yearsOfExperience: 3,
            consent: false,
            areaOfSpecialty: "",
            certifications: "",
          }}
          validate={(formValues) => {
            const errors = {};

            if (!formValues.firstName || formValues.firstName.length < 1) {
              errors.firstName = localize(dict.errors.required);
            }

            if (!formValues.lastName || formValues.lastName.length < 1) {
              errors.lastName = localize(dict.errors.required);
            }

            if (!formValues.email) {
              errors.email = localize(dict.errors.required);
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValues.email)
            ) {
              errors.email = localize(dict.errors.invalidEmailAddress);
            }

            if (!formValues.confirmEmail) {
              errors.confirmEmail = localize(dict.errors.required);
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                formValues.confirmEmail
              )
            ) {
              errors.confirmEmail = localize(dict.errors.invalidEmailAddress);
            } else if (formValues.email !== formValues.confirmEmail) {
              errors.confirmEmail = localize(dict.errors.emailAddressNoMatch);
            }

            if (!formValues.phoneNumber) {
              errors.phoneNumber = localize(dict.errors.required);
            }

            if (!formValues.whatsAppNumber) {
              errors.whatsAppNumber = localize(dict.errors.required);
            }

            if (!formValues.yearsOfExperience) {
              errors.yearsOfExperience = localize(
                dict.errors.mustProvideYearsOfExperience
              );
            } else if (formValues.yearsOfExperience < 3) {
              errors.yearsOfExperience = localize(
                dict.errors.mustHaveAtLeastThreeYearsOfExperience
              );
            }

            if (
              !formValues.areaOfSpecialty ||
              formValues.areaOfSpecialty.length < 1
            ) {
              errors.areaOfSpecialty = localize(
                dict.errors.pleaseEnterInformationRegardingAreaOfSpecialty
              );
            }

            if (
              !formValues.certifications ||
              formValues.certifications.length < 1
            ) {
              errors.certifications = localize(
                dict.errors.pleaseEnterYourCertifications
              );
            }

            if (!formValues.consent) {
              errors.consent = localize(
                dict.errors.mustAcceptTermsOfUseAndPrivacyPolicy
              );
            }

            return errors;
          }}
          onSubmit={async (formValues, helpers) => {
            try {
              const res = await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}/api/intake`,
                data: formValues,
              });

              toast.success(
                localize(
                  dict.providers.successfullySentProviderIntakeInformation
                )
              );
              helpers.resetForm();
            } catch (err) {
              if (err.response) {
                console.error("error", err.response.data.message);
                return toast.error(`Error: ${err.response.data.message}`);
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <React.Fragment>
              <InputGroupContainer
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 30,
                }}
              >
                <InputGroupContainer>
                  <InputGroup>
                    <span>{localize(dict.providers.firstName)} *</span>
                    <StyledInput.Input
                      type={"text"}
                      name={"firstName"}
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.firstName && touched.firstName}
                    />
                    <ErrorMessage>
                      {errors.firstName &&
                        touched.firstName &&
                        errors.firstName}
                    </ErrorMessage>
                  </InputGroup>
                  <InputGroup>
                    <span>{localize(dict.providers.lastName)} *</span>
                    <StyledInput.Input
                      type={"text"}
                      name={"lastName"}
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.lastName && touched.lastName}
                    />
                    <ErrorMessage>
                      {errors.lastName && touched.lastName && errors.lastName}
                    </ErrorMessage>
                  </InputGroup>
                </InputGroupContainer>
                <InputGroupContainer>
                  <InputGroup>
                    <span>{localize(dict.providers.email)} *</span>
                    <StyledInput.Input
                      type={"text"}
                      name={"email"}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.email && touched.email}
                    />
                    <ErrorMessage>
                      {errors.email && touched.email && errors.email}
                    </ErrorMessage>
                  </InputGroup>
                  <InputGroup>
                    <span>{localize(dict.providers.confirmEmail)} *</span>
                    <StyledInput.Input
                      type={"text"}
                      name={"confirmEmail"}
                      value={values.confirmEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.confirmEmail && touched.confirmEmail}
                    />
                    <ErrorMessage>
                      {errors.confirmEmail &&
                        touched.confirmEmail &&
                        errors.confirmEmail}
                    </ErrorMessage>
                  </InputGroup>
                </InputGroupContainer>
                <InputGroupContainer>
                  <InputGroup>
                    <span>{localize(dict.registration.cellPhoneNumber)} *</span>
                    <StyledInput.Input
                      type={"text"}
                      name={"phoneNumber"}
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.phoneNumber && touched.phoneNumber}
                    />
                    <ErrorMessage>
                      {errors.phoneNumber &&
                        touched.phoneNumber &&
                        errors.phoneNumber}
                    </ErrorMessage>
                  </InputGroup>
                  <InputGroup>
                    <span>{localize(dict.providers.whatsAppNumber)} *</span>
                    <StyledInput.Input
                      type={"text"}
                      name={"whatsAppNumber"}
                      value={values.whatsAppNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.whatsAppNumber && touched.whatsAppNumber}
                    />
                    <ErrorMessage>
                      {errors.whatsAppNumber &&
                        touched.whatsAppNumber &&
                        errors.whatsAppNumber}
                    </ErrorMessage>
                  </InputGroup>
                </InputGroupContainer>
                <InputGroupContainer>
                  <InputGroup>
                    <span>{localize(dict.providers.nationality)} *</span>
                    <CountrySelect
                      name={"nationality"}
                      value={values.nationality}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage>
                      {errors.nationality &&
                        touched.nationality &&
                        errors.nationality}
                    </ErrorMessage>
                  </InputGroup>
                  <InputGroup>
                    <span>{localize(dict.providers.gender)} *</span>
                    <GenderSelect
                      name={"gender"}
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage>
                      {errors.gender && touched.gender && errors.gender}
                    </ErrorMessage>
                  </InputGroup>
                </InputGroupContainer>
                <InputGroupContainer>
                  <InputGroup>
                    <span>{localize(dict.app.yearsOfExperience)} *</span>
                    <StyledInput.Input
                      type={"number"}
                      name={"yearsOfExperience"}
                      value={values.yearsOfExperience}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      min={3}
                    />
                    <ErrorMessage>
                      {errors.yearsOfExperience &&
                        touched.yearsOfExperience &&
                        errors.yearsOfExperience}
                    </ErrorMessage>
                  </InputGroup>
                </InputGroupContainer>
                <InputGroup style={{ maxWidth: 670, width: "100%" }}>
                  <span>{localize(dict.app.areaOfSpecialty)} *</span>
                  <Field
                    name="areaOfSpecialty"
                    as={CustomTextarea}
                    errors={errors.areaOfSpecialty && touched.areaOfSpecialty}
                  />
                  <ErrorMessage>
                    {errors.areaOfSpecialty &&
                      touched.areaOfSpecialty &&
                      errors.areaOfSpecialty}
                  </ErrorMessage>
                </InputGroup>
                <InputGroup style={{ maxWidth: 670, width: "100%" }}>
                  <span>{localize(dict.app.certifications)} *</span>
                  <Field
                    name="certifications"
                    as={CustomTextarea}
                    errors={errors.certifications && touched.certifications}
                  />
                  <ErrorMessage>
                    {errors.certifications &&
                      touched.certifications &&
                      errors.certifications}
                  </ErrorMessage>
                </InputGroup>
                <InputGroupContainer
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <InputGroup
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      maxWidth: 500,
                    }}
                  >
                    <Field
                      type="checkbox"
                      name="consent"
                      checked={values.consent}
                      component={(props) => (
                        <Checkbox
                          {...props}
                          onChange={() =>
                            setFieldValue("consent", !values.consent)
                          }
                          onBlur={handleBlur}
                        />
                      )}
                    />
                    <span
                      style={{ marginLeft: 5, marginBottom: 0, fontSize: 14 }}
                    >
                      {lngIsSpanish ? (
                        <span>
                          Soy mayor de 18 años y acepto los términos de uso y{" "}
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              props.setModal(true);
                            }}
                          >
                            la política de privacidad
                          </Link>
                          .
                        </span>
                      ) : (
                        <span>
                          I am over the age of 18 and agree to the terms of use
                          and{" "}
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              props.setModal(true);
                            }}
                          >
                            privacy policy
                          </Link>
                          .
                        </span>
                      )}
                    </span>
                  </InputGroup>
                  <ErrorMessage>
                    {errors.consent && touched.consent && errors.consent}
                  </ErrorMessage>
                </InputGroupContainer>
                <StyledButton.Button
                  md={{ maxWidth: 300, width: "100%" }}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  <StyledButton.ButtonText>
                    {localize(dict.app.submit)}
                  </StyledButton.ButtonText>
                </StyledButton.Button>
              </InputGroupContainer>
            </React.Fragment>
          )}
        </Formik>
      </StyledContainer>
    </StyledSection.Section>
  );
};

const StyledTextarea = styled.textarea`
  min-height: 200px;
  border-radius: 8px;

  ${({ errors }) => (errors ? { borderColor: "red" } : {})}
`;

const CustomTextarea = (props) => {
  return <StyledTextarea {...props} style={{ minHeight: 200 }} />;
};

export default ProvidersForm;
