export default {
  satisfactionGuaranteed: "Testimonios",
  satisfactionGuaranteedSub:
    "Sevelyn ha ayudado a muchas personas como tú, prueba nuestra plataforma GRATIS y sin compromiso.",
  testimonialOne: "¡Fácil de usar y muy útil!",
  testimonialTwo:
    "Me alegro de poder hablar con alguien que me comprenda a mí y a mi cultura. ¡Buena experiencia!",
  testimonialThree:
    "Mi proveedor me ayudó a comprender mejor por qué me sentía así y a superar mis síntomas actuales. ¡Recomiendo mucho este servicio!",
};
