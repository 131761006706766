export default {
  addictionCoping: "Addiction Coping",
  adhd: "ADHD",
  anxiety: "Anxiety",
  stress: "Stress",
  bipolarDisorder: "Bipolar Disorder",
  couplesTherapy: "Couples Therapy",
  depression: "Depression",
  angerManagement: "Anger Management",
  familyConflicts: "Family Conflicts",
  grief: "Grief",
  relationships: "Relationships",
  parentingCoaching: "Parenting Coaching",
  sleepingDisorders: "Sleeping Disorders",
  intimacyIssues: "Intimacy Issues",
  selfEsteem: "Self Esteem",
};
