import * as React from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { Routes } from "../../Routes";
import {
  StyledButton,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledLink,
} from "../../components/index";
import Container from "../../components/Container";
import { Navbar } from "../../components/Navbar";
import { NavLink } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { toast } from "react-hot-toast";
import Reset from "./Reset";
import { dict, localize } from "../../i18n";

const Group = styled(StyledLabel.Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  span {
    margin-bottom: 5px;
  }
`;

const InputGroup = ({ children }) => <Group>{children}</Group>;

const SignIn = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  useEffect(async () => {
    if (history.location.state?.successfulReset) {
      await toast.success(localize(dict.errors.successfullyResetPassword));
      history.replace(Routes.SIGN_IN, { successfulReset: null });
    }
  }, [history]);

  const buttonOnClick = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login`,
        { email, password },
        {
          headers: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            "cache-control": "no-cache",
            "Content-Type": "application/json",
          },
          timeout: 180000,
        }
      );

      if (!jwt_decode(res.data.token).approved) {
        console.error("Account is currently under review.");
        await toast.error(localize(dict.error.errorWhileLoggingIn));
        setError("Account is currently under review.");
        return;
      }

      await localStorage.setItem("token", res.data.token);
      return history.push(Routes.DASHBOARD);
    } catch (err) {
      if (err.response) {
        console.error("error", err.response.data.message);
        return toast.error(`Error: ${err.response.data.message}`);
      }
    }
  };

  const requestPasswordReset = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/forgot-password`, {
        email,
      });

      return toast.success(localize(dict.errors.aPasswordResetLinkHasBeenSent));
    } catch (err) {
      if (err.response) {
        console.error("error", err.response.data.message);
        return toast.error(`Error: ${err.response.data.message}`);
      }
    }
  };

  /*localStorage.getItem("token") &&
    jwt_decode(localStorage.getItem("token")).approved ? (
    history.push(Routes.DASHBOARD)
  ) : */
  return localStorage.getItem("token") &&
    jwt_decode(localStorage.getItem("token")).approved ? (
    <Redirect to={Routes.DASHBOARD} />
  ) : (
    <React.Fragment>
      <Navbar />
      <Container
        style={{
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        {history.location.search.includes("token") ? (
          <Reset tokenSearch={history.location.search} />
        ) : (
          <React.Fragment>
            <span
              style={{
                width: 301,
                height: 53,
                margin: "42px 10px 42px 9.3px",
                fontFamily: "Cantata One",
                fontSize: 40,
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "-0.14px",
                textAlign: "center",
                color: "#3b3b3b",
              }}
            >
              {localize(dict.app.hello)}
            </span>
            <StyledForm.Form style={{ maxWidth: 320 }}>
              {error && (
                <div
                  style={{
                    borderRadius: 4,
                    border: "1px solid red",
                    padding: 10,
                    color: "white",
                    backgroundColor: "red",
                    marginBottom: 10,
                  }}
                >
                  {error}
                </div>
              )}
              <InputGroup>
                <span>{localize(dict.app.email)}</span>
                <StyledInput.Input
                  placeHolder={localize(dict.app.email)}
                  onChange={(e) => {
                    setError(null);
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
              </InputGroup>
              <InputGroup>
                <span>{localize(dict.app.password)}</span>
                <StyledInput.Input
                  placeHolder={localize(dict.app.password)}
                  type={"password"}
                  onChange={(e) => {
                    setError(null);
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              </InputGroup>
              <StyledLink.Link onClick={requestPasswordReset}>
                {localize(dict.app.forgotPassword)}
              </StyledLink.Link>
              <StyledButton.Button
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  // maxWidth: 200,
                  width: "100%",
                }}
                onClick={buttonOnClick}
              >
                <StyledButton.ButtonText>
                  {localize(dict.app.logIn)}
                </StyledButton.ButtonText>
              </StyledButton.Button>
              <StyledForm.SubText>
                <span style={{ marginRight: 5 }}>
                  {localize(dict.app.dontHaveAnAccount)}
                </span>
                <NavLink to={Routes.REGISTRATION}>
                  <StyledLink.Link>
                    {localize(dict.app.getStarted)}
                  </StyledLink.Link>
                </NavLink>
              </StyledForm.SubText>
            </StyledForm.Form>
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  );
};

export { SignIn };
