import styled from "styled-components";
import { device } from "../../utilities/responsive";

const MobileNavDrawer = styled.div`
  display: ${({ showCollapse }) => (showCollapse ? "flex" : "none")};
  position: fixed;
  top: 92px;
  background-color: #fafafa;
  width: 100%;
  z-index: 10000;
  padding-bottom: 40px;
  flex-direction: column;

  > div {
    max-width: 100%;
    justify-content: space-between !important;

    > div {
      justify-content: flex-start !important;
      flex-direction: column;

      a {
        display: flex;
        justify-content: center;
        padding: 15px 20px;
        text-align: center;
        width: 100%;
        color: #fe6551;

        &:hover {
          cursor: pointer;
          background-color: #ececec;
        }
      }
    }
  }

  @media ${device.lg} {
    display: none;
  }
`;

export default MobileNavDrawer;
