import app from "./scopes/t9n.en.app";
import pricing from "./scopes/t9n.en.pricing";
import guaranteed from "./scopes/t9n.en.guaranteed";
import careSeekers from "./scopes/t9n.en.careSeekers";
import providers from "./scopes/t9n.en.providers";
import aboutUs from "./scopes/t9n.en.aboutUs";
import contactUs from "./scopes/t9n.en.contactUs";
import appointments from "./scopes/t9n.en.appointments";
import dashboard from "./scopes/t9n.en.dashboard";
import therapist from "./scopes/t9n.en.therapist";
import registration from "./scopes/t9n.en.registration";
import specialties from "./scopes/t9n.en.specialties";
import errors from "./scopes/t9n.en.errors";

export const en = {
  app,
  pricing,
  guaranteed,
  careSeekers,
  providers,
  aboutUs,
  contactUs,
  appointments,
  dashboard,
  therapist,
  registration,
  specialties,
  errors,
};
