import React from "react";
import { NavLink } from "react-router-dom";
import { Routes } from "../../Routes";

const MobileActions = (props) => {
  return [
    <NavLink to={Routes.SETTINGS}>
      <span>Settings</span>
    </NavLink>,
    <NavLink to={() => Routes.SIGN_OUT}>
      <span>Sign Out</span>
    </NavLink>,
  ];
};

export default MobileActions;
