import jwt_decode from "jwt-decode";
import { NavLink } from "react-router-dom";
import { Routes } from "../../Routes";
import { dict, localize } from "../../i18n";

const PagesLinks = () => {
  const user = jwt_decode(localStorage.getItem("token"));

  const therapistsLink = user.roles.includes("ROLE_CLIENT") && (
    <NavLink
      exact
      activeStyle={{ color: "#fe6551" }}
      to={Routes.DASHBOARD}
      isActive={(match, location) => {
        if (
          location.pathname === Routes.DASHBOARD ||
          location.pathname.includes("therapist")
        )
          return true;
      }}
    >
      <span>{localize(dict.app.therapists)}</span>
    </NavLink>
  );

  const appointmentsLink = (
    <NavLink activeStyle={{ color: "#fe6551" }} to={Routes.APPOINTMENTS}>
      <span>{localize(dict.app.appointments)}</span>
    </NavLink>
  );

  const adminLink = user.roles.includes("ROLE_ADMIN") && (
    <NavLink exact activeStyle={{ color: "#fe6551" }} to={Routes.ADMIN}>
      <span>{localize(dict.app.admin)}</span>
    </NavLink>
  );

  return [therapistsLink, appointmentsLink, adminLink];
};

export default PagesLinks;
