import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex: 0 1 calc(50% - 2 * 10px);
  position: relative;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 rgba(173, 173, 173, 0.5);
  background-color: #fff;
  align-self: flex-start;
  padding: 50px;
  width: 100%;

  ${({ style }) => ({ ...style })}
`;
