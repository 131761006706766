import React from "react";
import {
  StyledButton,
  StyledForm,
  StyledInput,
  StyledLabel,
} from "../../components";
import { Formik } from "formik";
import styled from "styled-components";
import ErrorMessage from "../../components/ErrorMessage";
import { dict, localize } from "../../i18n";
import Container from "../../components/Container";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Routes } from "../../Routes";

const Group = styled(StyledLabel.Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  span {
    margin-bottom: 5px;
  }
`;

const InputGroup = ({ children }) => <Group>{children}</Group>;

const Reset = ({ tokenSearch }) => {
  const history = useHistory();
  console.log(history.location.search.split("=")[1]);
  return (
    <React.Fragment>
      <Container
        style={{
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <span
          style={{
            width: 301,
            height: 53,
            margin: "42px 10px 42px 9.3px",
            fontFamily: "Cantata One",
            fontSize: 30,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "-0.14px",
            textAlign: "center",
            color: "#3b3b3b",
          }}
        >
          Password Reset
        </span>
        <StyledForm.Form style={{ maxWidth: 320 }}>
          <Formik
            initialValues={{ newPassword: "", confirmNewPassword: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.newPassword) {
                errors.newPassword = localize(dict.errors.required);
              }

              if (!values.confirmNewPassword) {
                errors.confirmNewPassword = localize(dict.errors.required);
              } else if (values.confirmNewPassword !== values.newPassword) {
                errors.confirmNewPassword = localize(
                  dict.errors.mustMatchInitialPassword
                );
              }
              return errors;
            }}
            onSubmit={async (fieldValues, actions) => {
              try {
                const response = await axios({
                  method: "post",
                  url: `${process.env.REACT_APP_API_URL}/api/reset-password`,
                  data: {
                    resetPasswordToken: history.location.search.split("=")[1],
                    password: fieldValues.newPassword,
                  },
                });

                if (response.status != "200") {
                  throw localize(dict.errors.failedToResetPassword);
                }

                await toast.success(
                  localize(dict.errors.successfullyResetPassword)
                );
                history.push(Routes.SIGN_IN, {
                  search: null,
                  successfulReset: true,
                });
                window.location.reload();
              } catch (err) {
                await toast.error(err);
                actions.setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <React.Fragment>
                <InputGroup>
                  <span>{localize(dict.errors.newPassword)}</span>
                  <StyledInput.Input
                    placeHolder={localize(dict.errors.newPassword)}
                    name={"newPassword"}
                    type={"password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                  />
                  <ErrorMessage>
                    {errors.newPassword &&
                      touched.newPassword &&
                      errors.newPassword}
                  </ErrorMessage>
                </InputGroup>
                <InputGroup>
                  <span>{localize(dict.errors.confirmNewPassword)}</span>
                  <StyledInput.Input
                    placeHolder={localize(dict.errors.confirmNewPassword)}
                    name={"confirmNewPassword"}
                    type={"password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmNewPassword}
                  />
                  <ErrorMessage>
                    {errors.confirmNewPassword &&
                      touched.confirmNewPassword &&
                      errors.confirmNewPassword}
                  </ErrorMessage>
                </InputGroup>
                <StyledButton.Button
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    width: "100%",
                  }}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  <StyledButton.ButtonText>
                    {localize(dict.errors.resetPassword)}
                  </StyledButton.ButtonText>
                </StyledButton.Button>
              </React.Fragment>
            )}
          </Formik>
        </StyledForm.Form>
      </Container>
    </React.Fragment>
  );
};

export default Reset;
