import { StyledButton, StyledSection } from "../../../components";
import * as React from "react";
import Container from "../../../components/Container";
import { Routes } from "../../../Routes";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../../utilities/responsive";
import { dict, localize } from "../../../i18n";
import icon1 from "../../../pics/create-account-icon.png";
import icon2 from "../../../pics/calendar-icon.png";
import icon3 from "../../../pics/start-therapy-icon.png";

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-direction: column;
  row-gap: 50px;
  column-gap: 30px;

  > div {
    padding: 30px;
    text-align: center;
  }

  @media ${device.lg} {
    align-items: center;
    flex-direction: row;
  }
`;

const StyledSectionSection = styled(StyledSection.Section)`
  flex-direction: column;
  background-color: #ffebe6;
  row-gap: 50px;
  padding: 50px;
  align-items: center;

  @media ${device.lg} {
    justify-content: space-around;
    padding: 75px 0;
  }
`;

export const CommunitySection = () => (
  <StyledSectionSection>
    <Container
      style={{
        flexDirection: "column",
        justifyContent: "space-around",
        rowGap: 50,
      }}
    >
      <h2
        style={{
          color: "#3B3B3B",
          fontFamily: "Cantata One, serif",
          fontSize: 30,
          textAlign: "center",
          fontWeight: "normal",
        }}
      >
        {localize(dict.app.howItWorks)}
      </h2>
      <StyledDiv>
        <div
          id={"pencil"}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: 30,
          }}
        >
          <img
            src={icon1}
            alt=""
            style={{
              maxWidth: 150,
              objectFit: "contain",
            }}
          />
          <span>{localize(dict.app.tellUsAboutYourself)}</span>
        </div>
        <div
          id={"heart"}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: 30,
          }}
        >
          <img
            src={icon2}
            alt=""
            style={{ maxWidth: 150, objectFit: "contain" }}
          />
          <span>{localize(dict.app.getMatched)}</span>
        </div>
        <div
          id={"chat"}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: 30,
          }}
        >
          <img
            src={icon3}
            alt=""
            style={{ maxWidth: 150, objectFit: "contain" }}
          />
          <span>{localize(dict.app.startTherapy)}</span>
        </div>
      </StyledDiv>
    </Container>
    <NavLink to={Routes.SIGN_IN} style={{ width: "100%" }}>
      <StyledButton.Button
        md={{ width: "100%", maxWidth: 300, margin: "0 auto" }}
      >
        <StyledButton.ButtonText>
          {localize(dict.app.bookFreeSessionNow)}
        </StyledButton.ButtonText>
      </StyledButton.Button>
    </NavLink>
  </StyledSectionSection>
);
