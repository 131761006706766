const aboutUsScope = "aboutUs";

export default {
  aboutUsHeadline: `${aboutUsScope}.aboutUsHeadline`,
  aboutUsSubCopy: `${aboutUsScope}.aboutUsSubCopy`,
  ourMissionHeadline: `${aboutUsScope}.ourMissionHeadline`,
  ourMissionSubCopy: `${aboutUsScope}.ourMissionSubCopy`,
  ourPromiseHeadline: `${aboutUsScope}.ourPromiseHeadline`,
  affordability: `${aboutUsScope}.affordability`,
  safetyAndConfidentiality: `${aboutUsScope}.safetyAndConfidentiality`,
  convenience: `${aboutUsScope}.convenience`,
  qualityFirst: `${aboutUsScope}.qualityFirst`,
  cultureFirst: `${aboutUsScope}.cultureFirst`,
};
