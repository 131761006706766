import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar";
import { FirstRegistration } from "./seeker/first";
import { SecondRegistration } from "./seeker/second";
import { StyledButton, StyledLabel, StyledSection } from "../../components";
import styled from "styled-components";
import Container from "../../components/Container";
import { ThirdRegistration } from "./seeker/third";
import { FirstProviderRegistration } from "./provider/first";
import { SecondProviderRegistration } from "./provider/second";
import axios from "axios";
import { useHistory } from "react-router";
import { Routes } from "../../Routes";
import { toast } from "react-hot-toast";
import { ThirdProviderRegistration } from "./provider/third";
import { device } from "../../utilities/responsive";
import { localize, dict, i18n } from "../../i18n/index";

const ButtonGroup = styled.div`
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  max-width: 380px;

  > div {
    max-width: 150px;
    width: 100%;
    border: 1px solid #9d9d9d;
    background-color: white;

    span {
      color: #2b2b2b;
      font-family: "Martel Sans", sans-serif;
      font-weight: normal;
    }
  }
  ${({ style }) => ({ ...style })};
`;

const Button = ({ children, onClick }) => (
  <StyledButton.Button style={{ maxWidth: 150 }} onClick={onClick}>
    {children}
  </StyledButton.Button>
);

const StyledContainer = styled(Container)`
  h2 {
    font-family: "Cantata One", serif;
    font-weight: normal;
    font-size: 40px;
    margin-bottom: 0;
  }

  h3 {
    font-family: "Martel Sans", sans-serif;
    font-size: 20px;
    color: #626262;
    margin-bottom: 0;
  }

  p {
    font-size: 18px;
    color: #626262;
  }
`;

const StyledSectionSection = styled(StyledSection.Section)`
  padding: 50px;
  padding-bottom: 300px;

  @media ${device.lg} {
    padding: 0;
    padding-top: 100px;
  }
`;

export const Registration = (props) => {
  const history = useHistory();
  const [role, setRole] = useState(
    history.location.search === "?admin" ? "ROLE_THERAPIST" : "ROLE_CLIENT"
  );
  const [firstData, setFirstData] = useState({});
  const [secondData, setSecondData] = useState({});
  const [thirdData, setThirdData] = useState({});
  const [phase, setPhase] = useState(1);

  useEffect(() => {
    setFirstData({});
    setSecondData({});
    setThirdData({});
  }, [role]);

  const createUserAndProfile = async (passedData) => {
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/register`,
        data: { role, ...firstData, ...secondData, ...passedData },
        headers: {
          "X-Language": !i18n.language ? "en-US" : i18n.language,
        },
      });

      if (response.status === 201) {
        await toast.success(localize(dict.errors.successfullyCreatedAccount));
        history.push(Routes.SIGN_IN);
      }
    } catch (err) {
      console.error("error", err);
      await toast.error(localize(dict.errors.errorWhileCreatingUserAccount));
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <StyledSectionSection>
        <StyledContainer style={{ flexDirection: "column" }}>
          <div style={role === null ? {} : { height: 0, overflow: "hidden" }}>
            <React.Fragment>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 30,
                  marginTop: 75,
                  marginBottom: 75,
                }}
              >
                <StyledLabel.Label style={{ textAlign: "center" }}>
                  <h4>{localize(dict.registration.whatRoleAreYouSeeking)}</h4>
                  <ButtonGroup
                    style={{ maxWidth: "100%", justifyContent: "center" }}
                  >
                    <Button onClick={() => setRole("ROLE_CLIENT")}>
                      {localize(dict.registration.client)}
                    </Button>
                    <Button onClick={() => setRole("ROLE_THERAPIST")}>
                      {localize(dict.registration.provider)}
                    </Button>
                  </ButtonGroup>
                </StyledLabel.Label>
              </form>
            </React.Fragment>
          </div>
          {role === "ROLE_THERAPIST" ? (
            <React.Fragment>
              <h2>{localize(dict.registration.tellUsAboutYourself)}</h2>
              <div>
                <h3>{localize(dict.registration.willBeAbleToFind)}</h3>
              </div>
              <div style={phase === 1 ? {} : { height: 0, overflow: "hidden" }}>
                <FirstProviderRegistration
                  setFirstData={setFirstData}
                  goNext={() => setPhase(2)}
                />
              </div>
              <div style={phase === 2 ? {} : { height: 0, overflow: "hidden" }}>
                <SecondProviderRegistration
                  setSecondData={setSecondData}
                  goPrevious={() => setPhase(1)}
                  goNext={() => setPhase(3)}
                />
              </div>
              <div style={phase === 3 ? {} : { height: 0, overflow: "hidden" }}>
                <ThirdProviderRegistration
                  setThirdData={setThirdData}
                  goPrevious={() => setPhase(2)}
                  goNext={createUserAndProfile}
                />
              </div>
            </React.Fragment>
          ) : null}
          {role === "ROLE_CLIENT" ? (
            <React.Fragment>
              <h2>{localize(dict.registration.registerNow)}</h2>
              <div>
                {/*<h3>{localize(dict.registration.registerForTeletherapy)}</h3>*/}
                <p>{localize(dict.registration.thankYou)}</p>
              </div>
              <div style={phase === 1 ? {} : { height: 0, overflow: "hidden" }}>
                <FirstRegistration
                  setFirstData={setFirstData}
                  goNext={() => {
                    setPhase(2);
                  }}
                />
              </div>
              <div style={phase === 2 ? {} : { height: 0, overflow: "hidden" }}>
                <SecondRegistration
                  setSecondData={setSecondData}
                  goPrevious={() => setPhase(1)}
                  goNext={() => setPhase(3)}
                />
              </div>
              <div style={phase === 3 ? {} : { height: 0, overflow: "hidden" }}>
                <ThirdRegistration
                  setThirdData={setThirdData}
                  goPrevious={() => setPhase(2)}
                  goNext={createUserAndProfile}
                />
              </div>
            </React.Fragment>
          ) : null}
        </StyledContainer>
      </StyledSectionSection>
    </React.Fragment>
  );
};
