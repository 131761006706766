import styled from "styled-components";
import { device } from "../../utilities/responsive";

const Container = styled.div`
  justify-content: center;

  @media ${device.lg} {
    flex: 1 0 50%;
  }
`;

const Sidebar = styled.div`
  min-height: 100%;
  background-color: #fff;
  border: solid 1px #e4e7eb;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  cursor: pointer;

  @media ${device.lg} {
    order: 1;
  }

  ${({ style }) => ({ ...style })};
`;

export default { Container, Sidebar };
