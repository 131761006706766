import { Card as BaseCard } from "../../Admin/Card";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../Routes";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  flex: "0 0 33.3%",
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: 20,
  rowGap: 15,
  alignSelf: "stretch",
  minHeight: 520,
  maxHeight: 520,
};

const Card = ({ attributes }) => {
  const history = useHistory();
  const { description, slug, title, timeToRead } = attributes;
  const [hover, setHover] = useState(false);

  return (
    <BaseCard
      style={
        hover
          ? { ...cardStyle, color: "#fe6551", cursor: "pointer" }
          : { ...cardStyle }
      }
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => history.push(`${Routes.BLOG}/${slug}`)}
    >
      <img
        src={attributes.image.data.attributes.url}
        style={{ width: 350, height: 250, borderRadius: 10, marginBottom: 15 }}
      />
      <h4>{title}</h4>
      {timeToRead && <span>{timeToRead}</span>}
      <p>{description}</p>
    </BaseCard>
  );
};
export default Card;
