import React, { useEffect, useState } from "react";
import { Navbar } from "./Navbar";
import { CTASection } from "./Sections/CTA";
import { CommunitySection } from "./Sections/Community";
import { BenefitsSection } from "./Sections/Benefits";
import { PricingSection } from "./Sections/Pricing";
import { GuaranteedSection } from "./Sections/Guaranteed";
import { FooterSection } from "./Sections/Footer";
import PrivacyPolicyModal from "../../components/PrivacyPolicyModal";
import * as ReactGA from "react-ga";

const Landing = () => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <div>
        <CTASection />
        <CommunitySection />
        <BenefitsSection />
        <PricingSection />
        <GuaranteedSection />
        <FooterSection setModal={setModal} />
      </div>
      <PrivacyPolicyModal modal={modal} setModal={setModal} />
    </React.Fragment>
  );
};

export { Landing };
