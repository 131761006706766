import app from "./scopes/t9n.es.app";
import pricing from "./scopes/t9n.es.pricing";
import guaranteed from "./scopes/t9n.es.guaranteed";
import careSeekers from "./scopes/t9n.es.careSeekers";
import providers from "./scopes/t9n.es.providers";
import aboutUs from "./scopes/t9n.es.aboutUs";
import contactUs from "./scopes/t9n.es.contactUs";
import appointments from "./scopes/t9n.es.appointments";
import dashboard from "./scopes/t9n.es.dashboard";
import therapist from "./scopes/t9n.es.therapist";
import registration from "./scopes/t9n.es.registration";
import specialties from "./scopes/t9n.es.specialties";
import errors from "./scopes/t9n.es.errors";

export const es = {
  app,
  pricing,
  guaranteed,
  careSeekers,
  providers,
  aboutUs,
  contactUs,
  appointments,
  dashboard,
  therapist,
  registration,
  specialties,
  errors,
};
