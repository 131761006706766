const errorsScope = "errors";

export default {
  required: `${errorsScope}.required`,
  invalidEmailAddress: `${errorsScope}.invalidEmailAddress`,
  emailAddressNoMatch: `${errorsScope}.emailAddressNoMatch`,
  pleaseEnterInformationRegardingInquiry: `${errorsScope}.pleaseEnterInformationRegardingInquiry`,
  mustMatchInitialPassword: `${errorsScope}.mustMatchInitialPassword`,
  mustProvideYearsOfExperience: `${errorsScope}.mustProvideYearsOfExperience`,
  mustHaveAtLeastThreeYearsOfExperience: `${errorsScope}.mustHaveAtLeastThreeYearsOfExperience`,
  pleaseEnterInformationRegardingAreaOfSpecialty: `${errorsScope}.pleaseEnterInformationRegardingAreaOfSpecialty`,
  pleaseEnterYourCertifications: `${errorsScope}.pleaseEnterYourCertifications`,
  mustAcceptTermsOfUseAndPrivacyPolicy: `${errorsScope}.mustAcceptTermsOfUseAndPrivacyPolicy`,
  removedFavorite: `${errorsScope}.removedFavorite`,
  paymentSuccessful: `${errorsScope}.paymentSuccessful`,
  failedToSubmitPayment: `${errorsScope}.failedToSubmitPayment`,
  successfullyUpdated: `${errorsScope}.successfullyUpdated`,
  errorWhileUpdatingUser: `${errorsScope}.errorWhileUpdatingUser`,
  successfullyUploadedImage: `${errorsScope}.successfullyUploadedImage`,
  failedToUploadImage: `${errorsScope}.failedToUploadImage`,
  errorWhileLoggingIn: `${errorsScope}.errorWhileLoggingIn`,
  accountIsCurrentlyUnderReview: `${errorsScope}.accountIsCurrentlyUnderReview`,
  successfullyScheduledAppointment: `${errorsScope}.successfullyScheduledAppointment`,
  failedToScheduleAppointment: `${errorsScope}.failedToScheduleAppointment`,
  youDoNotHaveAnyCreditsAvailable: `${errorsScope}.youDoNotHaveAnyCreditsAvailable`,
  successfullyCreatedAccount: `${errorsScope}.successfullyCreatedAccount`,
  errorWhileCreatingUserAccount: `${errorsScope}.errorWhileCreatingUserAccount`,
  passwordReset: `${errorsScope}.passwordReset`,
  newPassword: `${errorsScope}.newPassword`,
  confirmNewPassword: `${errorsScope}.confirmNewPassword`,
  pleaseFollowTheLinkToResetYourPassword: `${errorsScope}.pleaseFollowTheLinkToResetYourPassword`,
  aPasswordResetLinkHasBeenSent: `${errorsScope}.aPasswordResetLinkHasBeenSent`,
  successfullyResetPassword: `${errorsScope}.successfullyResetPassword`,
  failedToResetPassword: `${errorsScope}.failedToResetPassword`,
  resetPassword: `${errorsScope}.resetPassword`,
};
