export default {
  aboutUsHeadline: "Quienes Somos",
  aboutUsSubCopy:
    "Sevelyn es una plataforma digital que brinda servicios de bienestar emocional. Nuestro objetivo es brindarte una experiencia de bienestar mental que se adapte a ti - ya sea que hables con uno de nuestros especialistas, desarrolles nuevas habilidades a través de servicios de auto-ayuda, o aprendas e interactúes con nuestra comunidad y servicios de peer to peer que atiende tus necesidades únicas. Atender a tu bienestar mental y tus preferencias es nuestra única prioridad.",
  ourMissionHeadline: "Nuestra Misión",
  ourMissionSubCopy:
    "Nuestra misión es reducir la carencia de bienestar emocional que existe en los latinos hoy en día, brindando acceso a profesionales de la salud mental que comprenden la cultura, el idioma, las creencias y los desafíos diarios de la vida. De esta manera eliminaremos las brechas de inaccesibilidad que existen en esta área y los estigmas asociados a la búsqueda de asesoramiento mental.",
  ourPromiseHeadline: "Nuestra Promesa",
  affordability: "Asequibilidad",
  safetyAndConfidentiality: "Seguridad y Confidencialidad",
  convenience: "Comodidad",
  qualityFirst: "Atención de Calidad",
  cultureFirst: "Cultura Primero",
};
