export default {
  example: "example",
  bookFreeSessionNow: "Book Free Session Now",
  careSeekers: "Care Seekers",
  providers: "Providers",
  aboutUs: "About",
  blog: "Blog",
  contact: "Contact",
  dashboard: "Dashboard",
  emotionalWellBeingForTheLatinoCommunity:
    "Emotional Well-being for the Latino Community",
  benefitsOfOurPlatform:
    "Connecting Latinos with the education they need and professionals who understand their culture, values and needs",
  howItWorks: "How Does Sevelyn Work?",
  tellUsAboutYourself:
    "Create an account and tell us a bit your yourself and preferences",
  getMatched: "Pick a date and time of your choice",
  startTherapy: "Choose a provider from a selection of recommended matches.",
  benefitsOfOurPlatformHeadline: "Benefits of Our Platform",
  affordableHeadline: "Affordable",
  affordableBody: "Our rates are 1/4 of the traditional rates.",
  qualityCareHeadline: "Quality Care",
  qualityCareBody:
    "Our team of coaches are experienced and licensed in their home country in various fields of mental wellness",
  cultureFirstHeadline: "Culture First",
  cultureFirstBody:
    "We understand your culture and language because we are latinos just like you!",
  convenienceHeadline: "Convenience",
  convenienceBody:
    "Eliminate travel time, scheduling hassles or having to find a baby sitter. Connect with us from wherever you’d like!",
  safetyAndConfidentialityHeadline: "Safety and Confidentiality",
  safetyAndConfidentialityBody:
    "Our platform and tools are fully encrypted and managed with safety, security and anonymity in mind.",
  lifeThreateningSituation:
    "If you are in a life threatening situation - don't use this site. Call +1 (800) 273-8255 or use these resources to get immediate help.",
  switchToSpanish: "Español",
  privacyPolicy: "Privacy Policy",
  logIn: "Log In",
  getStarted: "Get Started",
  comingSoon: "Coming Soon",
  gender: "Gender",
  specialties: "Specialties",
  certifications: "Certifications",
  yearsOfExperience: "Years of Experience",
  passwordReset: "Password Reset",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  registerNow: "Register Now",
  registerForTeletherapy:
    "Register for teletherapy to start your journey to total wellness.",
  thankYouForStartingYourWellnessJourney:
    "Thank you for starting your wellness journey with Sevelyn. The registration process will take less than two minutes to complete.",
  whatRoleAreYouSeeking: "What role are you seeking?",
  client: "Client",
  admin: "Admin",
  forgotPassword: "Forgot Password?",
  password: "Password",
  email: "Email",
  hello: "Hello",
  therapists: "Therapists",
  appointments: "Appointments",
  male: "Male",
  female: "Female",
  bookNow: "Book Now",
  years: "years",
  year: "Year",
  back: "Back",
  submit: "Submit",
  save: "Save",
  select: "Select",
  areaOfSpecialty: "Area of Specialty",
  yes: "Yes",
  no: "No",
  dontHaveAnAccount: "Don't have an account?",
  whatDoYouSpecializeIn: "What do you specialize in?",
  preferNotToSay: "Prefer not to say",
};
