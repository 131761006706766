import { useHistory } from "react-router";
import { useEffect } from "react";
import { Routes } from "../Routes";

export const SignOut = () => {
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("token");
    history.push(Routes.SIGN_IN);
  });

  return null;
};
