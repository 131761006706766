const specialtiesScope = "specialties";

export default {
  addictionCoping: `${specialtiesScope}.addictionCoping`,
  adhd: `${specialtiesScope}.adhd`,
  anxiety: `${specialtiesScope}.anxiety`,
  stress: `${specialtiesScope}.stress`,
  bipolarDisorder: `${specialtiesScope}.bipolarDisorder`,
  couplesTherapy: `${specialtiesScope}.couplesTherapy`,
  depression: `${specialtiesScope}.depression`,
  angerManagement: `${specialtiesScope}.angerManagement`,
  familyConflicts: `${specialtiesScope}.familyConflicts`,
  grief: `${specialtiesScope}.grief`,
  relationships: `${specialtiesScope}.relationships`,
  parentingCoaching: `${specialtiesScope}.parentingCoaching`,
  sleepingDisorders: `${specialtiesScope}.sleepingDisorders`,
  intimacyIssues: `${specialtiesScope}.intimacyIssues`,
  selfEsteem: `${specialtiesScope}.selfEsteem`,
};
