import logo from "../logo.png";
import { useHistory } from "react-router";
import { Routes } from "../Routes";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Container from "./Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { NavLink as RBNavLink } from "react-bootstrap";
import TopNavMobileSpacer from "./Navbar/TopNavMobileSpacer";
import StyledNavbar from "./Navbar/StyledNavbar";
import DesktopNavDrawer from "./Navbar/DesktopNavDrawer";
import MobileNavDrawer from "./Navbar/MobileNavDrawer";
import styled from "styled-components";
import { device } from "../utilities/responsive";

const StyledLogo = styled.img`
  @media ${device.lg} {
    margin-right: 30px;
  }
`;

export const Navbar = ({ containerStyle, navItemsStyle, children }) => {
  const history = useHistory();
  const [navHeight, setNavHeight] = useState(0);
  const [mobileNav, setMobileNav] = useState(false);

  const toggleMobileNav = () => setMobileNav(!mobileNav);

  useEffect(() => {
    const topnav = document.getElementById("topnav");
    setNavHeight(topnav.clientHeight);
  }, [document]);

  //TODO: Make logo bigger
  return [
    <StyledNavbar id={"topnav"} showCollapse={mobileNav}>
      <Container style={{ justifyContent: "space-between", ...containerStyle }}>
        <div style={{ ...navItemsStyle }} className={"topnav-main-navigation"}>
          <NavLink
            to={
              localStorage.getItem(
                "token"
              ) /*TODO: Also include if we're on any of the dashboard routes*/
                ? Routes.DASHBOARD
                : Routes.HOME
            }
            style={{ flex: 0 }}
          >
            <StyledLogo
              src={logo}
              className={"Logo"}
              style={{ maxWidth: 200 }}
              onClick={() => history.push(Routes.HOME)}
            />
          </NavLink>
          {children && (
            <RBNavLink
              className={"menu-drawer-toggle"}
              onClick={toggleMobileNav}
            >
              <FontAwesomeIcon
                icon={faBars}
                size={"2x"}
                color={"rgb(254, 101, 81)"}
              />
            </RBNavLink>
          )}
        </div>
        <DesktopNavDrawer>{children}</DesktopNavDrawer>
      </Container>
    </StyledNavbar>,
    <TopNavMobileSpacer
      style={{ height: navHeight }}
      className={"topnav-mobile-spacer"}
    />,
    <MobileNavDrawer showCollapse={mobileNav}>{children}</MobileNavDrawer>,
  ];
};
