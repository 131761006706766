export default {
  wellnessYouDeserve: "Wellness You Deserve",
  wellnessYouDeserveBody:
    "Sevelyn is a digital platform providing emotional wellness services. Our focus is to provide you the wellness journey that works best for you - whether you talk with one of our specialist, build new skills through our user-guided pathways or learn and engage with our peer-to-peer approach, catering to your unique situation and preference is our sole priority. mental well-being.",
  affordableBody: "Our rates are 1/4 of the traditional rates",
  qualityCareBody:
    "We offer licensed providers with minimum 3 years of experience in their respective field",
  cultureFirstBody:
    "Our providers fully understand your language and culture, they’re latinos just like you!",
  convenienceBody:
    "Eliminate travel time, scheduling hassles or having to find a baby sitter. Connect with us from wherever you’d like!",
  safetyAndConfidentialityBody:
    "Our platform and tools are fully encrypted and managed with safety, security and anonymity in mind.",
  podcast: "Podcast",
  ourPodcastDiscusses:
    "Our Podcast discusses emotional wellness related topics important for the latino community.",
  sevelynMobileApp: "Sevelyn Mobile App",
  whatMakesUsUnique: "What Makes Us Unique",
};
