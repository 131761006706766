import styled from "styled-components";
import { mediaQueries } from "../mixins";

const Button = styled.div`
  padding: 12px 13px;
  border-radius: 8px;
  background-color: #fe6551;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    transition: 0.5ms filter;
    filter: brightness(0.9);
  }
  ${({ disabled }) =>
    disabled ? { pointerEvents: "none", filter: "brightness(80%)" } : {}};
  ${({ selected }) =>
    selected ? { pointerEvents: "none", filter: "brightness(80%)" } : {}};
  ${({ clickable }) => (clickable ? { pointerEvents: "auto" } : {})};
  ${({ style }) => ({ ...style })};
  ${(props) => mediaQueries(props)}
`;

const ButtonText = styled.span`
  display: flex;
  justify-content: center;
  font-family: ${({ theme: { fonts } }) => fonts.martel};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.05px;
  text-align: center;
  color: #fff;

  ${({ style }) => ({ ...style })};
`;

export default { Button, ButtonText };
