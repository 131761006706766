import axios from "axios";
import { toast } from "react-hot-toast";
import { dict, localize } from "../i18n";

export const requestPasswordReset = async (email) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/forgot-password`, {
      email,
    });

    return toast.success(localize(dict.errors.aPasswordResetLinkHasBeenSent));
  } catch (err) {
    if (err.response) {
      console.error("error", err.response.data.message);
      return toast.error(`Error: ${err.response.data.message}`);
    }
  }
};
