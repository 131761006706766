import { Card } from "./Card";
import { StyledButton } from "../../components";
import React from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

const CancellationRequestCard = ({ showCancellation }) => {
  const doRefund = async () => {
    try {
      const refundResponse = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/api/admin/refund`,
        data: { appointmentId: showCancellation._id },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return toast.success("Successfully refunded client.");
    } catch (err) {
      if (err.response) {
        console.error("error", err.response.data.message);
        return toast.error(`Error: ${err.response.data.message}`);
      }
    }
  };

  const doDecline = async () => {
    try {
      const declineResponse = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/api/admin/decline`,
        data: { appointmentId: showCancellation._id },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return toast.success("Successfully declined client.");
    } catch (err) {
      if (err.response) {
        console.error("error", err.response.data.message);
        return toast.error(`Error: ${err.response.data.message}`);
      }
    }
  };

  return showCancellation ? (
    <Card style={{ overflow: "scroll", rowGap: 20 }}>
      <div style={{ display: "flex", flexDirection: "column", rowGap: 30 }}>
        <div>
          <div style={{ fontWeight: "bold" }}>Cancellation Request Date</div>
          <span>
            {new Date(showCancellation.requestCancellationDate).toString() ||
              "No Date Available. Please contact developer."}
          </span>
        </div>
        <div>
          <div style={{ fontWeight: "bold" }}>Client</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {showCancellation.client.firstName}{" "}
              {showCancellation.client.lastName}
            </span>
            <span>{showCancellation.client.email}</span>
          </div>
        </div>
        <div>
          <div style={{ fontWeight: "bold" }}>Therapist</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {showCancellation.therapist.firstName}{" "}
              {showCancellation.therapist.lastName}
            </span>
            <span>{showCancellation.therapist.email}</span>
          </div>
        </div>
        <div>
          <div style={{ fontWeight: "bold" }}>Meeting Link</div>
          <a href={showCancellation.jitsiLink}>{showCancellation.jitsiLink}</a>
        </div>
      </div>
      <div style={{ display: "flex", columnGap: 30 }}>
        <StyledButton.Button
          style={{ maxWidth: 150, marginTop: 50 }}
          onClick={doRefund}
        >
          <StyledButton.ButtonText>Accept/Refund</StyledButton.ButtonText>
        </StyledButton.Button>
        <StyledButton.Button
          style={{ maxWidth: 150, marginTop: 50 }}
          onClick={doDecline}
        >
          <StyledButton.ButtonText>Decline Refund</StyledButton.ButtonText>
        </StyledButton.Button>
      </div>
    </Card>
  ) : null;
};

export default CancellationRequestCard;
