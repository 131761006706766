import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
`;

const Input = styled.input`
  width: 100%;
  padding: 9px;
  height: 48px;
  font-family: ${({ theme: { fonts } }) => fonts.martel};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: -0.23px;
  color: #9d9d9d;
  -webkit-appearance: none;

  &:focus {
    ${({ errors }) => (errors ? { borderColor: "red" } : {})}
  }

  ${({ errors }) => (errors ? { borderColor: "red" } : {})}
  ${(props) => ({ ...props.style })}
`;

export default { Container, Input };
