import styled from "styled-components";
import { device } from "../../utilities/responsive";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  @media ${device.md} {
    max-width: 720px;
  }

  @media ${device.xl} {
    max-width: 1024px;
    flex-direction: row;
  }

  @media ${device.xxl} {
    max-width: 1400px;
  }

  ${({ style }) => ({ ...style })};
`;

export default { Container };
