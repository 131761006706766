import { StyledButton, StyledSection } from "../../../components";
import * as React from "react";
import styled from "styled-components";
import Container from "../../../components/Container";
import { NavLink } from "react-router-dom";
import { Routes } from "../../../Routes";
import { device } from "../../../utilities/responsive";
import { dict, localize } from "../../../i18n";
import CTAImage from "../../../pics/cta-image.png";

const CTA = styled.div`
  color: #3b3b3b;
  font-family: "Cantata One", serif;
  font-size: 40px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 50px;

  @media ${device.lg} {
    justify-content: space-around;
    padding: 0;
  }
`;

const StyledImg = styled.img`
  position: absolute;
  bottom: -75px;
  max-width: 600px;
  z-index: -1;
  opacity: 0.3;
  filter: brightness(80%) blur(0.1px);

  @media ${device.lg} {
    opacity: 1;
    filter: brightness(100%) blur(0);
  }
`;

export const CTASection = () => {
  return (
    <StyledSection.Section style={{ padding: "75px 0" }}>
      <Container>
        <StyledDiv>
          <CTA>
            {localize(dict.app.emotionalWellBeingForTheLatinoCommunity)}
          </CTA>
          <p>{localize(dict.app.benefitsOfOurPlatform)}</p>
          <NavLink to={Routes.SIGN_IN}>
            <StyledButton.Button md={{ width: "100%", maxWidth: 300 }}>
              <StyledButton.ButtonText>
                {localize(dict.app.bookFreeSessionNow)}
              </StyledButton.ButtonText>
            </StyledButton.Button>
          </NavLink>
        </StyledDiv>
        <div style={{ display: "flex", flex: 1, position: "relative" }}>
          <StyledImg src={CTAImage} alt="" />
        </div>
      </Container>
    </StyledSection.Section>
  );
};
