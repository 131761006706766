export default {
  myTherapists: "My Therapists",
  allTherapists: "All Therapists",
  allSpecialties: "All Specialties",
  search: "Search",
  filters: "Filters",
  seeMore: "See More",
  credentials: "Credentials",
  therapistProfile: "Therapist Profile",
  profileImage: "Profile Image",
  uploadImage: "Upload Image",
  calendlyUsername: "Calendly Username",
  facebookUrl: "Facebook URL",
  linkedInUrl: "LinkedIn URL",
  instagramUrl: "Instagram URL",
  twitterUrl: "Twitter URL",
  media: "Media",
  clientProfile: "Client Profile",
  listYourCertifications: "List your certifications",
};
