export default {
  contactUsHeadline: "Contáctenos",
  contactUsSubCopy:
    "¿Tienes alguna pregunta sobre nosotros, nuestros servicios o nuestra red de especialistas? ¡Permítenos Ayudarte!",
  firstName: "Nombre",
  lastName: "Apellido",
  email: "Email",
  confirmEmail: "Confirma tu email",
  howCanWeHelpYou: "¿Como podemos ayudarte?",
};
