import i18next from "i18next";
import Moment from "moment";
import { es } from "./t9n/es";
import { en } from "./t9n/en";
import app from "./scopes/i18n.scope.app";
import pricing from "./scopes/i18n.scope.pricing";
import guaranteed from "./scopes/i18n.scope.guaranteed";
import careSeekers from "./scopes/i18n.scope.careSeekers";
import providers from "./scopes/i18n.scope.providers";
import aboutUs from "./scopes/i18n.scope.aboutUs";
import contactUs from "./scopes/i18n.scope.contactUs";
import dashboard from "./scopes/i18n.scope.dashboard";
import therapist from "./scopes/i18n.scope.therapist";
import appointments from "./scopes/i18n.scope.appointments";
import registration from "./scopes/i18n.scope.registration";
import specialties from "./scopes/i18n.scope.specialties";
import errors from "./scopes/i18n.scope.errors";
import LanguageDetector from "i18next-browser-languagedetector";

const lng = localStorage.getItem("lng") || navigator.language;

const newInstance = i18next.use(LanguageDetector).createInstance(
  {
    lng: lng || "es",
    fallbackLng: "es",
    debug: false,
    resources: {
      es: {
        translation: es,
      },
      en: {
        translation: en,
      },
    },
    interpolation: {
      format: function (value, format) {
        if (value instanceof Date || Moment.isMoment(value)) {
          return new Moment(value).format(format);
        }
        return value;
      },
      escapeValue: false,
    },
  },
  (err, t) => {
    if (err) {
      console.error(err);
    }
  }
);

export const dict = {
  app,
  pricing,
  guaranteed,
  careSeekers,
  providers,
  aboutUs,
  contactUs,
  dashboard,
  therapist,
  appointments,
  registration,
  specialties,
  errors,
};

export const localize = (key, keys) => newInstance.t(key, keys);
export const i18n = newInstance;
