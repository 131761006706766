import styled from "styled-components";

const Form = styled.form`
  max-width: 320px;
`;

const SubText = styled.span`
  color: ${({ theme: { colors } }) => colors.darkGrey};
  font-family: ${({ theme: { fonts } }) => fonts.martel};
`;

export default { Form, SubText };
