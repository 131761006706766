import React, { useEffect, useRef, useState } from "react";
import "./Appointments.css";
import styled from "styled-components";
import { StyledContainer } from "../../components";
import { device } from "../../utilities/responsive";
import jwt_decode from "jwt-decode";
import axios from "axios";
import moment from "moment";
import Subscription from "./components/Subscription";
import { createVideoConference } from "./components/VideoConference";
import { useHistory } from "react-router";
import { Routes } from "../../Routes";
import { dict, localize } from "../../i18n";
import { toast } from "react-hot-toast";

const AppointmentHeading = styled.h2`
  font-family: "Cantata One", serif;
  font-weight: normal;
  margin-bottom: 10px;
`;

const Appointment = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AppointmentDetails = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    font-family: "Martel Sans", sans-serif;
    font-size: 14px;
    color: #4c4c4c;
    font-weight: 600;
    margin: 0;
  }

  span {
    color: ${({ theme: { colors } }) => colors.lightGrey};
  }
`;

const AppointmentActions = styled.div`
  font-family: ${({ theme: { fonts } }) => fonts.martel};
  font-weight: bold;
  font-size: 14px;
  color: #ff5a56;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  column-gap: 20px;
  flex-wrap: wrap;
  padding: 30px 10px 10px;
  width: 100%;
  min-height: 100vh;

  @media ${device.lg} {
    flex-direction: row;
  }
`;

const Card = styled.div`
  display: flex;
  flex: 0 1 calc(50% - 2 * 10px);
  position: relative;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 rgba(173, 173, 173, 0.5);
  background-color: #fff;
  align-self: flex-start;
  padding: 50px;
  width: 100%;
`;

const StyledContainerContainer = styled(StyledContainer.Container)`
  padding: 50px;
  margin: 0 auto;

  @media ${device.lg} {
    padding: 0;
  }
`;

const StyledGrid = styled(Grid)`
  justify-content: flex-start;

  .disclaimer {
    font-size: 11px;
    line-height: 0.5px;
    margin-top: 10px;
  }

  @media ${device.lg} {
    justify-content: center;
  }
`;

export const Appointments = ({ setModal }) => {
  const history = useHistory();
  const [booked, setBooked] = useState(false);
  const upgradePlanButtonRef = useRef();
  const [upcomingAppointments, setUpcomingAppointments] = useState(null);
  const [pastAppointments, setPastAppointments] = useState(null);
  const [jitsiLink, setJitsiLink] = useState(null);
  const [videoConference, setVideoConference] = useState(null);
  const user = jwt_decode(localStorage.getItem("token"));
  const currentTime = new Date().toISOString();
  const bearerToken = `Bearer ${localStorage.getItem("token")}`;
  const isTherapist = user.roles.includes("ROLE_THERAPIST");

  const sendRequestCancellation = async (appointment) => {
    try {
      const cancelRequest = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/appointments/request`,
        headers: {
          Authorization: bearerToken,
        },
        data: {
          appointmentId: appointment._id,
        },
      });

      return await fetchAppointments();
      // return toast.success("Successfully requested appointment cancellation.");
    } catch (err) {
      if (err.response) {
        return toast.error(`Error: ${err.response.data.message}`);
      }
      return toast.error(`Error: ${err}`);
    }
  };

  const sendConfirmCancellation = async (appointment) => {
    try {
      const confirmRequest = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/appointments/accept`,
        headers: {
          Authorization: bearerToken,
        },
        data: {
          appointmentId: appointment._id,
        },
      });
      return await fetchAppointments();
      // return toast.success("Successfully confirmed cancellation.");
    } catch (err) {
      if (err.response) {
        return toast.error(`Error: ${err.response.data.message}`);
      }
      return toast.error(`Error: ${err}`);
    }
  };

  useEffect(() => {
    if (history.location?.state?.booked) {
      setBooked(true);
    }
  }, [history]);

  useEffect(async () => {
    if (booked) {
      window.scrollTo(0, 0);
      new Promise((resolve) =>
        setTimeout(() => {
          history.replace(Routes.APPOINTMENTS, { booked: false });
          setBooked(false);
          resolve();
        }, 5000)
      );
    }
  }, [booked]);

  const fetchAppointments = async () => {
    if (isTherapist) {
      const appointments = { upcoming: [], past: [] };
      try {
        const fetchedAppointments = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/api/appointments/therapist/${user.id}`,
          headers: {
            Authorization: bearerToken,
          },
        });

        await fetchedAppointments.data.map((appointment, idx) => {
          if (currentTime > appointment.calendlyAppointment.endTime) {
            appointments.past = appointments.past.concat([appointment]);
          } else {
            appointments.upcoming = appointments.upcoming.concat([appointment]);
          }
        });
        setUpcomingAppointments(appointments.upcoming);
        setPastAppointments(appointments.past);
      } catch (err) {
        if (err.response) {
          console.error("error", err.response.data.message);
          return toast.error(`Error: ${err.response.data.message}`);
        }
      }
    } else {
      const appointments = { upcoming: [], past: [] };
      try {
        const fetchedAppointments = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/api/appointments/client/${user.id}`,
          headers: {
            Authorization: bearerToken,
          },
        });

        await fetchedAppointments.data.map((appointment, idx) => {
          if (!appointment.calendlyAppointment) return null;
          if (currentTime > appointment.calendlyAppointment.endTime) {
            appointments.past = appointments.past.concat([appointment]);
          } else {
            appointments.upcoming = appointments.upcoming.concat([appointment]);
          }
        });
        setUpcomingAppointments(appointments.upcoming);
        setPastAppointments(appointments.past);
      } catch (err) {
        if (err.response) {
          console.error("error", err.response.data.message);
          return toast.error(`Error: ${err.response.data.message}`);
        }
      }
    }
  };

  useEffect(async () => {
    if (history.location?.state?.displayModal) {
      upgradePlanButtonRef.current?.click();
      history.replace(Routes.APPOINTMENTS, { displayModal: false });
    }

    await fetchAppointments();
  }, []);

  useEffect(() => {
    if (jitsiLink) {
      const newVideoConference = createVideoConference({
        roomName: jitsiLink?.slice(
          jitsiLink.indexOf("https://meet.jit.si/") +
            "https://meet.jit.si/".length
        ),
        close: () => {
          setJitsiLink(null);
          setVideoConference(null);
        },
      });

      setVideoConference(newVideoConference);
    }
  }, [jitsiLink]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#f3f3f3",
        flexDirection: "column",
      }}
    >
      <StyledContainerContainer>
        <StyledGrid>
          {videoConference ? videoConference.renderComponent() : null}
          <Card>
            {booked && (
              <div
                style={{
                  border: "1px solid green",
                  backgroundColor: "green",
                  color: "white",
                  padding: "10px 20px",
                  display: "flex",
                  width: "100%",
                  borderRadius: 8,
                  marginBottom: 20,
                }}
              >
                {localize(dict.appointments.successfullyBookedAppointment)}
              </div>
            )}
            <div
              className={"upcoming-appointments"}
              style={{ borderBottom: "1px solid #ebebeb" }}
            >
              <AppointmentHeading>
                {localize(dict.appointments.upcomingAppointments)}
              </AppointmentHeading>
              <span className={"disclaimer"}>
                {localize(dict.appointments.disclaimer)}
              </span>
              {upcomingAppointments && (
                <div style={{ marginTop: 50 }}>
                  {upcomingAppointments.length ? (
                    upcomingAppointments.map((appointment) => {
                      const { requestCancellation } = appointment;
                      return (
                        <Appointment key={appointment.id}>
                          <AppointmentDetails>
                            <h5>{appointment.calendlyAppointment.name}</h5>
                            <span>
                              {moment(
                                appointment.calendlyAppointment.startTime
                              ).format("MMMM Do, YYYY")}
                            </span>
                            <span>
                              {moment(
                                appointment.calendlyAppointment.startTime
                              ).format("h:mm A")}{" "}
                              -{" "}
                              {moment(
                                appointment.calendlyAppointment.endTime
                              ).format("h:mm A")}
                            </span>
                            {appointment.therapist?.firstName &&
                              appointment.therapist?.lastName && (
                                <span>
                                  {localize(dict.appointments.with)}{" "}
                                  {appointment.therapist.firstName}{" "}
                                  {appointment.therapist.lastName}
                                </span>
                              )}
                            {isTherapist &&
                              appointment.client?.firstName &&
                              appointment.client?.lastName && (
                                <span>
                                  {localize(dict.appointments.with)}{" "}
                                  {appointment.client.firstName}{" "}
                                  {appointment.client.lastName}
                                </span>
                              )}
                          </AppointmentDetails>
                          {!videoConference && (
                            <AppointmentActions
                              className={"actions"}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 10,
                              }}
                            >
                              {!requestCancellation && !appointment.cancelled && (
                                <React.Fragment>
                                  <span
                                    onClick={() => {
                                      if (jitsiLink !== appointment.jitsiLink) {
                                        setJitsiLink(appointment.jitsiLink);
                                      }

                                      if (videoConference)
                                        return videoConference.dispose();
                                    }}
                                  >
                                    {localize(dict.appointments.joinMeeting)}
                                  </span>
                                  <span
                                    onClick={() =>
                                      sendRequestCancellation(appointment)
                                    }
                                  >
                                    {localize(
                                      dict.appointments.requestCancellation
                                    )}
                                  </span>
                                </React.Fragment>
                              )}
                              {requestCancellation &&
                                !appointment.cancelled &&
                                isTherapist && (
                                  <span
                                    onClick={() =>
                                      sendConfirmCancellation(appointment)
                                    }
                                  >
                                    {localize(
                                      dict.appointments.confirmCancellation
                                    )}
                                  </span>
                                )}
                              {requestCancellation &&
                                !appointment.cancelled &&
                                !isTherapist && (
                                  <span>
                                    {localize(
                                      dict.appointments.awaitingCancellation
                                    )}
                                  </span>
                                )}
                              {appointment.cancelled && (
                                <span>
                                  {localize(dict.appointments.cancelled)}
                                </span>
                              )}
                            </AppointmentActions>
                          )}
                        </Appointment>
                      );
                    })
                  ) : (
                    <Appointment>
                      <AppointmentDetails>
                        {localize(dict.appointments.noAppointments)}
                      </AppointmentDetails>
                    </Appointment>
                  )}
                </div>
              )}
            </div>
            {pastAppointments && (
              <div className={"past-appointments"}>
                <h2
                  style={{
                    fontFamily: "Cantata One",
                    fontWeight: "normal",
                    margin: 0,
                    marginBottom: 30,
                  }}
                >
                  {localize(dict.appointments.pastAppointments)}
                </h2>
                <React.Fragment>
                  {pastAppointments.length ? (
                    pastAppointments.map((appointment) => {
                      if (
                        !appointment.calendlyAppointment ||
                        currentTime < appointment.calendlyAppointment?.startTime
                      )
                        return null;

                      return (
                        <Appointment key={appointment.id}>
                          <AppointmentDetails>
                            <h5>{appointment.calendlyAppointment.name}</h5>
                            <span>
                              {moment(
                                appointment.calendlyAppointment.startTime
                              ).format("MMMM Do, YYYY")}
                            </span>
                            <span>
                              {moment(
                                appointment.calendlyAppointment.startTime
                              ).format("h:mm A")}{" "}
                              -{" "}
                              {moment(
                                appointment.calendlyAppointment.endTime
                              ).format("h:mm A")}
                            </span>
                          </AppointmentDetails>
                        </Appointment>
                      );
                    })
                  ) : (
                    <Appointment>
                      <AppointmentDetails>
                        {localize(dict.appointments.noAppointments)}
                      </AppointmentDetails>
                    </Appointment>
                  )}
                </React.Fragment>
              </div>
            )}
          </Card>
          {!isTherapist && (
            <Subscription
              upgradePlanButtonRef={upgradePlanButtonRef}
              setModal={setModal}
            />
          )}
        </StyledGrid>
      </StyledContainerContainer>
    </div>
  );
};
