export const Routes = {
  SIGN_IN: "/signin",
  SIGN_OUT: "/signout",
  REGISTRATION: "/registration",
  HOME: "/",
  DASHBOARD: "/dashboard",
  CARE_SEEKERS: "/care-seekers",
  PROVIDERS: "/providers",
  THERAPISTS: "/dashboard/therapists",
  APPOINTMENTS: "/dashboard/appointments",
  SETTINGS: "/dashboard/settings",
  EMOTIONAL_WELLNESS: "/emotional-wellness",
  ABOUT_US: "/about-us",
  BLOG: "/blog",
  BLOG_POST: "/blog/:slug",
  CONTACT: "/contact",
  ADMIN: "/dashboard/admin",
};
