import styled from "styled-components";
import { device } from "../../utilities/responsive";

const TopNavMobileSpacer = styled.div`
  display: flex;

  @media ${device.lg} {
    display: none;
  }
`;

export default TopNavMobileSpacer;
