import { StyledButton, StyledInput, StyledLabel } from "../../../components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { toast } from "react-hot-toast";
import { localize, dict } from "../../../i18n/index";

const Group = styled(StyledLabel.Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;

  span {
    margin-bottom: 5px;
    font-size: 12px;
    color: #2b2b2b;
    letter-spacing: 0.7px;
    font-family: "Martel Sans", sans-serif;
  }

  ${({ style }) => ({ ...style })};
`;

const InputGroup = ({ style, children }) => (
  <Group style={{ ...style, maxWidth: 320, width: "100%" }}>{children}</Group>
);

export const Profile = () => {
  const userId = jwt_decode(localStorage.getItem("token"))?.id;
  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    const userRes = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/users/${userId}`,
      {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "cache-control": "no-cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return userRes;
  };

  useEffect(async () => {
    const userRes = await fetchUser();
    setUser(userRes.data);
  }, []);

  return user ? (
    <Formik
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      }}
      validate={(values) => {
        const errors = {};

        if (!values.firstName) {
          errors.firstName = localize(dict.errors.required);
        }

        if (!values.lastName) {
          errors.lastName = localize(dict.errors.required);
        }

        if (!values.email) {
          errors.email = localize(dict.errors.required);
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = localize(dict.errors.invalidEmailAddress);
        }

        if (!values.phoneNumber) {
          errors.phoneNumber = localize(dict.errors.required);
        }

        return errors;
      }}
      onSubmit={async (formValues, actions) => {
        const props = {
          ...formValues,
        };

        try {
          const updatedUser = await axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}/api/users/${userId}/profile`,
            data: { ...props },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          toast.success(localize(dict.errors.successfullyUpdated));
          const userRes = await fetchUser();
          setUser(userRes.data);
        } catch (err) {
          console.error("error", err);
          toast.error("Error while updating user.");
        }

        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 10,
            marginTop: 75,
            marginBottom: 75,
          }}
        >
          <div style={{ display: "flex", columnGap: 60 }}>
            <InputGroup>
              <span>{localize(dict.registration.firstName)} *</span>
              <StyledInput.Input
                type={"text"}
                name={"firstName"}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.firstName && touched.firstName && errors.firstName}
            </InputGroup>
            <InputGroup>
              <span>{localize(dict.registration.lastName)} *</span>
              <StyledInput.Input
                type={"text"}
                name={"lastName"}
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.lastName && touched.lastName && errors.lastName}
            </InputGroup>
          </div>
          <div style={{ display: "flex", columnGap: 60 }}>
            <InputGroup>
              <span>{localize(dict.registration.email)} *</span>
              <StyledInput.Input
                type={"text"}
                name={"email"}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email && errors.email}
            </InputGroup>
            <InputGroup>
              <span>{localize(dict.registration.cellPhoneNumber)} *</span>
              <StyledInput.Input
                type={"text"}
                name={"phoneNumber"}
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
            </InputGroup>
          </div>
          <StyledButton.Button
            style={{ maxWidth: 150, marginTop: 50 }}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            <StyledButton.ButtonText>
              {localize(dict.app.save)}
            </StyledButton.ButtonText>
          </StyledButton.Button>
        </form>
      )}
    </Formik>
  ) : null;
};
