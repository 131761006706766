export default {
  pricing: "Pricing",
  freeFirstSession: "First Free Session",
  freeFirstSessionPrice: "$0",
  fourtyFiveMinutes: "45 Minutes",
  fourtyFiveMinutesEach: "45 Minutes Each",
  individualSession: "Individual Session",
  fifty: "$50",
  valueOfFifty: "(Value of $50)",
  packetOfFourSessions: "Packet of 4 Sessions",
  oneHundredEighty: "$180",
  savingsOfTwenty: "(Savings of $20)",
  packetOfTenSessions: "Packet of 10 Sessions",
  fourHundred: "$400",
  savingsOfOneHundred: "(Savings of $100)",
  sixty: "$60",
  couplesSession: "Couples Session",
};
