const theme = {
  fonts: {
    martel: "Martel Sans, sans-serif",
    cantata: "Cantata One, serif",
  },
  colors: {
    primary: "#fe6551",
    lightGrey: "#757575",
    darkGrey: "#2b2b2b",
  },
};

export default theme;
