import styled from "styled-components";

const Link = styled.a`
  color: ${({ theme: { colors } }) => colors.primary};
  ${({ style }) => ({ ...style })};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export default { Link };
