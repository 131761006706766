import axios from "axios";
import { toast } from "react-hot-toast";
import jwt_decode from "jwt-decode";

const fetchFavorites = async (setFavorites) => {
  const token = localStorage.getItem("token");
  const user = jwt_decode(token);

  try {
    const favoritesRes = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/favorites/user/${user.id}`,
      {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "cache-control": "no-cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    setFavorites(favoritesRes.data);
  } catch (err) {
    if (err.response) {
      console.error("error", err.response.data.message);
      return toast.error(`Error: ${err.response.data.message}`);
    }
  }
};

export default fetchFavorites;
