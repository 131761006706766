import styled from "styled-components";
import { mediaQueries } from "./mixins";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  ${(props) => mediaQueries(props)}
  ${({ style }) => ({ ...style })};
`;

const Container = (props) => (
  <StyledContainer
    {...props}
    base={{ maxWidth: "100%" }}
    md={{ maxWidth: "720px" }}
    lg={{ flexDirection: "row", maxWidth: "960px" }}
    xl={{ maxWidth: "960px" }}
  >
    {props.children}
  </StyledContainer>
);

export default Container;
