import { ProgressBar } from "../ProgressBar";
import React from "react";
import { Formik } from "formik";
import { StyledButton, StyledInput, StyledLabel } from "../../../components";
import styled from "styled-components";
import ErrorMessage from "../../../components/ErrorMessage";
import { localize, dict } from "../../../i18n/index";

const Group = styled(StyledLabel.Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;

  span {
    margin-bottom: 5px;
    font-size: 12px;
    color: #2b2b2b;
    letter-spacing: 0.7px;
    font-family: "Martel Sans", sans-serif;
  }

  ${({ style }) => ({ ...style })};
`;

const InputGroup = ({ style, children }) => (
  <Group style={{ ...style, maxWidth: 320, width: "100%" }}>{children}</Group>
);

export const ThirdProviderRegistration = (props) => {
  const { setThirdData, goPrevious, goNext } = props;

  return (
    <React.Fragment>
      <ProgressBar percent={100} phase={3} phases={3} />
      <Formik
        initialValues={{
          whatsapp: "",
          facebook: "",
          instagram: "",
          twitter: "",
          linkedin: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.whatsapp) {
            errors.whatsapp = localize(dict.errors.required);
          }

          return errors;
        }}
        onSubmit={async (formProps, actions) => {
          await setThirdData(formProps);
          await goNext(formProps);
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <React.Fragment>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 10,
                marginTop: 75,
                marginBottom: 75,
              }}
            >
              <div style={{ display: "flex", columnGap: 60 }}>
                <InputGroup>
                  <span>{localize(dict.providers.whatsAppNumber)} *</span>
                  <StyledInput.Input
                    type={"text"}
                    name={"whatsapp"}
                    value={values.whatsapp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.whatsapp && touched.whatsapp}
                  />
                  <ErrorMessage>
                    {errors.whatsapp && touched.whatsapp && errors.whatsapp}
                  </ErrorMessage>
                </InputGroup>
                <InputGroup>
                  <span>{localize(dict.dashboard.facebookUrl)}</span>
                  <StyledInput.Input
                    type={"text"}
                    name={"facebook"}
                    value={values.facebook}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.facebook && touched.facebook}
                  />
                  {errors.facebook && touched.facebook && errors.facebook}
                </InputGroup>
              </div>
              <div style={{ display: "flex", columnGap: 60 }}>
                <InputGroup>
                  <span>{localize(dict.dashboard.instagramUrl)}</span>
                  <StyledInput.Input
                    type={"text"}
                    name={"instagram"}
                    value={values.instagram}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.instagram && touched.instagram}
                  />
                  {errors.instagram && touched.instagram && errors.instagram}
                </InputGroup>
                <InputGroup>
                  <span>{localize(dict.dashboard.twitterUrl)}</span>
                  <StyledInput.Input
                    type={"text"}
                    name={"twitter"}
                    value={values.twitter}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.twitter && touched.twitter}
                  />
                  {errors.twitter && touched.twitter && errors.twitter}
                </InputGroup>
              </div>
              <div style={{ display: "flex", columnGap: 60 }}>
                <InputGroup>
                  <span>{localize(dict.dashboard.linkedInUrl)}</span>
                  <StyledInput.Input
                    type={"text"}
                    name={"linkedin"}
                    value={values.linkedin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.linkedin && touched.linkedin}
                  />
                  {errors.linkedin && touched.linkedin && errors.linkedin}
                </InputGroup>
              </div>
            </form>
            <div
              style={{
                display: "flex",
                maxWidth: 380,
                justifyContent: "space-between",
              }}
            >
              <StyledButton.Button
                style={{ maxWidth: 150, width: "100%" }}
                onClick={goPrevious}
              >
                <StyledButton.ButtonText>
                  {localize(dict.registration.back)}
                </StyledButton.ButtonText>
              </StyledButton.Button>
              <StyledButton.Button
                style={{ maxWidth: 150, width: "100%" }}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                <StyledButton.ButtonText>
                  {localize(dict.registration.done)}
                </StyledButton.ButtonText>
              </StyledButton.Button>
            </div>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
};
