import {
  StyledPill,
  StyledPillLeftOption,
  StyledPillRightOption,
} from "../../components/Pill/Pill.styled";
import { dict, localize } from "../../i18n";
import React, { useEffect, useState } from "react";

const MyTherapistsFilter = ({ column, values, favorites }) => {
  const [myTherapists, setMyTherapists] = useState(false);
  useEffect(() => {
    column.setFilter({ myTherapists, favorites });
  }, [column, favorites, myTherapists]);

  const handleToggle = () => {
    setMyTherapists(!myTherapists);
  };

  return (
    <StyledPill>
      <StyledPillLeftOption active={myTherapists} onClick={handleToggle}>
        {localize(dict.dashboard.myTherapists)}
      </StyledPillLeftOption>
      <StyledPillRightOption active={!myTherapists} onClick={handleToggle}>
        {localize(dict.dashboard.allTherapists)}
      </StyledPillRightOption>
    </StyledPill>
  );
};

export default MyTherapistsFilter;
