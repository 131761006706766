import { StyledButton, StyledLabel } from "../../../components";
import styled from "styled-components";
import { ProgressBar } from "../ProgressBar";
import React, { useState } from "react";
import { Formik } from "formik";
import CountrySelect from "../../../components/CountrySelect";
import { localize, dict } from "../../../i18n/index";

const ButtonGroup = styled.div`
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  max-width: 380px;

  > div {
    max-width: 150px;
    width: 100%;
    border: 1px solid #9d9d9d;
    background-color: white;

    span {
      color: #2b2b2b;
      font-family: "Martel Sans", sans-serif;
      font-weight: normal;
    }
  }
`;

const Label = styled(StyledLabel.Label)`
  h4 {
    margin-top: 0;
  }
`;

const Button = ({ children, onClick, selected }) => (
  <StyledButton.Button
    style={{ maxWidth: 150 }}
    onClick={onClick}
    selected={selected}
  >
    <StyledButton.ButtonText onClick={onClick}>
      {children}
    </StyledButton.ButtonText>
  </StyledButton.Button>
);

const H4 = styled.h4`
  color: #626262;
  font-family: "Cantata One", serif;
  font-size: 25px;
  font-weight: normal;
  margin: 0;
`;

export const ThirdRegistration = (props) => {
  const { setThirdData, goPrevious, goNext } = props;
  const [therapistGender, setTherapistGender] = useState(null);
  const [therapistLGBTQ, setTherapistLGBTQ] = useState(null);
  const [therapistOrientation, setTherapistOrientation] = useState(null);

  const getNextStatus = () => {
    if (
      therapistGender !== null &&
      therapistLGBTQ !== null &&
      therapistOrientation !== null
    )
      return true;
    return false;
  };

  return (
    <React.Fragment>
      <ProgressBar percent={100} phase={3} phases={3} />
      <Formik
        initialValues={{ therapistCountryOfOrigin: "United States of America" }}
        validate={(values) => {
          const errors = {};
          if (!values.therapistCountryOfOrigin) {
            errors.therapistCountryOfOrigin = localize(dict.errors.required);
          }

          return errors;
        }}
        onSubmit={async (formProps, actions) => {
          await setThirdData({
            ...formProps,
            therapistGender,
            therapistLGBTQ,
            therapistOrientation,
          });
          await goNext({
            ...formProps,
            therapistGender,
            therapistLGBTQ,
            therapistOrientation,
          });
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <React.Fragment>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 50,
                marginTop: 75,
                marginBottom: 75,
              }}
            >
              <H4>{localize(dict.registration.tellUsTheType)}</H4>
              <Label>
                <h4>{localize(dict.registration.gender)}</h4>
                <ButtonGroup>
                  <Button
                    onClick={() => setTherapistGender("GENDER_MALE")}
                    selected={therapistGender === "GENDER_MALE"}
                  >
                    {localize(dict.registration.male)}
                  </Button>
                  <Button
                    onClick={() => setTherapistGender("GENDER_FEMALE")}
                    selected={therapistGender === "GENDER_FEMALE"}
                  >
                    {localize(dict.registration.female)}
                  </Button>
                  <Button
                    onClick={() => setTherapistGender("GENDER_NO_PREFERENCE")}
                    selected={therapistGender === "GENDER_NO_PREFERENCE"}
                  >
                    {localize(dict.registration.noPreference)}
                  </Button>
                </ButtonGroup>
              </Label>
              <Label>
                <h4>
                  {localize(dict.registration.therapistWhoSpecializesInLGBTQ)}
                </h4>
                <ButtonGroup>
                  <Button
                    onClick={() => setTherapistLGBTQ(true)}
                    selected={therapistLGBTQ === true}
                  >
                    {localize(dict.app.yes)}
                  </Button>
                  <Button
                    onClick={() => setTherapistLGBTQ(false)}
                    selected={therapistLGBTQ === false}
                  >
                    {localize(dict.app.no)}
                  </Button>
                </ButtonGroup>
              </Label>
              <Label>
                <h4>{localize(dict.registration.spiritualOrientation)}</h4>
                <ButtonGroup>
                  <Button
                    onClick={() => setTherapistOrientation(true)}
                    selected={therapistOrientation === true}
                  >
                    {localize(dict.registration.believer)}
                  </Button>
                  <Button
                    onClick={() => setTherapistOrientation(false)}
                    selected={therapistOrientation === false}
                  >
                    {localize(dict.registration.nonBeliever)}
                  </Button>
                </ButtonGroup>
              </Label>
              <Label>
                <h4>{localize(dict.registration.countryOfOrigin)}</h4>
                <CountrySelect
                  name={"therapistCountryOfOrigin"}
                  value={values.therapistCountryOfOrigin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Label>
            </form>
            <div
              style={{
                display: "flex",
                maxWidth: 380,
                justifyContent: "space-between",
              }}
            >
              <StyledButton.Button
                style={{ maxWidth: 150, width: "100%" }}
                onClick={goPrevious}
              >
                <StyledButton.ButtonText>
                  {localize(dict.registration.back)}
                </StyledButton.ButtonText>
              </StyledButton.Button>
              <StyledButton.Button
                style={{ maxWidth: 150, width: "100%" }}
                onClick={handleSubmit}
                disabled={!getNextStatus() || isSubmitting}
              >
                <StyledButton.ButtonText>
                  {localize(dict.registration.done)}
                </StyledButton.ButtonText>
              </StyledButton.Button>
            </div>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
};
