import { StyledButton, StyledInput, StyledLabel } from "../../../components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import jwt_decode from "jwt-decode";
import axios from "axios";
import CountrySelect from "../../../components/CountrySelect";
import { Formik } from "formik";
import { toast } from "react-hot-toast";
import { localize, dict } from "../../../i18n/index";

const ButtonGroup = styled.div`
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  max-width: 650px;

  > div {
    max-width: 150px;
    width: 100%;
    border: 1px solid #9d9d9d;
    background-color: white;

    span {
      color: #2b2b2b;
      font-family: "Martel Sans", sans-serif;
      font-weight: normal;
    }
  }
`;

const Label = styled(StyledLabel.Label)`
  h4 {
    margin-top: 0;
  }
`;

const Button = ({ children, onClick, selected }) => (
  <StyledButton.Button
    style={{ maxWidth: 150 }}
    onClick={onClick}
    selected={selected}
    clickable={true}
  >
    <StyledButton.ButtonText onClick={onClick}>
      {children}
    </StyledButton.ButtonText>
  </StyledButton.Button>
);

export const ClientPreferences = ({ shownUserId }) => {
  const [client, setClient] = useState(null);
  const [gender, setGender] = useState(null);
  const [orientation, setOrientation] = useState(null);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [LGBTQ, setLGBTQ] = useState(null);
  const userId = shownUserId
    ? shownUserId
    : jwt_decode(localStorage.getItem("token")).id;

  const fetchClient = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/clients/${userId}`,
      {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "cache-control": "no-cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return res;
  };

  useEffect(async () => {
    try {
      const res = await fetchClient();
      setClient(res.data);

      const { therapistGender, therapistOrientation, therapistLGBTQ } =
        res.data;
      setGender(therapistGender);
      setOrientation(therapistOrientation);
      setLGBTQ(therapistLGBTQ);
    } catch (err) {
      setClient({ therapistCountryOfOrigin: "United States of America" });
    }
  }, []);

  return client ? (
    <Formik
      initialValues={{
        therapistCountryOfOrigin: client.therapistCountryOfOrigin,
      }}
      onSubmit={async (formValues, actions) => {
        const props = {
          ...formValues,
          therapistGender: gender,
          therapistOrientation: orientation,
          specialties: selectedSpecialties,
          therapistLGBTQ: LGBTQ,
        };

        try {
          if (shownUserId) {
            const updatedClient = await axios({
              method: "put",
              url: `${process.env.REACT_APP_API_URL}/api/admin/update-client`,
              data: { _id: shownUserId, ...props },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });

            toast.success("Updated client preferences.");
            return setClient(updatedClient);
          }

          if (!client._id) {
            const createdClient = await axios({
              method: "post",
              url: `${process.env.REACT_APP_API_URL}/api/clients/${userId}`,
              data: { ...props },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
            toast.success(localize(dict.errors.successfullyUpdated));
            setClient(createdClient);
          } else {
            const updatedClient = await axios({
              method: "put",
              url: `${process.env.REACT_APP_API_URL}/api/clients/${userId}`,
              data: { ...props },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
            toast.success(localize(dict.errors.successfullyUpdated));
            setClient(updatedClient);
          }
        } catch (err) {
          console.error("error", err);
          toast.error(localize(dict.errors.errorWhileUpdatingUser));
        }

        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <React.Fragment>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 40,
              marginTop: 75,
              marginBottom: 75,
            }}
          >
            <h4
              style={{ fontFamily: "Cantata One, serif", fontWeight: "normal" }}
            >
              {localize(dict.registration.tellUsTheType)}
            </h4>
            <Label>
              <h4>{localize(dict.app.gender)}</h4>
              <ButtonGroup>
                <Button
                  onClick={() => setGender("GENDER_MALE")}
                  selected={gender === "GENDER_MALE"}
                >
                  {localize(dict.app.male)}
                </Button>
                <Button
                  onClick={() => setGender("GENDER_FEMALE")}
                  selected={gender === "GENDER_FEMALE"}
                >
                  {localize(dict.app.female)}
                </Button>
                <Button
                  onClick={() => setGender("GENDER_NO_PREFERENCE")}
                  selected={gender === "GENDER_NO_PREFERENCE"}
                >
                  {localize(dict.registration.noPreference)}
                </Button>
              </ButtonGroup>
            </Label>
            <Label>
              <h4>
                {localize(dict.registration.therapistWhoSpecializesInLGBTQ)}
              </h4>
              <ButtonGroup>
                <Button
                  onClick={() => setLGBTQ(true)}
                  selected={LGBTQ === true}
                >
                  {localize(dict.app.yes)}
                </Button>
                <Button
                  onClick={() => setLGBTQ(false)}
                  selected={LGBTQ !== true}
                >
                  {localize(dict.app.no)}
                </Button>
              </ButtonGroup>
            </Label>
            <Label>
              <h4>{localize(dict.registration.spiritualOrientation)}</h4>
              <ButtonGroup>
                <Button
                  onClick={() => setOrientation(true)}
                  selected={orientation === true}
                >
                  {localize(dict.registration.believer)}
                </Button>
                <Button
                  onClick={() => setOrientation(false)}
                  selected={orientation !== true}
                >
                  {localize(dict.registration.nonBeliever)}
                </Button>
              </ButtonGroup>
            </Label>
            <Label>
              <h4>{localize(dict.registration.countryOfOrigin)}</h4>
              <CountrySelect
                name={"therapistCountryOfOrigin"}
                value={values.therapistCountryOfOrigin}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Label>
            <StyledButton.Button
              style={{ maxWidth: 150, marginTop: 50 }}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              <StyledButton.ButtonText>
                {localize(dict.app.save)}
              </StyledButton.ButtonText>
            </StyledButton.Button>
          </form>
        </React.Fragment>
      )}
    </Formik>
  ) : null;
};
