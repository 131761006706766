export default {
  upcomingAppointments: "Upcoming Appointments",
  noAppointments: "No appointments to display.",
  pastAppointments: "Past Appointments",
  successfullyPurchasedSubscriptionPlan:
    "Successfully purchased subscription plan.",
  sessionsRemaining: "sessions remaining",
  pleasePurchaseAPlan: "Please purchase a plan.",
  upgradePlan: "Upgrade Plan",
  plan: "Plan",
  noPlan: "No Plan",
  packetOfSessions: "Packet of {{number}} sessions",
  joinMeeting: "Join Meeting",
  save: "Save",
  requestCancellation: "Request Cancellation",
  confirmCancellation: "Confirm Cancellation",
  awaitingCancellation: "Awaiting Cancellation",
  cancelled: "Cancelled",
  disclaimer:
    "Please provide cancellation notice at least 24 hours prior to your appointment to be able to maintain your credit.",
};
