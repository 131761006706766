import React from "react";

export const ProgressBar = ({ percent, phase, phases }) => (
  <React.Fragment>
    <div
      style={{
        display: "flex",
        height: 8,
        width: "100%",
        borderRadius: 4,
        backgroundColor: "#D8D8D8",
      }}
    >
      <div
        style={{
          flexBasis: `${percent}%`,
          borderRadius: 4,
          backgroundColor: "#094F58",
        }}
      />
    </div>
    <span
      style={{ display: "flex", justifyContent: "flex-end" }}
    >{`${phase}/${phases}`}</span>
  </React.Fragment>
);
