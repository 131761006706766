import * as React from "react";
import { useEffect, useLayoutEffect, useState } from "react";
import { device } from "../../utilities/responsive";
import styled from "styled-components";
import { StyledContainer } from "../../components";
import axios from "axios";
import TherapistsTable from "./TherapistsTable";
import jwt_decode from "jwt-decode";
import { Redirect } from "react-router";
import { Routes } from "../../Routes";
import { toast } from "react-hot-toast";

const Container = styled(StyledContainer.Container)`
  background-color: #f3f3f3;
  height: 100%;

  @media ${device.sm} {
    max-width: 100%;
    flex: 1;
  }

  @media ${device.xl} {
    max-height: 100vh;
  }

  ${({ style }) => ({ ...style })}
`;

export const Dashboard = (props) => {
  const user = jwt_decode(localStorage.getItem("token"));
  const [therapists, setTherapists] = useState([]);
  const [favorites, setFavorites] = useState({});

  useEffect(async () => {
    try {
      const therapistsRes = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/therapists?approved=true`,
        {
          headers: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            "cache-control": "no-cache",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setTherapists(therapistsRes.data);
      await fetchFavorites();
    } catch (err) {
      if (err.response) {
        console.error("error", err.response.data.message);
        return toast.error(`Error: ${err.response.data.message}`);
      }
    }
  }, []);

  const fetchFavorites = async () => {
    try {
      const favoritesRes = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/favorites/user/${user.id}`,
        {
          headers: {
            "Cache-Control": "no-cache, no-store, must-revalidate",
            "cache-control": "no-cache",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setFavorites(favoritesRes.data);
    } catch (err) {
      if (err.response) {
        console.error("error", err.response.data.message);
        return toast.error(`Error: ${err.response.data.message}`);
      }
    }
  };

  useLayoutEffect(() => {
    function doResize() {
      if (window.innerWidth >= 1200) {
        document.body.style.maxHeight = "100vh";
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.maxHeight = "unset";
        document.body.style.overflow = "unset";
      }
    }

    window.addEventListener("resize", doResize);
    return () => window.removeEventListener("resize", doResize);
  }, []);

  return user.roles.includes("ROLE_THERAPIST") ? (
    <Redirect to={Routes.APPOINTMENTS} />
  ) : user.roles.includes("ROLE_ADMIN") ? (
    <Redirect to={Routes.ADMIN} />
  ) : (
    <Container>
      <TherapistsTable
        users={therapists}
        favorites={favorites}
        setFavorites={setFavorites}
      />
    </Container>
  );
};
