import { Field, FieldArray } from "formik";
import * as React from "react";
import { specialties } from "./Sidebar";
import styled from "styled-components";
import { StyledLabel } from "../../components";
import { useEffect } from "react";
import Checkbox from "../../components/Checkbox";
import { dict, localize } from "../../i18n";

const SpecialtiesFilter = ({ column, values }) => {
  useEffect(() => {
    column.setFilter(values.specialties);
  }, [column, values]);

  return (
    <div
      className={"specialties"}
      style={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: 200,
      }}
    >
      <StyledLabel.Label>
        <span
          style={{
            fontFamily: "Martel Sans",
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          {localize(dict.app.specialties)}
        </span>
      </StyledLabel.Label>
      <div
        role="group"
        aria-labelledby="checkbox-group"
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          rowGap: 8,
        }}
      >
        <div
          role="group"
          aria-labelledby="checkbox-group"
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            rowGap: 8,
          }}
        >
          <FieldArray name={"specialties"}>
            {({ remove, push }) =>
              Object.keys(specialties).map((specialty) => {
                const value = specialties[specialty].value;

                return (
                  <Label>
                    <Field
                      type="checkbox"
                      name="checked"
                      value={value}
                      component={(props) => (
                        <Checkbox
                          {...props}
                          checked={props.form.values.specialties.includes(
                            value
                          )}
                        />
                      )}
                      onChange={() => {
                        if (!values.specialties.includes(value)) {
                          push(value);
                        } else {
                          return remove(values.specialties.indexOf(value));
                        }
                      }}
                    />
                    <span style={{ marginLeft: 5, fontSize: 16 }}>
                      {specialties[specialty].value}
                    </span>
                  </Label>
                );
              })
            }
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

const Label = styled.label`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export default SpecialtiesFilter;
