export default {
  required: "Requerido",
  invalidEmailAddress: "Dirección de email inválida",
  emailAddressNoMatch: "La dirección de Email no coincide",
  pleaseEnterInformationRegardingInquiry:
    "Por favor ingrese la información relacionada con su pregunta",
  mustMatchInitialPassword: "Debe coincidir con la contraseña inicial",
  mustProvideYearsOfExperience: "Debe proporcionar años de experiencia",
  mustHaveAtLeastThreeYearsOfExperience:
    "Debe tener un mínimo de 3 años de experiencia",
  pleaseEnterInformationRegardingAreaOfSpecialty:
    "Ingrese información sobre su área de especialidad",
  pleaseEnterYourCertifications:
    'Ingrese sus certificaciones o escriba "ninguna"',
  mustAcceptTermsOfUseAndPrivacyPolicy:
    "Debe aceptar los términos de uso y la política de privacidad",
  removedFavorite: "Se ha eliminado un favorito.",
  paymentSuccessful: "Pago Exitoso.",
  failedToSubmitPayment: "No se pudo enviar el pago.",
  successfullyUpdated: "La actualización fue exitosa.",
  errorWhileUpdatingUser:
    "Error al actualizar usuario. Favor de intentar de nuevo.",
  successfullyUploadedImage: "Tu imagen fue cargada.",
  failedToUploadImage:
    "No se pudo cargar la imagen. Favor de intentar de nuevo.",
  errorWhileLoggingIn: "Error al iniciar sesión. Favor de intentar de nuevo.",
  accountIsCurrentlyUnderReview: "La cuenta está bajo revisión.",
  successfullyScheduledAppointment: "Cita programada exitosamente.",
  failedToScheduleAppointment:
    "Hubo un error al programar tu cita. Favor de intentar de nuevo.",
  youDoNotHaveAnyCreditsAvailable:
    "No hay créditos disponibles, favor de cargar tu cuenta.",
  successfullyCreatedAccount: "Tu cuenta ha sido creada.",
  errorWhileCreatingUserAccount:
    "Error al crear la cuenta de usuario. Favor de intentar de nuevo.",
  passwordReset: "Restablecimiento de contraseña",
  newPassword: "Nueva contraseña",
  confirmNewPassword: "Confirmar Nueva Contraseña",
  pleaseFollowTheLinkToResetYourPassword:
    "Favor de seguir el siguiente enlace para restablecer su contraseña.",
  aPasswordResetLinkHasBeenSent:
    "Un enlace de restablecimiento de contraseña se ha enviado a la dirección de correo electrónico solicitada.",
  successfullyResetPassword:
    "Has restablecido con éxito tu contraseña, y ya puedes iniciar sesión.",
  failedToResetPassword:
    "No se pudo restablecer la contraseña, por favor inténtalo de nuevo.",
  resetPassword: "Restablecer la contraseña",
};
