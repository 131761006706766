import { useEffect } from "react";
import { StyledLabel } from "../../components";
import { Field, FieldArray } from "formik";
import Checkbox from "../../components/Checkbox";
import * as React from "react";
import styled from "styled-components";
import { dict, localize } from "../../i18n";

const GendersFilter = ({ column, values }) => {
  useEffect(() => {
    column.setFilter(values.genders);
  }, [column, values]);

  return (
    <div
      className={"genders"}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <StyledLabel.Label>
        <span style={{ fontFamily: "Martel Sans", fontWeight: "bold" }}>
          {localize(dict.app.gender)}
        </span>
        <div
          role="group"
          aria-labelledby="checkbox-group"
          style={{
            display: "flex",
            flexDirection: "row",
            maxWidth: "100%",
            columnGap: 20,
            marginBottom: 30,
          }}
        >
          <FieldArray name={"genders"}>
            {({ remove, push }) => (
              <React.Fragment>
                <Label>
                  <Field
                    type="checkbox"
                    name="checked"
                    value="GENDER_MALE"
                    component={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.form.values.genders.includes(
                          "GENDER_MALE"
                        )}
                      />
                    )}
                    onChange={() => {
                      if (!values.genders.includes("GENDER_MALE")) {
                        push("GENDER_MALE");
                      } else {
                        return remove(values.genders.indexOf("GENDER_MALE"));
                      }
                    }}
                  />
                  <span style={{ marginLeft: 5, fontSize: 16 }}>
                    {localize(dict.app.male)}
                  </span>
                </Label>
                <Label>
                  <Field
                    type="checkbox"
                    name="checked"
                    value="GENDER_FEMALE"
                    component={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.form.values.genders.includes(
                          "GENDER_FEMALE"
                        )}
                      />
                    )}
                    onChange={() => {
                      if (!values.genders.includes("GENDER_FEMALE")) {
                        push("GENDER_FEMALE");
                      } else {
                        return remove(values.genders.indexOf("GENDER_FEMALE"));
                      }
                    }}
                  />
                  <span style={{ marginLeft: 5, fontSize: 16 }}>
                    {localize(dict.app.female)}
                  </span>
                </Label>
              </React.Fragment>
            )}
          </FieldArray>
        </div>
      </StyledLabel.Label>
    </div>
  );
};

const Label = styled.label`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export default GendersFilter;
