import styled from "styled-components";
import * as React from "react";

const StyledCheckboxInput = styled.input`
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;

  &:active,
  &:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  &:checked {
    background-color: #fe6551;
    border: 1px solid #fe6551;
    box-shadow: 0 1px 2px rgb(255, 57, 33),
      inset 0px -15px 10px -12px rgb(255, 79, 56),
      inset 15px 10px -12px rgb(254, 101, 81);
    color: #fe6551;
  }

  &:checked:after {
    content: "\\2713";
    font-size: 14px;
    position: absolute;
    top: -2px;
    left: 3px;
    color: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Checkbox = (props) => {
  return <StyledCheckboxInput {...props} />;
};

export default Checkbox;
