import React, { useEffect, useState } from "react";

export const AuthContext = React.createContext({
  isAuthenticated: false,
  token: null,
});

export const useAuthActions = () => {
  const handleLogin = () => {};
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // console.log("rendered provider");
  });

  const value = {
    isAuthenticated,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
