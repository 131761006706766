import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { matchSorter } from "match-sorter";
import { useFilters, useGlobalFilter, useTable } from "react-table";

const UserButton = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    background-color: rgba(254, 101, 81, 0.34);
  }
`;

const UserManagementList = (props) => {
  const [users, setUsers] = useState([]);

  useEffect(async () => {
    const users = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/admin/users`,
      {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "cache-control": "no-cache",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    setUsers(users.data);
  }, []);

  const data = React.useMemo(() => users, [users]);
  const columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
        filter: "fuzzyText",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        filter: "fuzzyText",
      },
      {
        Header: "Email",
        accessor: "email",
        filter: "fuzzyText",
      },
    ],
    []
  );

  const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  };

  const filterTypes = React.useMemo(
    () => ({ fuzzyText: fuzzyTextFilterFn }),
    []
  );

  const tableInstance = useTable(
    { columns, data, filterTypes },
    useGlobalFilter,
    useFilters
  );

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    preGlobalFilteredRows,
    headerGroups,
  } = tableInstance;

  return (
    <div
      style={{
        maxHeight: 500,
        width: "100%",
        overflowY: "scroll",
        paddingBottom: 50,
      }}
      {...getTableProps()}
      {...getTableBodyProps()}
    >
      {rows.map((user) => {
        prepareRow(user);
        return (
          <UserButton
            key={user.original.email}
            onClick={() => props.setShowUser(user.original)}
          >
            <span style={{ fontWeight: "bold" }}>
              {user.original.firstName} {user.original.lastName}
            </span>
            <span>{user.original.email}</span>
            <div>
              {user.original.roles.map((role, idx) => {
                if (role === "ROLE_CLIENT")
                  return <span key={"client"}>Client</span>;
                else if (role === "ROLE_THERAPIST")
                  return <span key={"therapist"}>Therapist</span>;
                else if (role === "ROLE_ADMIN")
                  return <span key={"admin"}>{idx > 0 && ", "}Admin</span>;
                else return <span key={"nothing"} />;
              })}
            </div>
          </UserButton>
        );
      })}
    </div>
  );
};

export default UserManagementList;
