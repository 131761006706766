import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { StyledButton } from "../../../components";
import styled from "styled-components";
import SubscriptionModal from "./SubscriptionModal";
import { dict, localize } from "../../../i18n";
import { toast } from "react-hot-toast";

const Card = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 rgba(173, 173, 173, 0.5);
  background-color: #fff;
  align-self: flex-start;
  padding: 50px;
  width: 100%;
`;

const Subscription = ({ upgradePlanButtonRef }) => {
  const [subscription, setSubscription] = useState(null);
  const [modal, setModal] = useState(false);
  const [purchased, setPurchased] = useState(false);
  const user = jwt_decode(localStorage.getItem("token"));
  const bearerToken = `Bearer ${localStorage.getItem("token")}`;

  const fetchSubscription = async () => {
    try {
      const currentSubscription = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/api/subscriptions/client/${user.id}`,
        headers: { Authorization: bearerToken },
      });

      setSubscription(currentSubscription?.data);
    } catch (err) {
      if (err.response) {
        console.error("error", err.response.data.message);
        return toast.error(`Error: ${err.response.data.message}`);
      }
    }
  };

  useEffect(async () => {
    await fetchSubscription();
  }, []);

  useEffect(async () => {
    if (purchased) {
      new Promise((resolve) =>
        setTimeout(() => {
          setPurchased(false);
          resolve();
        }, 5000)
      );
    }
  }, [purchased, setPurchased]);

  const plan = {
    PLAN_FREE: localize(dict.pricing.freeFirstSession),
    PLAN_ONE: localize(dict.pricing.individualSession),
    PLAN_COUPLES: localize(dict.pricing.couplesSession),
    PLAN_FOUR: localize(dict.pricing.packetOfFourSessions),
    PLAN_TEN: localize(dict.pricing.packetOfTenSessions),
  };

  return (
    <React.Fragment>
      <Card>
        {purchased && (
          <div
            style={{
              border: "1px solid green",
              backgroundColor: "green",
              color: "white",
              padding: "10px 20px",
              display: "flex",
              width: "100%",
              borderRadius: 8,
              marginBottom: 20,
            }}
          >
            {localize(dict.appointments.successfullyPurchasedSubscriptionPlan)}
          </div>
        )}
        <h2
          style={{
            fontFamily: "Cantata One",
            fontWeight: "normal",
            margin: 0,
            marginBottom: 30,
          }}
        >
          {localize(dict.appointments.plan)}
        </h2>
        {subscription && subscription.currentCredit > 0 && (
          <div className={"plan-details"}>
            <span className={"plan"}>{plan[subscription.plan]}</span>
            <span style={{ color: "#757575" }}>
              {subscription.currentCredit}/{subscription.initialCredit}{" "}
              {localize(dict.appointments.sessionsRemaining)}
            </span>
          </div>
        )}
        {!subscription || subscription.currentCredit < 1 ? (
          <React.Fragment>
            <div className={"plan-details"}>
              <span className={"plan"}>
                {localize(dict.appointments.noPlan)}
              </span>
              <span style={{ color: "#757575" }}>
                {localize(dict.appointments.pleasePurchaseAPlan)}
              </span>
            </div>
            <StyledButton.Button
              id={"update-plan-button"}
              style={{ maxWidth: 320 }}
              onClick={() => setModal(true)}
              ref={upgradePlanButtonRef}
            >
              <StyledButton.ButtonText style={{ margin: 0 }}>
                {localize(dict.appointments.upgradePlan)}
              </StyledButton.ButtonText>
            </StyledButton.Button>
          </React.Fragment>
        ) : null}
        <SubscriptionModal
          modal={modal}
          setModal={setModal}
          callback={fetchSubscription}
          setPurchased={setPurchased}
        />
      </Card>
    </React.Fragment>
  );
};

export default Subscription;
